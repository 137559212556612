import { getIsMeetupLiteEvent } from 'context/LiveAirmeet';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import { FLAG_NAMES } from 'hooks/useFeatureControl';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import keys from 'locale/keys';
import React, { useMemo } from 'react';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import LangsSelect from './ClosedCaptions/LangsSelect';
import PrimaryLangSelect from './ClosedCaptions/PrimaryLangSelect';
import PanelSettings from './PanelSettings';
import { closedCaptionsConfigSelector } from 'store/selectors/liveAirmeet';
import { useSelector } from 'react-redux';
import useCommunityPlanInfo from 'hooks/community/useCommunityPlanInfo';

function CustomizeMeetupOptions({
    liveConfig,
    setPromptData,
    onChange,
    isConference,
    logEvent,
    isUGCEnabled,
    isSessionLevelStageSetting,
}) {
    const { t } = useCustomTranslation();
    const {
        features,
        airmeet: {
            data: {
                currentAirmeet: {
                    status,
                    is_chat_gif_enabled,
                    is_advanced_people_search_enabled,
                    community_id,
                },
            },
            featureFlags: { isLoungeAllowed, isSessionReplayEnabled },
        },
    } = useLiveAirmeetContext();
    const {
        closed_captions: closeCaptionFlag,
        isCCTranslationEnabled,
    } = useSelector(closedCaptionsConfigSelector);
    const isMeetupLiteEvent = useLiveAirmeetContext(getIsMeetupLiteEvent);
    const isPeopleSearchFirebaseFlagEnabled = features?.includes(
        FLAG_NAMES.ADVANCED_PEOPLE_SEARCH
    );
    const { isPaidPlan } = useCommunityPlanInfo(community_id);

    const SETTINGS = useMemo(
        () => [
            ...(isSessionLevelStageSetting
                ? [
                      {
                          key: 'hideEventFeed',
                          label: t(keys.RHS_CUSTOMIZE_FEED_LABEL),
                          isHidden: false,
                          isClearEnabled: true,
                          clearTitle: t(keys.RHS_CUSTOMIZE_FEED_CLEAR_TITLE),
                          clearDescription: t(
                              keys.RHS_CUSTOMIZE_FEED_CLEAR_DESCRIPTION
                          ),
                      },
                      {
                          key: 'hideEventPolls',
                          label: t(keys.RHS_CUSTOMIZE_EVENT_POLLS_LABEL),
                          isHidden: false,
                          isClearEnabled: true,
                          clearTitle: t(
                              keys.RHS_CUSTOMIZE_EVENT_POLLS_CLEAR_TITLE
                          ),
                          clearDescription: t(
                              keys.RHS_CUSTOMIZE_EVENT_POLLS_CLEAR_DESCRIPTION
                          ),
                      },
                  ]
                : []),
            isLoungeAllowed
                ? {
                      key: 'hideSocialLounge',
                      label: t(keys.LOUNGE_SOCIAL_LOUNGE),
                      isHidden: false,
                      isClearEnabled: false,
                      showPromptOnHide: true,
                      promptTitle: t(
                          keys.RHS_CUSTOMIZE_HIDE_SOCIAL_LOUNGE_PROMPT_TITLE
                      ),
                      promptMessage: t(
                          keys.RHS_CUSTOMIZE_SOCIAL_LOUNGE_PROMPT_MSG
                      ),
                  }
                : {},
            isSessionLevelStageSetting
                ? {}
                : {
                      key: 'hideSessionsLiveChat',
                      label: t(keys.RHS_CUSTOMIZE_LIVE_CHAT_LABEL),
                      isHidden: false,
                      isClearEnabled: true,
                      clearTitle: t(keys.RHS_CUSTOMIZE_LIVE_CHAT_CLEAR_TITLE),
                      clearDescription: t(
                          keys.RHS_CUSTOMIZE_LIVE_CHAT_CLEAR_DESCRIPTION
                      ),
                  },
            is_chat_gif_enabled
                ? {
                      key: 'hideEventGifs',
                      isHidden: false,
                      label: t(keys.RHS_CHAT_GIFS),
                      hint: t(keys.RHS_CHAT_GIFS_TOOLTIP),
                      isClearEnabled: false,
                  }
                : {},
            {
                key: 'hideEventAttendeeList',
                label: t(keys.RHS_CUSTOMIZE_PEOPLE_IN_EVENT_LABEL_V2),
                hint: t(keys.RHS_CUSTOMIZE_PEOPLE_IN_EVENT_HINT),
                isHidden: false,
                isClearEnabled: false,
            },
            ...(is_advanced_people_search_enabled ||
            isPeopleSearchFirebaseFlagEnabled
                ? [
                      {
                          key: 'hideAdvancedPeopleSearch',
                          label: t(
                              keys.RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_NEW_KEY
                          ),
                          hint: t(
                              keys.RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_KEY_HINT
                          ),
                          isHidden: false,
                          isClearEnabled: false,
                      },
                      {
                          key: 'onlyAttendeeCardSearchDisabled',
                          label: t(
                              keys.RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_LABEL
                          ),
                          hint: t(
                              keys.RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_HINT_NEW
                          ),
                          isHidden: false,
                          isClearEnabled: false,
                          parentKey: 'hideAdvancedPeopleSearch',
                      },
                  ]
                : []),
            ...(isSessionReplayEnabled && !isMeetupLiteEvent
                ? [
                      {
                          key: 'isSessionPlaybackDisabled',
                          label: t(keys.RHS_CUSTOMIZE_SESSION_REPLAY_LABEL),
                          hint: t(keys.RHS_CUSTOMIZE_SESSION_REPLAY_HINT),
                          isHidden: false,
                          isClearEnabled: false,
                          notChangeChildSettings: true,
                          showDetails: true,
                          detailsHeading: t(
                              keys.RHS_CUSTOMIZE_SESSION_REPLAY_HEADING
                          ),
                          detailsSubHeading: t(
                              keys.RHS_CUSTOMIZE_SESSION_REPLAY_SUBHEADING
                          ),
                          isPostEventVisible: true,
                      },
                  ]
                : []),
            ...(isSessionLevelStageSetting
                ? []
                : [
                      {
                          key: 'hideSessionsQA',
                          label: t(keys.RHS_CUSTOMIZE_QA_LABEL),
                          isHidden: false,
                          isClearEnabled: true,
                          clearTitle: t(keys.RHS_CUSTOMIZE_QA_CLEAR_TITLE),
                          clearDescription: t(
                              keys.RHS_CUSTOMIZE_QA_CLEAR_DESCRIPTION
                          ),
                      },
                      ...(isPaidPlan
                          ? [
                                {
                                    key: 'allowSessionQAModeration',
                                    label: t(keys.MODERATE_QUESTIONS),
                                    hint: t(keys.MODERATE_QUESTIONS_TOOLTIP),
                                    isHidden: false,
                                    isClearEnabled: false,
                                    parentKey: 'hideSessionsQA',
                                    isInverted: true,
                                },
                                {
                                    key: 'allowSpeakerQAModeration',
                                    label: t(
                                        keys.ALLOW_SPEAKER_MODERATION_LABEL
                                    ),
                                    isHidden: false,
                                    isClearEnabled: false,
                                    parentKey: 'allowSessionQAModeration',
                                    isCheckbox: true,
                                    hideDivider: true,
                                },
                                {
                                    key: 'allowSessionQAComments',
                                    label: t(keys.QA_COMMENTS_ALLOW),
                                    hint: t(keys.QA_COMMENTS_ALLOW_SUBTEXT),
                                    isHidden: true,
                                    isClearEnabled: false,
                                    parentKey: 'hideSessionsQA',
                                    isInverted: true,
                                },
                                {
                                    key: 'allowSessionQAAnonymity',
                                    label: t(keys.QA_ANONYMOUS),
                                    hint: t(keys.QA_ALLOW_ANONYMOUS_SUBTEXT),
                                    isHidden: false,
                                    isClearEnabled: false,
                                    showDetails: true,
                                    parentKey: 'hideSessionsQA',
                                    isInverted: true,
                                    isNew: true,
                                },
                            ]
                          : [
                                {
                                    key: 'allowSessionQAComments',
                                    label: t(keys.QA_COMMENTS_ALLOW),
                                    hint: t(keys.QA_COMMENTS_ALLOW_SUBTEXT),
                                    isHidden: true,
                                    isClearEnabled: false,
                                    parentKey: 'hideSessionsQA',
                                    isInverted: true,
                                },
                            ]),
                      {
                          key: 'hideSessionsPolls',
                          label: t(keys.POLLS),
                          isHidden: false,
                          isClearEnabled: true,
                          clearTitle: t(keys.RHS_CUSTOMIZE_POLLS_CLEAR_TITLE),
                          clearDescription: t(
                              keys.RHS_CUSTOMIZE_POLLS_CLEAR_DESCRIPTION
                          ),
                      },
                      {
                          key: 'hideSessionsAttendeeCount',
                          label: t(keys.RHS_CUSTOMIZE_ATTENDEE_COUNT_LABEL_V2),
                          isHidden: false,
                          isClearEnabled: false,
                          hint: t(keys.RHS_CUSTOMIZE_ATTENDEE_COUNT_HINT_V2),
                      },
                      {
                          key: 'hideSessionsEmojiReactions',
                          label: t(keys.RHS_CUSTOMIZE_EMOJI_REACTION),
                          hint: t(keys.RHS_CUSTOMIZE_EMOJI_HINT),
                          isHidden: false,
                          isClearEnabled: false,
                      },
                  ]),
            {
                key: 'hideAirmeetExitSurvey',
                label: t(keys.RHS_CUSTOMIZE_EXIT_SURVEY_LABEL2),
                isHidden: false,
                isClearEnabled: false,
            },
            isSessionLevelStageSetting
                ? {}
                : {
                      key: 'hideSessionsRaiseHand',
                      label: t(keys.RHS_CUSTOMIZE_RAISE_HAND_LABEL),
                      hint: t(keys.RHS_CUSTOMIZE_RAISE_HAND_HINT),
                      isHidden: false,
                      isClearEnabled: false,
                  },
            {
                key: 'hideDirectMessage',
                label: t(keys.RHS_CUSTOMIZE_DIRECT_MESSAGES_LABEL),
                hint: t(keys.RHS_CUSTOMIZE_DIRECT_MESSAGES_HINT),
                isHidden: false,
                isClearEnabled: false,
            },
            {
                key: 'leaderboardEarnPointsVisibility',
                label: t(keys.RHS_CUSTOMIZE_SHOW_LEADERBOARD_POINTS_LABEL),
                hint: t(keys.RHS_CUSTOMIZE_SHOW_LEADERBOARD_POINTS_HINT),
                isHidden: false,
                isClearEnabled: false,
            },
            ...(closeCaptionFlag === 'SHOW'
                ? [
                      {
                          key: 'showCloseCaption',
                          label: t(keys.RHS_CUSTOMIZE_CLOSE_CAPTION_LABEL),
                          hint: t(keys.RHS_CUSTOMIZE_CLOSE_CAPTION_HINT),
                          isHidden: false,
                          isClearEnabled: false,
                          isInverted: true,
                          childComponent: PrimaryLangSelect,
                      },
                      isCCTranslationEnabled && {
                          key: 'allowTranslatedCaption',
                          label: t(keys.RHS_CUSTOMIZE_TRANSLATED_CAPTION_LABEL),
                          hint: t(keys.RHS_CUSTOMIZE_TRANSLATED_CAPTION_HINT),
                          isHidden: false,
                          isClearEnabled: false,
                          parentKey: 'showCloseCaption',
                          isInverted: true,
                          childComponent: LangsSelect,
                      },
                  ]
                : []),
            isSessionLevelStageSetting
                ? {}
                : {
                      key: 'hideStageLabels',
                      label: t(keys.RHS_CUSTOMIZE_STAGE_LABELS),
                      hint: t(keys.RHS_CUSTOMIZE_STAGE_LABELS_HINT),
                      isHidden: false,
                      isClearEnabled: false,
                  },
        ],
        [
            isPeopleSearchFirebaseFlagEnabled,
            is_advanced_people_search_enabled,
            is_chat_gif_enabled,
            t,
            closeCaptionFlag,
            isCCTranslationEnabled,
            isSessionLevelStageSetting,
            isSessionReplayEnabled,
        ]
    );

    const eventSettings = useMemo(
        () =>
            status === AIRMEET_STATUS.FINISHED
                ? SETTINGS.filter((setting) => setting?.isPostEventVisible)
                : isUGCEnabled
                ? SETTINGS
                : SETTINGS.filter(
                      (setting) => setting.key !== 'hideSocialLounge'
                  ),
        [isUGCEnabled, SETTINGS, status]
    );

    return (
        <PanelSettings
            liveConfig={liveConfig}
            setPromptData={setPromptData}
            onChange={onChange}
            settings={eventSettings}
            isConference={isConference}
            title={t(keys.RHS_CUSTOMIZE_EVENT_EXP_TITLE)}
            description={t(keys.RHS_CUSTOMIZE_EVENT_EXP_SUBTITLE)}
            logEvent={logEvent}
        />
    );
}

export default CustomizeMeetupOptions;

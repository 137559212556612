import isEmpty from 'lodash/isEmpty';
import { EVENT_MODERATOR, USER_ROLE } from 'utils/constants/users';
import { logger } from 'utils/logger';
import { isCoHost, isSpeaker } from 'utils/users';
import PermissionUtils from './permission-utils';

const { HOST, COHOST, ORGANIZER, SPEAKER, ATTENDEE } = USER_ROLE;

class UserRoles {
    // Data required for computation of roles
    static data = {};
    static authUser = null;
    static usersMap = {};
    static roles = {};

    /**
     * Map of userId to role
     * @example
     * roles = {
     *   user1Id: {
     *      role: 'userRole',
     *      sessions: {
     *         [sessionId1]: 'user1Session1Role',
     *         [sessionId2]: 'user1Session2Role',
     *      },
     *   },
     *   user2Id: {
     *      role: 'userRole',
     *      sessions: {
     *         [sessionId1]: 'user2Session1Role',
     *         [sessionId2]: 'user2Session2Role',
     *      },
     *   },
     * };
     */
    static roles = {};

    static setData = ({
        user,
        airmeet,
        session,
        users,
        allInvitedSpeakers,
    }) => {
        if (!this.authUser) {
            this.authUser = user;
        }

        if (users) {
            this.usersMap = users;
        }

        if (user) {
            this.setUser(user);
        }

        this.data = {
            airmeet,
            session,
            allInvitedSpeakers,
        };

        this.roles = {};
        this.populateRoles(this.authUser?.id);
    };

    static setUser = (user) => {
        if (!user) {
            return;
        }

        if (!this.usersMap) {
            this.usersMap = {};
        }

        if (!this.usersMap[user?.id]) {
            this.usersMap[user.id] = user;
        }
    };

    static setUsers = (users) => {
        this.usersMap = { ...users };
    };

    static populateRoles = (userId) => {
        const { airmeet, session } = this.data;

        const sessionRoles = {};
        let userRole = ATTENDEE;
        if (!airmeet) {
            this.roles = {};
            return;
        }
        if (!userId) {
            return;
        }

        const privilegedUserRoles = this.usersMap?.[userId]?.airmeetRoles || [];

        const isEMTeamMember = privilegedUserRoles.includes(EVENT_MODERATOR);

        if (airmeet.host === userId) {
            userRole = HOST;
        } else if (session && (session.cohost_ids || []).includes(userId)) {
            userRole = COHOST;
        } else if (session && session.speaker_id.includes(userId)) {
            userRole = SPEAKER;
        }

        if (isEMTeamMember) {
            userRole = ORGANIZER;
        }

        PermissionUtils.assignValues({
            authUser: this.authUser,
            sessions: airmeet.sessions,
            currentSession: session,
            origin: 'userRoles',
        });

        if (PermissionUtils.isSessionCloudHost()) {
            // todo cloud host
            userRole = SPEAKER;
        }

        if (airmeet.session_enabled && airmeet.sessions.length > 0) {
            airmeet.sessions.forEach((sessionRow) => {
                const sessionRole = this.computeSessionRole({
                    userId,
                    session: sessionRow,
                });
                sessionRoles[sessionRow.sessionid] = { role: sessionRole };
                if (userRole === ORGANIZER) {
                    return;
                }

                if (isSpeaker(sessionRole) || isCoHost(sessionRole)) {
                    userRole = SPEAKER;
                }
            });
        }

        if (this.usersMap?.[userId]) {
            this.roles[userId] = {
                privilegedJsonUserRoles:
                    this.usersMap[userId].airmeetRoles &&
                    this.usersMap[userId].airmeetRoles.length > 0
                        ? JSON.stringify(
                              [...this.usersMap[userId].airmeetRoles].sort()
                          )
                        : '',
                role: userRole,
                sessions: sessionRoles,
            };
        }
    };

    static computeSessionRole = ({ userId, session = null }) => {
        let role = ATTENDEE;

        if (!session) {
            session = this.data.session;
        }

        const { allInvitedSpeakers: invitedSpeakers, airmeet } = this.data;

        if (isEmpty(airmeet) || isEmpty(userId)) {
            return role;
        }

        const isUserInvitedToStage =
            invitedSpeakers?.[session?.sessionid]?.[userId] || false;
        const isEMTeamMember = (
            this.usersMap?.[userId]?.airmeetRoles || []
        ).includes(EVENT_MODERATOR);

        if (isEmpty(session)) {
            if (userId === airmeet.host) {
                role = ORGANIZER;
            }
        } else if (session.host_id.includes(userId)) {
            role = HOST;
        } else if ((session.cohost_ids || []).includes(userId)) {
            role = COHOST;
        } else if (
            session.speaker_id.includes(userId) ||
            isUserInvitedToStage ||
            session.cloud_user_id === userId
        ) {
            role = SPEAKER;
        } else if (isEMTeamMember) {
            role = ORGANIZER;
        }

        return role;
    };

    static getUserRoleData(userId, finalCheck = false) {
        if (!userId) {
            return;
        }
        const roleUser = this.roles?.[userId];
        if (!roleUser) {
            return;
        }
        const userMap = this.usersMap[userId];
        if (userMap) {
            userMap.airmeetJSONRoles =
                userMap.airmeetRoles && userMap.airmeetRoles.length > 0
                    ? JSON.stringify([...userMap.airmeetRoles].sort())
                    : '';
        }
        const userMapJsonAirmeetRoles = userMap?.airmeetJSONRoles || '';
        const privilegedJsonUserRoles = roleUser.privilegedJsonUserRoles || '';
        if (
            !finalCheck &&
            userMapJsonAirmeetRoles !== privilegedJsonUserRoles
        ) {
            logger.info(`Mismatch the airmeetRoles for user ${userId}`, {
                userMapJsonAirmeetRoles,
                privilegedJsonUserRoles,
            });
            try {
                delete this.roles[userId];
            } catch (e) {}
            return;
        }
        return roleUser;
    }

    static getUserEventRole = (userId, finalCheck = false) => {
        if (!userId) {
            userId = this.authUser?.id;
        }

        if (!userId) {
            return ATTENDEE;
        }
        const roleUser = this.getUserRoleData(userId, finalCheck);
        const role = roleUser?.role;

        if (finalCheck) {
            return role || ATTENDEE;
        }

        if (role) {
            return role;
        } else {
            this.populateRoles(userId);
            return this.getUserEventRole(userId, true);
        }
    };

    static getUserSessionRole = (userId, sessionId, finalCheck = false) => {
        if (!userId) {
            userId = this.authUser?.id;
        }

        if (!sessionId) {
            sessionId = this.data?.session?.sessionid;
        }

        if (!userId || !sessionId) {
            return this.getUserEventRole(userId);
        }

        const roleUser = this.getUserRoleData(userId, finalCheck);
        const role = roleUser?.sessions?.[sessionId]?.role;
        if (finalCheck) {
            return role || this.getUserEventRole(userId);
        }

        if (role) {
            return role;
        } else {
            this.populateRoles(userId);
            return this.getUserSessionRole(userId, sessionId, true);
        }
    };
}

export default UserRoles;

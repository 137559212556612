import useCommunityPlanInfo from 'hooks/community/useCommunityPlanInfo';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import keys from 'locale/keys';
import React, { useMemo } from 'react';
import PanelSettings from './PanelSettings';

function CustomizeSessionExperience({
    liveConfig,
    setPromptData,
    onChange,
    logEvent,
}) {
    const { t } = useCustomTranslation();
    const {
        airmeet: {
            data: {
                currentAirmeet: { community_id },
            },
        },
    } = useLiveAirmeetContext();

    const { isPaidPlan } = useCommunityPlanInfo(community_id);

    const SETTINGS = useMemo(
        () => [
            {
                key: 'hideSessionsLiveChat',
                label: t(keys.RHS_CUSTOMIZE_LIVE_CHAT_LABEL),
                isHidden: false,
                isClearEnabled: true,
                clearTitle: t(keys.RHS_CUSTOMIZE_LIVE_CHAT_CLEAR_TITLE),
                clearDescription: t(
                    keys.RHS_CUSTOMIZE_LIVE_CHAT_CLEAR_DESCRIPTION
                ),
            },
            {
                key: 'hideSessionsQA',
                label: t(keys.RHS_CUSTOMIZE_QA_LABEL),
                isHidden: false,
                isClearEnabled: true,
                clearTitle: t(keys.RHS_CUSTOMIZE_QA_CLEAR_TITLE),
                clearDescription: t(keys.RHS_CUSTOMIZE_QA_CLEAR_DESCRIPTION),
            },
            ...(isPaidPlan
                ? [
                      {
                          key: 'allowSessionQAModeration',
                          label: t(keys.MODERATE_QUESTIONS),
                          hint: t(keys.MODERATE_QUESTIONS_TOOLTIP),
                          isHidden: false,
                          isClearEnabled: false,
                          parentKey: 'hideSessionsQA',
                          isInverted: true,
                      },
                      {
                          key: 'allowSpeakerQAModeration',
                          label: t(keys.ALLOW_SPEAKER_MODERATION_LABEL),
                          isHidden: false,
                          isClearEnabled: false,
                          parentKey: 'allowSessionQAModeration',
                          isCheckbox: true,
                          hideDivider: true,
                      },
                      {
                          key: 'allowSessionQAComments',
                          label: t(keys.QA_COMMENTS_ALLOW),
                          hint: t(keys.QA_COMMENTS_ALLOW_SUBTEXT),
                          isHidden: true,
                          isClearEnabled: false,
                          parentKey: 'hideSessionsQA',
                          isInverted: true,
                      },
                      {
                          key: 'allowSessionQAAnonymity',
                          label: t(keys.QA_ANONYMOUS),
                          hint: t(keys.QA_ALLOW_ANONYMOUS_SUBTEXT),
                          isHidden: false,
                          isClearEnabled: false,
                          showDetails: true,
                          parentKey: 'hideSessionsQA',
                          isInverted: true,
                          isNew: true,
                      },
                  ]
                : [
                      {
                          key: 'allowSessionQAComments',
                          label: t(keys.QA_COMMENTS_ALLOW),
                          hint: t(keys.QA_COMMENTS_ALLOW_SUBTEXT),
                          isHidden: true,
                          isClearEnabled: false,
                          parentKey: 'hideSessionsQA',
                          isInverted: true,
                      },
                  ]),
            {
                key: 'hideSessionsPolls',
                label: t(keys.RHS_CUSTOMIZE_POLLS_LABEL),
                isHidden: false,
                isClearEnabled: true,
                clearTitle: t(keys.RHS_CUSTOMIZE_POLLS_CLEAR_TITLE),
                clearDescription: t(keys.RHS_CUSTOMIZE_POLLS_CLEAR_DESCRIPTION),
            },
            {
                key: 'hideSessionsPeopleInSession',
                label: t(keys.RHS_CUSTOMIZE_PEOPLE_IN_SESSION_LABEL_V2),
                hint: t(keys.RHS_CUSTOMIZE_PEOPLE_IN_SESSION_HINT),
                isHidden: false,
                isClearEnabled: false,
            },
            {
                key: 'hideSessionsAttendeeCount',
                label: t(keys.RHS_CUSTOMIZE_ATTENDEE_COUNT_LABEL_V2),
                hint: t(keys.RHS_CUSTOMIZE_EVENT_ATTENDEE_COUNT_HINT_V2),
                isHidden: false,
                isClearEnabled: false,
            },
            {
                key: 'hideSessionsEmojiReactions',
                label: t(keys.RHS_CUSTOMIZE_EMOJI_REACTIONS_LABEL),
                hint: t(keys.RHS_CUSTOMIZE_EMOJI_REACTIONS_HINT),
                isHidden: false,
                isClearEnabled: false,
            },
            {
                key: 'hideSessionsRaiseHand',
                label: t(keys.RHS_CUSTOMIZE_RAISE_HAND_LABEL),
                hint: t(keys.RHS_CUSTOMIZE_RAISE_HAND_HINT),
                isHidden: false,
                isClearEnabled: false,
            },
            {
                key: 'hideStageLabels',
                label: t(keys.RHS_CUSTOMIZE_STAGE_LABELS),
                hint: t(keys.RHS_CUSTOMIZE_STAGE_LABELS_HINT),
                isHidden: false,
                isClearEnabled: false,
            },
        ],
        [t]
    );

    return (
        <PanelSettings
            liveConfig={liveConfig}
            setPromptData={setPromptData}
            onChange={onChange}
            settings={SETTINGS}
            title={t(keys.RHS_CUSTOMIZE_SESSION_EXP_TITLE)}
            description={t(keys.RHS_CUSTOMIZE_SESSION_EXP_SUBTITLE)}
            logEvent={logEvent}
        />
    );
}

export default CustomizeSessionExperience;

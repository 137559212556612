import { useMainNavigation } from 'context/MainNavigation';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useToasts from 'hooks/useToasts';
import keys from 'locale/keys';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAirmeetState } from 'store/actions/Lounge';
import { callApi } from 'store/api';
import FirebaseLogger from 'utils/firebaseLogger';
import { getCurrentTenantData } from 'utils/tenant';
import CustomizeTab from '../widgets/CustomizeTab';
import CustomizeBranding from './CustomizeBranding/';
import {
    DEFAULT_BACKGROUND_IMAGES,
    getDefaultBackground,
} from './CustomizeBranding/CustomizeBackground/utils';
import CustomizeSettings from './CustomizeSettings';
import useClosedCaptionsSettings from 'hooks/closed-captions/useClosedCaptionsSettings';

const defaultColor = getCurrentTenantData('primaryColor');
const defaultColorString = `hsl(${defaultColor.H},${defaultColor.S}%,${defaultColor.L}%)`;

export default function CustomizePanel({
    onToggle,
    userRole,
    userStageRole,
    isConference,
    isCustomNavLabelsEnabled,
    isScheduleMeetingEnabled,
    isUGCEnabled,
    type = 'SETTINGS',
}) {
    const { t } = useCustomTranslation();
    const dispatch = useDispatch();
    const [promptData, setPromptData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showPageOverlay, setShowPageOverlay] = useState(false);
    const [disableSave, setDisableSave] = useState(false);

    const { successToast, errorToast } = useToasts();
    const user = useSelector((state) => state.auth.user);

    const airmeet = useSelector((store) => store?.lounge?.airmeet);
    const {
        theme_info: themeInfo,
        custom_text,
        live_config,
        community_id,
        status,
    } = airmeet || {};
    // Sanitize theme info
    const theme_info = {
        ...(themeInfo || {}),
        primary_color: themeInfo?.primary_color || defaultColorString,
    };

    const prevHideStageLabelsValue = useRef(live_config?.hideStageLabels)
        .current;
    const prevSessionQAAnonymityLabelsValue = useRef(
        live_config?.allowSessionQAAnonymity
    ).current;

    const logEvent = useCallback(
        (event, data = {}) =>
            FirebaseLogger.customizeEvent(event, {
                airmeet_id: airmeet?.airmeetId,
                airmeet_name: airmeet?.name,
                community_id: airmeet?.community_id,
                community_name: airmeet?.community_name,
                user_id: user?.id,
                user_role: userRole,
                user_stage_role: userStageRole,
                ...(data || {}),
            }),
        [airmeet, user, userRole, userStageRole]
    );

    const settings = useMemo(
        () => ({
            theme_info,
            custom_text,
            live_config,
        }),
        [theme_info, custom_text, live_config]
    );

    const { defaultPrimaryLanguage } = useClosedCaptionsSettings();

    const handleAnalytics = (prevSettings) => {
        if (prevHideStageLabelsValue !== live_config?.hideStageLabels) {
            logEvent &&
                logEvent('stageLabel', {
                    status: live_config?.hideStageLabels ? 'off' : 'on',
                });
        }
        if (
            prevSessionQAAnonymityLabelsValue !==
            live_config?.allowSessionQAAnonymity
        ) {
            FirebaseLogger.questionsEvent('anonymousToggled', {
                airmeet_id: airmeet?.airmeetId,
                airmeet_name: airmeet?.name,
                community_id: airmeet?.community_id,
                community_name: airmeet?.community_name,
                user_id: user?.id,
                user_role: userRole,
                user_stage_role: userStageRole,
                toggle: live_config?.allowSessionQAAnonymity ? 'ON' : 'OFF',
            });
        }
        if (type === 'BRANDING') {
            if (!isEqual(prevSettings, settings)) {
                const commonProps = {
                    airmeet_id: airmeet?.airmeetId,
                    airmeet_name: airmeet?.name,
                    community_id: airmeet?.community_id,
                    community_name: airmeet?.community_name,
                    user_id: user?.id,
                    user_role: userRole,
                    user_stage_role: userStageRole,
                };
                FirebaseLogger.eventBranding('eventBrandingUsed', {
                    ...commonProps,
                });

                const lightThemePath = 'theme_info.is_light_theme';
                if (
                    get(prevSettings, lightThemePath) !==
                    get(settings, lightThemePath)
                ) {
                    FirebaseLogger.eventBranding('eventBrandingThemeUsed', {
                        ...commonProps,
                        theme_type: get(settings, lightThemePath)
                            ? 'light'
                            : 'dark',
                        prev_theme_type: get(prevSettings, lightThemePath)
                            ? 'light'
                            : 'dark',
                    });
                }

                const highlightColorPath = 'theme_info.primary_color';
                if (
                    get(prevSettings, highlightColorPath) !==
                    get(settings, highlightColorPath)
                ) {
                    FirebaseLogger.eventBranding(
                        'eventBrandingHighlightColorUsed',
                        {
                            ...commonProps,
                            color: get(settings, highlightColorPath),
                            prev_color: get(prevSettings, highlightColorPath),
                        }
                    );
                }

                const primaryButtonTextColorPath =
                    'live_config.eventBranding.primaryButtonTextColor';
                if (
                    get(prevSettings, primaryButtonTextColorPath) !==
                    get(settings, primaryButtonTextColorPath)
                ) {
                    FirebaseLogger.eventBranding(
                        'eventBrandingButtonTextColorUsed',
                        {
                            ...commonProps,
                            color: get(settings, primaryButtonTextColorPath),
                            prev_color: get(
                                prevSettings,
                                primaryButtonTextColorPath
                            ),
                        }
                    );
                }

                const backgroundPath = 'live_config.eventBranding.background';
                if (
                    !isEqual(
                        get(prevSettings, backgroundPath),
                        get(settings, backgroundPath)
                    )
                ) {
                    const template_type = get(
                        settings,
                        `${backgroundPath}.type`
                    );
                    const props = {
                        ...commonProps,
                        template_type: get(settings, `${backgroundPath}.type`),
                        prev_template_type: get(
                            prevSettings,
                            `${backgroundPath}.type`
                        ),
                    };

                    const bgValue = get(settings, `${backgroundPath}.value`);
                    switch (template_type) {
                        case 'solidColor':
                            props.color = bgValue;
                            break;
                        case 'radialGradient':
                            props.gradient_type = 'radial_gradient';
                            props.colors = bgValue;
                            break;
                        case 'linearGradient':
                            props.gradient_type = 'linear_gradient';
                            props.colors = bgValue;
                            break;
                        case 'pattern':
                            props.pattern_type = bgValue;
                            break;
                        case 'image':
                            props.image_url = bgValue;
                            break;
                        default:
                            break;
                    }

                    FirebaseLogger.eventBranding(
                        'eventBrandingBackgroundOptionUsed',
                        props
                    );
                }

                const fontFamilyPath =
                    'live_config.eventBranding.font.fontFamily';
                if (
                    !isEqual(
                        get(prevSettings, fontFamilyPath),
                        get(settings, fontFamilyPath)
                    )
                ) {
                    const fontType = get(
                        settings,
                        'live_config.eventBranding.font.isCustom'
                    )
                        ? 'custom'
                        : 'default';

                    FirebaseLogger.customFont('fontSaved', {
                        font_type: fontType,
                        font_name: get(settings, fontFamilyPath),
                    });
                }

                const autoBrandAppliedPath =
                    'live_config.eventBranding.isAutoBrandApplied';
                if (
                    get(settings, autoBrandAppliedPath) &&
                    get(prevSettings, autoBrandAppliedPath) !==
                        get(settings, autoBrandAppliedPath)
                ) {
                    FirebaseLogger.customizeEvent('autoBrandUsed', {
                        ...commonProps,
                    });
                }
            }
        }
    };

    const putAirmeet = async (prevSettings) => {
        const { airmeetId, community_id } = airmeet || {};

        if (airmeetId && community_id && !isLoading) {
            setIsLoading(true);
            try {
                await callApi({
                    endpoint: '/airmeet',
                    method: 'PUT',
                    body: {
                        airmeetId,
                        communityId: community_id,
                        ...(settings || {}),
                    },
                    type: 'json',
                });
                handleAnalytics(prevSettings);
                successToast(t(keys.RHS_CUSTOMIZE_TOAST_SUCCESS));
                onToggle(false);
            } catch (e) {
                errorToast(t(keys.SCHEDULE_MEETING_TOASTS_ERROR_OCCURRED));
            }
            setIsLoading(false);
        }
    };

    const { setShowRHS } = useMainNavigation();
    const hideRHS = useCallback(() => {
        setShowRHS(false);
    }, [setShowRHS]);

    const handleChange = (type, value) => {
        const updateObj = { ...(settings || {}) };
        switch (type) {
            case 'is_light_theme':
                {
                    const newIsLightTheme = !!value;
                    updateObj.theme_info[type] = newIsLightTheme;

                    // Auto-Update BG image acc. to theme if default theme image was selected
                    const defaultBgImg = DEFAULT_BACKGROUND_IMAGES.find(
                        (item) =>
                            (item?.url || '').endsWith(
                                updateObj?.live_config?.eventBranding
                                    ?.background?.value
                            )
                    );
                    if (
                        defaultBgImg &&
                        (defaultBgImg?.isDefaultDarkThemeBg ||
                            defaultBgImg?.isDefaultLightThemeBg)
                    ) {
                        let defaultBgValue = null;
                        if (newIsLightTheme) {
                            defaultBgValue = DEFAULT_BACKGROUND_IMAGES.find(
                                (item) => item?.isDefaultLightThemeBg
                            )?.url;
                        } else {
                            defaultBgValue = DEFAULT_BACKGROUND_IMAGES.find(
                                (item) => item?.isDefaultDarkThemeBg
                            )?.url;
                        }
                        if (defaultBgValue) {
                            set(
                                updateObj,
                                'live_config.eventBranding.background',
                                getDefaultBackground(defaultBgValue)
                            );
                        }
                    }
                }
                break;
            case 'primary_color':
                updateObj.theme_info[type] = value || defaultColorString;
                break;
            case 'custom_text':
            case 'live_config':
                if (value?.ccPrimaryLang == undefined) {
                    value['ccPrimaryLang'] = defaultPrimaryLanguage;
                }
                updateObj[type] = value;
                break;
            default:
                break;
        }
        dispatch(setAirmeetState(updateObj));
    };

    const handleSave = (prevSettings) => {
        putAirmeet(prevSettings);
    };

    const handleCancel = () => {
        onToggle && onToggle(false);
    };

    const handleClose = () => {
        hideRHS && hideRHS();
    };

    const handleDiscardChanges = (originalSettings, action = 'cancel') => {
        // Revert local theme changes
        dispatch(
            setAirmeetState({
                ...(originalSettings || {}),
            })
        );

        action === 'cancel'
            ? onToggle && onToggle(false)
            : hideRHS && hideRHS();

        setPromptData(null);
    };

    return (
        <CustomizeTab
            headerTitle={
                type === 'SETTINGS'
                    ? t(keys.RHS_CUSTOMIZE_TITLE)
                    : t(keys.RHS_SETTINGS_BRANDING_TITLE)
            }
            handleSave={handleSave}
            onCancel={handleCancel}
            onClose={handleClose}
            promptData={promptData}
            setPromptData={setPromptData}
            isLoading={isLoading}
            settings={settings}
            onDiscardChanges={handleDiscardChanges}
            portalContainerId='eventRHS'
            showPageOverlay={showPageOverlay}
            onPageOverlayClick={showPageOverlay ? () => {} : null}
            disableSave={disableSave}
        >
            {type === 'SETTINGS' ? (
                <CustomizeSettings
                    {...{
                        isConference,
                        live_config,
                        setPromptData,
                        handleChange,
                        logEvent,
                        isScheduleMeetingEnabled,
                        isUGCEnabled,
                        isCustomNavLabelsEnabled,
                        custom_text,
                        setDisableSave,
                        status,
                    }}
                />
            ) : (
                <CustomizeBranding
                    {...{
                        isConference,
                        handleChange,
                        community_id,
                        theme_info,
                        setShowPageOverlay,
                        live_config,
                        logEvent,
                    }}
                />
            )}
        </CustomizeTab>
    );
}

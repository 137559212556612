import { useEffect, useState } from 'react';

const MAX_DELAY = Math.pow(2, 31) - 1;

export default function useTimeout(timeoutInMilliseconds) {
    const [isTimerReached, setIsTimerReached] = useState(false);

    useEffect(() => {
        let timer;
        if (timeoutInMilliseconds > 0) {
            timer = setTimeout(() => {
                setIsTimerReached(true);
            }, Math.min(MAX_DELAY, timeoutInMilliseconds));
        } else if (timeoutInMilliseconds !== null) {
            setIsTimerReached(true);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [timeoutInMilliseconds]);

    return { isTimerReached };
}

import Btn from 'atoms/Button/btn';
import Divider from 'atoms/Divider/Divider';
import ToggleSwitch from 'atoms/Switch/Switch';
import Tooltip from 'atoms/Tooltip/Tooltip';
import { POLL_TYPE } from 'components/Polls/constants';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import WithTooltip from 'components/general/WithTooltip';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text/index';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import useToasts from 'hooks/useToasts';
import keys from 'locale/keys';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLiveConfig } from 'store/selectors';
import styled from 'styled-components';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import firebaseLogger from 'utils/firebaseLogger';
import { localToStateSettings } from '../utils';
import Checkbox from 'atoms/Checkbox/Checkbox';

export default function SettingItem({
    liveConfig,
    setPromptData,
    onChange,
    eventSettings,
    isConference = true,
    logEvent,
    item,
}) {
    const { t } = useCustomTranslation();
    const {
        user,
        firebaseClient,
        featureDataClients: { chat: chatFirebaseClient },
        airmeet: {
            airmeetId,
            data: {
                currentAirmeet: { sessions = [] },
                currentSession,
                currentAirmeet,
                userRole,
            },
        },
    } = useLiveAirmeetContext();

    const { hideResourcesTab } = useSelector(getLiveConfig);

    const { successToast, errorToast } = useToasts();

    const clearSessionsData = (type) => {
        if (type) {
            (sessions || []).forEach((session) => {
                if (session && session.sessionid) {
                    if (type === 'polls') {
                        firebaseClient.setData(
                            `${airmeetId}/polls/${POLL_TYPE.SESSION}/${session.sessionid}`,
                            null
                        );
                        if (getAirmeetUtilInstance())
                            getAirmeetUtilInstance().cleanupEvent(
                                'poll',
                                {},
                                true
                            );
                    } else {
                        if (type === 'questions') {
                            getAirmeetUtilInstance().cleanupEvent('question', {
                                sessionId: session.sessionid,
                            });
                        }
                        if (type === 'messages') {
                            getAirmeetUtilInstance().cleanupEvent('chat', {
                                sessionId: session.sessionid,
                            });
                            chatFirebaseClient.setData(
                                `${airmeetId}/chats/Pinned/SESSION_FEED/${session.sessionid}`,
                                null
                            );
                            chatFirebaseClient.setData(
                                `${airmeetId}/liveAirmeet/sessions/${session.sessionid}/${type}`,
                                null
                            );
                            chatFirebaseClient.setData(
                                `${airmeetId}/liveAirmeet/sessions/${session.sessionid}/moderateWriteNode`,
                                null
                            );
                        }
                        firebaseClient.setData(
                            `${airmeetId}/liveAirmeet/sessions/${session.sessionid}/${type}`,
                            null
                        );
                    }
                }
            });
        }
    };

    const clearEventFeed = () => {
        chatFirebaseClient.setData(`${airmeetId}/chats/General`, null);
        chatFirebaseClient.setData(
            `${airmeetId}/chats/moderateWriteNode`,
            null
        );
        chatFirebaseClient.setData(
            `${airmeetId}/chats/Pinned/EVENT_FEED`,
            null
        );

        if (currentSession?.sessionid) {
            getAirmeetUtilInstance().cleanupEvent('chat', {
                sessionId: currentSession.sessionid,
            });
        }
    };
    const clearEventPolls = () => {
        firebaseClient.setData(`${airmeetId}/polls/${POLL_TYPE.EVENT}`, null);

        if (getAirmeetUtilInstance()?.cleanupEvent)
            getAirmeetUtilInstance().cleanupEvent('poll', {}, true);
    };

    const onClear = (data) => {
        try {
            if (data && data?.item?.key) {
                switch (data?.item?.key) {
                    case 'hideEventFeed':
                        clearEventFeed();
                        logEvent && logEvent('clearEventFeed');
                        break;
                    case 'hideSessionsLiveChat':
                        isConference
                            ? clearSessionsData('messages')
                            : clearEventFeed();
                        logEvent && logEvent('clearSessionsMessages');
                        break;
                    case 'hideSessionsQA':
                        clearSessionsData('questions');
                        logEvent && logEvent('clearSessionsQuestions');
                        break;
                    case 'hideSessionsPolls':
                        isConference
                            ? clearSessionsData('polls')
                            : clearEventPolls();
                        logEvent && logEvent('clearSessionsPolls');
                        break;
                    case 'hideEventPolls':
                        clearEventPolls();
                        logEvent && logEvent('clearEventPolls');
                        break;
                    default:
                        break;
                }
                successToast(t(keys.RHS_CUSTOMIZE_TOAST_CLEAR_SUCCESS));
            }
            setPromptData(null);
        } catch (e) {
            errorToast(t(keys.SCHEDULE_MEETING_TOASTS_ERROR_OCCURRED));
        }
    };

    const handleClear = (item) => {
        setPromptData({
            title: item?.clearTitle,
            description: item?.clearDescription,
            resolveText: t(keys.RHS_CUSTOMIZE_CLEAR),
            item,
            onResolve: onClear,
        });
    };

    const handleChange = (setting) => {
        const newVal = localToStateSettings(setting, liveConfig, eventSettings);
        onChange('live_config', newVal);
    };

    const handleHide = (setting) => {
        if (setting.key === 'hideResourcesTab') {
            firebaseLogger.rhManagement('rhResourceTabEnabledInLiveEvent', {
                community_id: currentAirmeet.community_id,
                airmeet_id: airmeetId,
                user_id: user.id,
                user_role: userRole,
                action: hideResourcesTab ? 'enable' : 'disable',
            });
        }
        if (setting?.showPromptOnHide && !setting?.isHidden) {
            setPromptData({
                title: setting?.promptTitle,
                description: setting?.promptMessage,
                resolveText: t(keys.RHS_CUSTOMIZE_HIDE),
                onResolve: () => {
                    handleChange(setting);
                    setPromptData(null);
                },
            });
        } else {
            handleChange(setting);
        }
    };

    const {
        hasParent,
        hasChildSettings,
        isLastChild,
        isChecked,
        isDisabled,
        hideDivider,
    } = useMemo(() => {
        const parent = eventSettings.find((st) => st?.key === item?.parentKey);
        const hasParent = !!parent;
        const isParentChecked = hasParent
            ? parent?.isInverted
                ? parent?.isHidden
                : !parent?.isHidden
            : false;
        return {
            hasParent,
            hasChildSettings: !!eventSettings.find(
                (st) => st?.parentKey === item?.key
            ),
            isLastChild:
                item?.parentKey &&
                [...(eventSettings || [])]
                    .reverse()
                    .find((st) => st?.parentKey === item?.parentKey)?.key ===
                    item?.key,
            isChecked: item?.isInverted ? item.isHidden : !item.isHidden,
            isDisabled: item?.disabled || (hasParent && !isParentChecked),
            hideDivider: item?.hideDivider,
        };
    }, [eventSettings, item]);

    return (
        <>
            <SettingsWrapper
                isCheckbox={item?.isCheckbox}
                hasParent={hasParent}
                hasChildSettings={hasChildSettings}
                isLastChild={isLastChild}
            >
                <FlexDiv alignItems='center' width='100%'>
                    {item?.isCheckbox ? (
                        isDisabled ? null : (
                            <Box
                                display='flex'
                                alignItems='center'
                                mb='x5'
                                mt={-2}
                                pl={2}
                            >
                                <Checkbox
                                    label={item?.label}
                                    isChecked={item?.isHidden}
                                    iconSize='20px'
                                    labelVariant={'subtext2'}
                                    onClick={() => handleHide(item)}
                                    allowClickOnLabel
                                    filledSvgColor='accentPrimary.1'
                                />
                            </Box>
                        )
                    ) : (
                        <>
                            <FlexDiv flex={1} alignItems='center' pr='20px'>
                                <Title
                                    variant={
                                        item?.parentKey ? 'caption' : 'body2'
                                    }
                                    color={
                                        isDisabled
                                            ? 'text.default.disabled'
                                            : 'text.default.secondary'
                                    }
                                    pr={1}
                                    pl={item?.parentKey ? '16px' : '0px'}
                                >
                                    {item.isNew && (
                                        <Text
                                            variant={'caption10-bold'}
                                            color={'text.other.primary'}
                                            p={0}
                                            backgroundColor={'sem.error'}
                                            border={'1px solid'}
                                            borderColor={'border.default1'}
                                            borderRadius={'20px'}
                                            mr={1}
                                            textTransform='uppercase'
                                        >
                                            {t(keys.GENERAL_NEW)}
                                        </Text>
                                    )}
                                    {item.label}
                                    {item.hint && (
                                        <Tooltip
                                            content={item.hint}
                                            maxWidth='208px'
                                            hasWrapper={true}
                                            alignOffset={4}
                                        >
                                            <StyledSvgSprite
                                                icon='ic-info'
                                                height='20px'
                                                width='20px'
                                                fill='text.default.secondary'
                                                ml='8px'
                                            />
                                        </Tooltip>
                                    )}
                                </Title>
                            </FlexDiv>
                            <FlexDiv alignItems='center'>
                                {item.isClearEnabled && (
                                    <WithTooltip
                                        tooltip={t(
                                            keys.RHS_SETTING_CLEAR_EXISTING_DATA_TOOLTIP_TEXT
                                        )}
                                        place='bottom'
                                        isThemeV2={true}
                                    >
                                        <StyledSvgSprite
                                            icon='arrow_clockwise'
                                            fill='text.default.primary'
                                            height='20px'
                                            width='20px'
                                            mr='6px'
                                        />
                                        <Btn
                                            variant='link'
                                            onClick={() => handleClear(item)}
                                            mr='x4'
                                        >
                                            {t(keys.RHS_CUSTOMIZE_CLEAR)}
                                        </Btn>
                                    </WithTooltip>
                                )}
                                <WithTooltip
                                    tooltip={item?.tooltip}
                                    place='bottom'
                                    isThemeV2={true}
                                >
                                    <ToggleSwitch
                                        checked={isChecked}
                                        disabled={isDisabled}
                                        onCheckedChange={() => handleHide(item)}
                                        uncheckedLabel={t(keys.GENERAL_OFF)}
                                        checkedLabel={t(keys.GENERAL_ON)}
                                    />
                                </WithTooltip>
                            </FlexDiv>
                        </>
                    )}
                </FlexDiv>
            </SettingsWrapper>
            {isChecked && item?.childComponent && (
                <item.childComponent
                    liveConfig={liveConfig}
                    onChange={onChange}
                    eventSettings={eventSettings}
                    item={item}
                    airmeetId={airmeetId}
                    setPromptData={setPromptData}
                />
            )}
            {hideDivider ||
            hasChildSettings ||
            (hasParent && !isLastChild) ? null : (
                <Divider />
            )}
        </>
    );
}

const SettingsWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: ${({ hasParent, isCheckbox }) =>
        hasParent ? (isCheckbox ? '0px' : '0 0 20px 0') : '20px 0'};

    &:last-child {
        border-bottom: 0;
    }
`;

const Title = styled(Text)`
    display: flex;
    align-items: center;
    &::first-letter {
        text-transform: capitalize;
    }
`;

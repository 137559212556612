const SHOW_CLOSE_CAPTION_TOGGLE = 'showCloseCaption';
export const SHOW_FEED_FOR_REPLAY = 'showFeedForReplay';
export const SHOW_QNA_FOR_REPLAY = 'showQnAForReplay';
export const SHOW_POLLS_FOR_REPLAY = 'showPollsForReplay';

export const stateToLocalSettings = (
    settings,
    liveConfig,
    closeCaptionFlag
) => {
    // if closeCaptionFlag's value is `SHOW` and showCloseCaption doesn't exist, then showCloseCaption will be true
    const liveConfigData = {
        ...liveConfig,
        showCloseCaption:
            liveConfig?.showCloseCaption !== undefined
                ? liveConfig?.showCloseCaption
                : closeCaptionFlag === 'SHOW',
    };

    const newSettings = [];
    [...(settings || [])].forEach((item) => {
        if (
            item &&
            item.key &&
            (item.key !== SHOW_CLOSE_CAPTION_TOGGLE ||
                (item.key === SHOW_CLOSE_CAPTION_TOGGLE &&
                    closeCaptionFlag === 'SHOW'))
        ) {
            const newItem = { ...item };
            if (
                newItem?.key &&
                liveConfigData &&
                liveConfigData[newItem.key] !== null &&
                liveConfigData[newItem.key] !== undefined
            ) {
                newItem.isHidden = !!liveConfigData[newItem.key];
            }
            newSettings.push(newItem);
        }
    });

    return { eventSettings: newSettings, liveConfigData };
};

export const localToStateSettings = (setting, liveConfig, eventSettings) => {
    // Update state of current setting
    const newLiveConfig = { ...(liveConfig || {}) };
    if (setting?.key) {
        newLiveConfig[setting.key] = !setting.isHidden;
    }

    // Uncheck current setting's children if current settings is unchecked
    const isChecked = !(setting?.isInverted
        ? setting.isHidden
        : !setting.isHidden);
    if (!isChecked) {
        const childSettings = eventSettings.filter(
            (item) => item?.parentKey === setting?.key
        );
        if (childSettings.length > 0) {
            childSettings.forEach((item) => {
                if (item?.key) {
                    newLiveConfig[item?.key] = item?.isInverted ? false : true;
                }
            });
        }
    }

    return newLiveConfig;
};

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getForbiddenActions } from 'store/actions/sessionAccessible';
import { logger } from 'utils/logger';
import useLiveAirmeetContext from './useLiveAirmeetContext';

let MAX_RETRIES = 2;
let isApiCalled = false;
function useForbiddenActions(props) {
    const { sessionId } = props || {};
    const dispatch = useDispatch();
    const {
        airmeet: {
            airmeetId,
            featureFlags: { isCustomAttendeeExpEnabled },
        },
    } = useLiveAirmeetContext();
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiCallRetryDepth, setApiCallRetryDepth] = useState(0);
    const forbiddenAction = useSelector(
        (state) => state.sessionAccessible?.sessionAccess
    );

    const getSessionAccessData = useCallback(async () => {
        try {
            const response = await dispatch(getForbiddenActions(airmeetId));
            if (response?.payload?.error || response?.error) {
                setHasError(true);
                return;
            }
        } catch (error) {
            logger.error('Failed to fetch session access info.', error);
            setHasError(true);
        }
    }, [airmeetId, dispatch]);

    const forbiddenActionReturnObj = useMemo(() => {
        return {
            data: forbiddenAction.forbiddenActions ? forbiddenAction : null,
            loading: isLoading,
            error: hasError,
        };
    }, [forbiddenAction, isLoading, hasError]);

    useEffect(() => {
        let timerId;
        (async () => {
            if (
                isCustomAttendeeExpEnabled &&
                (isEmpty(forbiddenAction) ||
                    (forbiddenAction?.status &&
                        forbiddenAction?.status !== 200)) &&
                !isApiCalled &&
                apiCallRetryDepth <= MAX_RETRIES
            ) {
                setIsLoading(true);
                isApiCalled = true;
                await getSessionAccessData();
                timerId = setTimeout(() => {
                    setApiCallRetryDepth((prev) => prev + 1);
                }, 2000);
                isApiCalled = false;
                setIsLoading(false);
            }
        })();
        return () => clearTimeout(timerId);
    }, [
        isCustomAttendeeExpEnabled,
        forbiddenAction,
        apiCallRetryDepth,
        getSessionAccessData,
    ]);

    const checkAccessDenied = useCallback(
        (sessionId) =>
            isCustomAttendeeExpEnabled
                ? (
                      get(
                          forbiddenAction,
                          `forbiddenActions.sessionLevel.${sessionId}`,
                          []
                      ) || []
                  ).includes('ENTER_SESSION')
                : false,
        [forbiddenAction, isCustomAttendeeExpEnabled]
    );
    const checkSessionDiscovery = useCallback(
        (sessionId) =>
            isCustomAttendeeExpEnabled
                ? (
                      get(
                          forbiddenAction,
                          `forbiddenActions.sessionLevel.${sessionId}`,
                          []
                      ) || []
                  ).includes('SESSION_DISCOVERY')
                : false,
        [forbiddenAction, isCustomAttendeeExpEnabled]
    );
    const hideSessionDiscovery = useMemo(
        () => checkSessionDiscovery(sessionId),
        [checkSessionDiscovery, sessionId]
    );
    const accessDenied = useMemo(() => checkAccessDenied(sessionId), [
        checkAccessDenied,
        sessionId,
    ]);

    return {
        ...{
            ...(forbiddenActionReturnObj || {}),
            accessDenied,
            checkAccessDenied,
            hideSessionDiscovery,
            checkSessionDiscovery,
        },
    };
}

export default useForbiddenActions;

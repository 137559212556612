import Cookies from 'js-cookie';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import { logger } from 'utils/logger';
import { NOISE_CANCELLATION } from '../hooks/useAudioModes';
import { setTimeoutWorker, WORKER_IDS } from './timeoutWorker';

const USER_KEY = 'user';
const LOGGED_IN_AT_KEY = 'loggedInAt';
const HAS_LOGGED_IN_AT_KEY = 'hasLoggedInAt'; // setting to localStorage is used only for redirecting user to other tabs when logout.

const getCookieDomain = () => {
    let cookieDomain = '.airmeet.com';
    try {
        const hostNameArr = window.location?.hostname.split('.');
        if (!hostNameArr.includes('airmeet')) {
            cookieDomain = `.${window.location?.hostname}`;
        }
    } catch (error) {
        logger.error('Error in extracting domain name!', error);
    } finally {
        logger.info(
            'authService.getCookieDomain: return cookieDomain',
            cookieDomain
        );
        return cookieDomain;
    }
};

let redirecting = false;
const handleUserLogoutRedirection = () => {
    if (redirecting) {
        return;
    }
    redirecting = true;
    if (
        window.location.pathname.includes('/e/') ||
        window.location.pathname.includes('/es/') ||
        window.location.pathname.includes('/event/')
    ) {
        logger.info(
            'authService.handleUserLogoutRedirection: logged out on event page.'
        );
        window.location.reload();
    } else {
        window.location.href = '/signin';
    }
};

const COOKIE_PROPS = {
    expires: 365,
    domain: getCookieDomain(),
    path: '/',
};

function getNullSafe(key) {
    try {
        const val = localStorage.getItem(key);

        return val === 'null' ? null : val;
    } catch {
        logger.info('authService: localStorage is disabled');
    }
}

function getUserData() {
    try {
        const userCookie = Cookies.get(USER_KEY);
        let userFormCookie = null;
        if (userCookie) {
            // Try to parse JSON user cookie
            try {
                userFormCookie = JSON.parse(userCookie);
            } catch (e) {
                logger.error(
                    'authService.getUserData: Error JSON.parse user cookie : ',
                    e
                );
            }
        }

        if (userFormCookie) {
            return userFormCookie;
        } else {
            // If proper user cookie is not present try to get user info from local storage
            const userFromLocalStorage = getNullSafe(USER_KEY);
            if (userFromLocalStorage) {
                try {
                    return JSON.parse(userFromLocalStorage);
                } catch (e) {
                    logger.error(
                        'authService.getUserData: Error JSON.parse local user : ',
                        e
                    );
                }
            }
        }
    } catch (e) {
        logger.error('authService.getUserData: ', e);
    }

    return null;
}
const AUTH_COOKIE_KEYS = [USER_KEY, LOGGED_IN_AT_KEY];
const getAuthCookieValues = () => {
    const authCookieValues = {};
    AUTH_COOKIE_KEYS.forEach((key) => {
        authCookieValues[key] = Cookies.get(key);
    });
    return authCookieValues;
};
class _AuthService {
    constructor() {
        this.state = null;
        this._user = null;
        this._guest = null;
        this._tokenUser = null;
        this._token = null;
        this.initialize();
        this.addLogoutEventListener();
    }

    initialize() {
        const authUserToken = getNullSafe('authUserToken');
        // As we remove the authUserToken from the local storage,
        // we need to cleanup from storage
        if (authUserToken) {
            this.logout();
        }

        this._user = getUserData();

        const localGuest = getNullSafe('guest');
        if (localGuest) {
            try {
                this._guest = JSON.parse(localGuest);
            } catch (e) {
                logger.error(
                    'authService.initialize: Error JSON.parse localGuest : ',
                    e
                );
            }
        } else {
            this._guest = null;
        }
    }

    //TODO: Add a listener like this for cookies
    addLogoutEventListener() {
        window.addEventListener('storage', (event) => {
            if (
                (this.token && this.tokenUser && this.tokenUser.id_seq) ||
                window.location.href.includes('embedded')
            ) {
                return;
            }
            if ([USER_KEY].includes(event.key) && event.newValue === null) {
                this.logout();
                handleUserLogoutRedirection();
            }
            if (
                [LOGGED_IN_AT_KEY].includes(event.key) &&
                event.newValue === null
            ) {
                handleUserLogoutRedirection();
            }
            if (
                [HAS_LOGGED_IN_AT_KEY].includes(event.key) &&
                event.newValue === null
            ) {
                handleUserLogoutRedirection();
            }
        });

        const listenCookieChange = (callback, delay = 1000) => {
            let lastCookie = getAuthCookieValues();

            const timeoutCallback = () => {
                let cookie = getAuthCookieValues();
                if (!isEqual(cookie, lastCookie)) {
                    try {
                        return !callback({
                            newValue: cookie,
                            oldValue: lastCookie,
                        });
                    } finally {
                        lastCookie = cookie;
                    }
                }
                return true;
            };
            setTimeoutWorker(
                timeoutCallback,
                delay,
                true,
                WORKER_IDS.AUTH_SERVICE
            );
        };
        listenCookieChange(({ newValue, oldValue }) => {
            if (
                (this.token && this.tokenUser && this.tokenUser.id_seq) ||
                window.location.href.includes('embedded')
            ) {
                return false;
            }

            if (
                (window.location.pathname.indexOf('/event/') === 0 &&
                    oldValue.loggedInAt === undefined &&
                    newValue.loggedInAt !== undefined) ||
                (newValue.user === undefined &&
                    newValue.loggedInAt === undefined)
            ) {
                logger.info('logged out from other region.');
                handleUserLogoutRedirection();
                return true;
            }
            return false;
        }, 1000);
    }

    isLogin(allowGuest = false) {
        if (this.token && this.tokenUser && this.tokenUser.id_seq) {
            return true;
        }

        /*
         * A user has `direct: true` if they are landing through a magic link
         * See: ../store/reducers/auth.js
         */
        //  if (this._user && this._user.direct) return true;

        let user = null;
        const userData = getUserData();
        if (userData) {
            user = userData;
        } else if (allowGuest) {
            try {
                user = JSON.parse(getNullSafe('guest'));
            } catch (e) {
                logger.error(
                    'authService.isLogin: Error JSON.parse guest : ',
                    e
                );
            }
        }

        if (!!user?.id_seq) {
            return true;
        }

        return false;
    }

    getCachedUser() {
        return (this.loggedInAt ? this.user : null) || this.guest;
    }

    listen(store) {
        //return () => (this.state = store.getState());
    }

    logout() {
        this.user = null;
        this.guest = null;
        // localStorage.clear();
        // clear the local storage except the feedback
        Object.entries(localStorage).forEach(([key]) => {
            if (
                key.indexOf('feedback-') >= 0 ||
                key.indexOf('pref_') >= 0 ||
                key.indexOf('intro-seen-') >= 0 ||
                [
                    'cookieBanner',
                    'announcementBanner',
                    'productTourStat',
                    'meterAlerts',
                    'closeBannerClickedTime',
                    'intro-video-shown',
                    'WEBINAR_NAME_UPDATE_SHOWN',
                    'EVENT_TEMPLATES_INTRO_MODAL_SHOWN',
                    'event_dash_v3_updates_modal_closed',
                    NOISE_CANCELLATION,
                ].includes(key)
            ) {
                return;
            }
            delete localStorage[key];
        });
        Cookies.remove(USER_KEY, COOKIE_PROPS);
        Cookies.remove(LOGGED_IN_AT_KEY, COOKIE_PROPS);
        // End fullstory recording session for user
        if (window.FS && isFunction(window.FS.anonymize)) window.FS.anonymize();
    }

    set user(data = {}) {
        /*
         * This makes it possible for a user landing through a magic link
         * to be contained only in the app memory
         * A simple refresh is good enough to kick them out, unless they use
         * the same magic link to jump in again
         */

        // if (data && data.direct) {
        //     return (this._user = data);
        // }

        if (data !== this._user) {
            let userData = '';
            try {
                const {
                    id,
                    id_seq,
                    last_active_region,
                    guest,
                    plan_intent,
                    roles,
                    userRole,
                    singup_intent,
                    email,
                } = isObject(data) ? data : {};

                userData = JSON.stringify({
                    id,
                    id_seq,
                    last_active_region,
                    guest,
                    plan_intent,
                    roles,
                    userRole,
                    singup_intent,
                    email,
                    name: '',
                    first_name: '',
                    last_name: '',
                });
            } catch (e) {
                logger.error(
                    'authService set user: Error JSON.stringify user : ',
                    e
                );
            }

            const hasRemoved = data === null || typeof data === 'undefined';
            try {
                if (hasRemoved) {
                    Cookies.remove(USER_KEY, COOKIE_PROPS);
                } else {
                    logger.info(
                        'authService.set user: Set cookie: ',
                        COOKIE_PROPS
                    );
                    Cookies.set(USER_KEY, userData, COOKIE_PROPS);
                }
            } catch (e) {
                logger.error('authService: setting user cookie ', e);
            }
            try {
                localStorage[hasRemoved ? 'removeItem' : 'setItem'](
                    USER_KEY,
                    userData
                );
            } catch (e) {
                logger.error('authService: set user to localStorage ', e);
            }

            this._user = data;
            // If we're setting a user, clear any existing guest information
            if (!!data && !!this.guest) {
                this.guest = null;
            }
        }
    }

    get user() {
        if (this._tokenUser) {
            return null;
        } else if (this._user) {
            return this._user;
        } else {
            return getUserData();
        }
    }

    set loggedInAt(loggedInAt) {
        const hasRemoved =
            loggedInAt === null || typeof loggedInAt === 'undefined';

        try {
            if (hasRemoved) {
                logger.info('authService.set loggedInAt: remove cookie: ', {
                    user: this.user?.id,
                    loggedInAt: this.loggedInAt,
                    COOKIE_PROPS,
                    debug: Cookies.get('airmeet_debug'),
                });
                Cookies.remove(LOGGED_IN_AT_KEY, COOKIE_PROPS);
            } else {
                logger.info('authService.set loggedInAt: Set cookie: ', {
                    user: this.user?.id,
                    oldLoggedInAt: this.loggedInAt,
                    loggedInAt,
                    COOKIE_PROPS,
                    debug: Cookies.get('airmeet_debug'),
                });
                Cookies.set(LOGGED_IN_AT_KEY, loggedInAt, COOKIE_PROPS);
            }
        } catch (e) {
            logger.error(`authService: setting ${LOGGED_IN_AT_KEY} cookie `, e);
        }

        // this is used only for old users, it will clear localStorage when logout
        if (hasRemoved) {
            localStorage[hasRemoved ? 'removeItem' : 'setItem'](
                LOGGED_IN_AT_KEY,
                loggedInAt
            );
        }
        // setting to localStorage is used only for redirecting user to other tabs when logout.
        return localStorage[hasRemoved ? 'removeItem' : 'setItem'](
            HAS_LOGGED_IN_AT_KEY,
            loggedInAt
        );
    }

    get loggedInAt() {
        return this._tokenUser
            ? null
            : Cookies.get(LOGGED_IN_AT_KEY) ||
                  getNullSafe(LOGGED_IN_AT_KEY) ||
                  getNullSafe(HAS_LOGGED_IN_AT_KEY);
    }

    set guest(data) {
        if (data !== this._guest && (!data || !data.direct)) {
            try {
                localStorage[
                    data === null || typeof data === 'undefined'
                        ? 'removeItem'
                        : 'setItem'
                ]('guest', JSON.stringify(data));
                this._guest = data;
            } catch (e) {
                logger.error(
                    'authService set guest: Error JSON.stringify data : ',
                    e
                );
            }
        }
        this._guest = data;
    }

    get guest() {
        return this._tokenUser ? null : this._guest;
    }

    setTokenUser(tokenUser, token) {
        this._tokenUser = tokenUser;
        this._token = token;
    }

    get tokenUser() {
        return this._tokenUser;
    }

    get guestToken() {
        return this._guest && this._guest.auth_token
            ? this._guest.auth_token
            : null;
    }

    getGuestToken() {
        // if we use the guest token on other pages then during a signup, login,
        // process get conflicts with loggedIn auth token and 401 error came,
        // also if you need guest token on other page then you can use this.guestToken
        const allowGuestToken =
            window.location.pathname.indexOf('/event/') === 0;
        if (!allowGuestToken || this.loggedInAt) {
            return null;
        }
        const cookieValues = getAuthCookieValues();
        if (cookieValues.loggedInAt) {
            return null;
        }
        return this.guestToken;
    }

    get token() {
        return this._tokenUser ? this._token : this.getGuestToken();
    }
}

const AuthService = new _AuthService();
export default AuthService;

import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setCustomFPSConfig } from '../store/actions/liveAirmeet';
import { ALL_FEATURES_CONFIG } from './useFeatureControl';

const useCustomFPSConfig = ({ featuresConfig, currentAirmeet }) => {
    const dispatch = useDispatch();

    const customFPS = useMemo(() => {
        const backendConfig = {
            isCustomFPSEnabled: currentAirmeet?.is_custom_fps_config_enabled,
            customFPSVal: currentAirmeet?.custom_fps_config_value?.fps,
        };

        let customFPSVal = null;
        if (backendConfig.isCustomFPSEnabled) {
            customFPSVal = backendConfig.customFPSVal;
        }
        //FE config to override BE config
        if (
            featuresConfig &&
            featuresConfig[ALL_FEATURES_CONFIG.CUSTOM_FPS_CONFIG]
        ) {
            customFPSVal =
                featuresConfig[ALL_FEATURES_CONFIG.CUSTOM_FPS_CONFIG];
        }
        return customFPSVal;
    }, [featuresConfig, currentAirmeet]);

    useEffect(() => {
        dispatch(setCustomFPSConfig(customFPS));
    }, [dispatch, customFPS]);
};

export default useCustomFPSConfig;

const BLANK = '';
const STAGING = 'staging';

export const useDailyStagingEnv = (
    process.env.REACT_APP_PROD_ENDPOINT || BLANK
).includes(STAGING);

export const DEFAULT_MAX_TILES_PER_PAGE = 25;

export const MEDIA_STATES = {
    MUTED: 'muted',
    UNMUTED: 'unmuted',
    CHANGING: 'changing',
    BLOCKED: 'blocked',
};

export enum LAYOUT_STATES {
    GRID = 'grid',
    SPEAKER = 'speaker',
}

export const DEFAULT_LAYOUT = LAYOUT_STATES.GRID;

export type GM_MEDIA_STATE = {
    audio: string;
    video: string;
};

export const DEFAULT_AUDIO_VIDEO_STATE: GM_MEDIA_STATE = {
    audio: MEDIA_STATES.MUTED,
    video: MEDIA_STATES.MUTED,
};

export const getTruncatedRoomNameFromUuid = (uuidStr: string = '') =>
    uuidStr.length > 30 ? uuidStr.substr(0, uuidStr.lastIndexOf('-')) : uuidStr;

export enum ERROR_TYPES {
    GENERIC = 1000,
    NETWORK = 1001,
}

export const RECORDING_START_TIMEOUT = 20 * 1000; // in milliseconds
export const RECORDING_MAX_DURATION = 60 * 60 * 12; // in secs. 12hours max recording at once.
export const RECORDING_MIN_IDLE_TIMEOUT = 60 * 60 * 1; // in secs. Recording will stop if no media is sent for 1hour.

export const EVENT_MAP = {
    JOINED_MEETING: 'joined-meeting',
    LEFT_MEETING: 'left-meeting',
    PARTICIPANT_JOINED: 'participant-joined',
    PARTICIPANT_LEFT: 'participant-left',
    PARTICIPANT_UPDATED: 'participant-updated',
    NETWORK_QUALITY: 'network-quality-change',
    NETWORK_CONNECTION: 'network-connection',
    RECORDING_STARTED: 'recording-started',
    RECORDING_STOPPED: 'recording-stopped',
    RECORDING_START_TIMEOUT: 'recording-start-timeout',
    RECORDING_ERROR: 'recording-error',
    ERROR: 'error',
    TRANSCRIPTION_STARTED: 'transcription-started',
    TRANSCRIPTION_STOPPED: 'transcription-stopped',
    TRANSCRIPTION_MESSAGE: 'transcription-message',
    TRANSCRIPTION_ERROR: 'transcription-error',
    APP_MESSAGE: 'app-message',
};

import { RESOLUTIONS_1080P } from 'containers/StageResolutionsList';
import { hasRTMPStreamingEnabledFromRecorder } from 'context/LiveAirmeet';
import { usePrevious } from 'hooks/common';
import useDeviceInfo from 'hooks/useDeviceInfo';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import useNetworkStatus from 'hooks/useNetworkStatus';
import useSessionContext from 'hooks/useSessionContext';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    getClosedCaptions,
    getIsSessionGenralDataLoaded,
    getStageBroadcastChannels,
} from 'store/selectors';
import { getInviteToStagePermissionProcess } from 'store/selectors/liveAirmeet';
import { getCustomFPSConfig } from 'store/selectors';
import { useUserData } from 'store/selectors/users';
import { RTC_SOURCE_MODULES, noop } from 'utils/constants/common';
import {
    LIVE_AIRMEET_STATE,
    STAGE_DEFAULT_AV_STATES,
} from 'utils/constants/live-airmeet';
import {
    PERSISTENT_STAGE_LOCATIONS,
    SESSION_TYPES,
} from 'utils/constants/sessions';
import { UserLoginType } from 'utils/constants/common';
import { logger } from 'utils/logger';
import PermissionUtils from 'utils/permission-utils';
import { v4 as uuid } from 'uuid';
import { Events } from 'utils/constants/containers/airmeet';

const RESET_DISABLE_AUTO_JOIN_STATE_DURATION = 60 * 1000; // in sec

export default function useLiveEventClientPersistent({
    stageService,
    showCDNStreams,
    currentSession,
    airmeet,
    currentState,
    userStageRole,
    allowMinRoleHost,
    userRole,
    streamVideoProfile,
    mode = PERSISTENT_STAGE_LOCATIONS.LIVESTAGE,
    isMediaModeActive,
}) {
    const liteModeActive = useSelector(
        (state) => state.liveAirmeet.liteModeActive
    );
    const isInviteToStagePermissionInProgress = useSelector(
        getInviteToStagePermissionProcess
    );

    const mirrorLocalVideo = useSelector(
        (store) => store.liveAirmeet.mirrorVideo
    );
    const isBreakoutActive = useSelector(
        (store) => store.liveAirmeet.isBreakoutActive
    );
    const stageBroadcastChannels = useSelector(getStageBroadcastChannels);
    const backStageChannelId = stageBroadcastChannels?.backStage;
    const liveStageChannelId = stageBroadcastChannels?.liveStage;
    const { isMobile } = useDeviceInfo();
    const isSessionGenralDataLoaded = useSelector(getIsSessionGenralDataLoaded);
    const allowToJoinStageChannel =
        !PermissionUtils.isEventCloudHost() ||
        PermissionUtils.isSessionCloudHost() ||
        PermissionUtils.isSessionScreenRecordingUser();
    const disableJoinInChannel =
        !allowToJoinStageChannel ||
        !isSessionGenralDataLoaded ||
        liteModeActive ||
        isBreakoutActive ||
        isMobile ||
        showCDNStreams ||
        (currentSession &&
            currentSession.type &&
            ![SESSION_TYPES.HOSTING, SESSION_TYPES.PRE_RECORDED].includes(
                currentSession.type
            ));
    const is1080PSession =
        currentSession?.streaming_resolution === RESOLUTIONS_1080P;
    const customFPSConfig = useSelector(getCustomFPSConfig);
    const [joinedStageChannel, setJoinedChannel] = useState(null);
    const [joinChannelError, setChannelJoinError] = useState(null);
    const [allowToPublish, setAllowToPublishState] = useState(false);
    const allowToPublishRef = useRef(allowToPublish);
    const publishHandler = useRef(noop);
    // Track the current joining request
    const JoiningProcess = useRef(false);

    const [hasInReConnectingState, setReConnectingState] = useState(false);
    const { isOnline } = useNetworkStatus();

    const hasToSubscribe = PermissionUtils.canSubscribeStream();

    const cloudUserInfo = useUserData(currentSession?.cloud_user_id);
    const enablePublishLiveStream = useLiveAirmeetContext(
        hasRTMPStreamingEnabledFromRecorder
    );
    const { disableAutoJoinState, setDisableAutoJoin } = useSessionContext();

    const methods = useRef({});
    const {
        isClosedCaptioningEnabled,
        enableCCSubscriptionViaFirebase,
    } = useLiveAirmeetContext();

    const { config: closedCaptionConfParams } = useSelector(getClosedCaptions);
    const allowToPublishLatestReq = useRef(false);

    // Channel Name which need to join to session
    const channelId = disableJoinInChannel
        ? null
        : mode === PERSISTENT_STAGE_LOCATIONS.LIVESTAGE
        ? liveStageChannelId || currentSession?.sessionid || airmeet.airmeetId
        : backStageChannelId || `bs-${currentSession?.sessionid}`;
    const previousChannelId = usePrevious(channelId, channelId);
    const previousJoinedChannelId = usePrevious(joinedStageChannel);

    const uid = useMemo(uuid, []);
    methods.current.addLog = (message, props = {}) => {
        logger.info(
            `useLiveEventClientPersistent ${mode}- ${message}`,
            JSON.stringify({
                uid,
                ...props,
                channelId,
                isOnline,
                joinedStageChannel,
                previousChannelId,
                currentState,
                userRole,
                userStageRole,
                allowMinRoleHost,
                disableJoinInChannel,
                status: currentSession?.status,
                is1080PSession,
                disableAutoJoinState,
                allowToPublish,
            })
        );
    };

    methods.current.joinStageChannel = (
        publishVideo = false,
        callback = () => {}
    ) => {
        methods.current.addLog('Join Channel invoke', {
            JoiningProcess: JoiningProcess.current,
            channelId,
            liteModeActive,
        });
        if (
            !channelId ||
            liteModeActive ||
            (JoiningProcess.current && JoiningProcess.current === channelId)
        ) {
            return;
        }
        JoiningProcess.current = channelId;
        methods.current.addLog('Joining Channel');
        publishVideo = false;

        const skipStreamsToSubscribing = {};
        if (cloudUserInfo) {
            skipStreamsToSubscribing[cloudUserInfo.id_seq] = cloudUserInfo.id;
        }

        const userLoginType = PermissionUtils.isUserSessionCloudHost()
            ? UserLoginType.CLOUD_HOST
            : PermissionUtils.isSessionScreenRecordingUser()
            ? UserLoginType.SCREEN_RECORDER
            : UserLoginType.USER;
        stageService
            .join(
                channelId,
                {
                    resolution: streamVideoProfile,
                    skipStreamsToSubscribing: skipStreamsToSubscribing || {},
                    canSubscribe: hasToSubscribe,
                    isClosedCaptioningEnabled:
                        mode === PERSISTENT_STAGE_LOCATIONS.LIVESTAGE
                            ? isClosedCaptioningEnabled
                            : false,
                    is1080Enabled:
                        mode === PERSISTENT_STAGE_LOCATIONS.LIVESTAGE
                            ? is1080PSession
                            : false,
                    enableCCSubscriptionViaFirebase:
                        mode === PERSISTENT_STAGE_LOCATIONS.LIVESTAGE
                            ? enableCCSubscriptionViaFirebase
                            : false,
                    enableDualStream:
                        mode === PERSISTENT_STAGE_LOCATIONS.LIVESTAGE,
                    sourceModule:
                        LIVE_AIRMEET_STATE.goLive === currentState
                            ? RTC_SOURCE_MODULES.LIVE_STAGE
                            : RTC_SOURCE_MODULES.BACK_STAGE,
                    userLoginType,
                    mode,
                    closedCaptionConfParams,
                    customFPSConfig,
                },
                (response) => {}
            )
            .then(async (response) => {
                methods.current.addLog('Joined Channel', {
                    response,
                });
                JoiningProcess.current = false;
                setJoinedChannel(stageService.channelName);
                setChannelJoinError(null);
                callback();
            })
            .catch(function ([e, rejectFromSdk]) {
                methods.current.addLog('Joining Channel Failed', {
                    error: e,
                    rejectFromSdk,
                });
                JoiningProcess.current = false;
                setJoinedChannel(null);
                if (rejectFromSdk) {
                    methods.current.addLog('Joining Channel Failed Set ERROR');
                    setChannelJoinError(e);
                }
            });
    };

    const isReadyToPublish = useMemo(() => {
        const canStartPublish =
            !isInviteToStagePermissionInProgress && joinedStageChannel;
        if (!canStartPublish) {
            methods.current.addLog(
                `Modifying allow to publish isReadyToPublish. Permission modal in progress : ${isInviteToStagePermissionInProgress},  joinedStageChannel: ${joinedStageChannel} }`
            );
        }
        return canStartPublish;
    }, [joinedStageChannel, isInviteToStagePermissionInProgress]);

    const setAllowToPublish = useCallback(
        (allowToPublishFlag, source = '') => {
            allowToPublishLatestReq.current = allowToPublishFlag;
            methods.current.addLog(
                `Update allowToPublish State request received from  ${source}`,
                allowToPublishFlag
            );
            if (!isReadyToPublish && allowToPublishFlag) {
                methods.current.addLog(
                    `skip[hold] to allowToPublish State request received from  ${source}`,
                    allowToPublishFlag
                );
                return;
            }
            setAllowToPublishState(allowToPublishFlag);
            allowToPublishRef.current = allowToPublishFlag;
        },
        [setAllowToPublishState, isReadyToPublish]
    );

    useEffect(() => {
        if (
            allowToPublishLatestReq.current &&
            isReadyToPublish &&
            allowToPublish !== allowToPublishLatestReq.current
        ) {
            methods.current.addLog(
                `Setting allow to publish state to this ${allowToPublishLatestReq.current}, as current state and latest both are not equal `
            );
            setAllowToPublish(allowToPublishLatestReq.current);
        }
    }, [isReadyToPublish, allowToPublish, setAllowToPublish]);

    useEffect(() => {
        if (!joinedStageChannel) {
            return;
        }
        if (
            // allowMinRoleHost &&
            currentSession?.type === SESSION_TYPES.HOSTING
        ) {
            methods.current.addLog('update the publication to persist', {
                allowMinRoleHost,
            });
            stageService.setPersistPublication({
                isPersistPublication: allowMinRoleHost,
            });
        }
    }, [
        allowMinRoleHost,
        currentSession.type,
        joinedStageChannel,
        mirrorLocalVideo,
        stageService,
    ]);

    const publishStageStream = (props) => {
        if (PermissionUtils.isEventCloudHost() && enablePublishLiveStream) {
            methods.current.addLog(
                'Not need to publishStageStream for cloud host in case enable streaming form recorder'
            );
            return;
        }
        if (allowMinRoleHost) {
            stageService
                .enableAudioVideoControls({
                    mirror: mirrorLocalVideo,
                    forceAudioMute: isMediaModeActive,
                    forceVideoMute: isMediaModeActive,
                })
                .catch((e) => {
                    logger.info('Enabling the audio/video controls failed', e);
                });
        } else {
            const attendeeMode = PermissionUtils.isEventCloudHost()
                ? 'audio-only'
                : 'video';
            stageService
                .publishStreamIfNot({
                    attendeeMode,
                    ...props,
                    mirror: mirrorLocalVideo,
                })
                .catch((e) => {
                    logger.info('Publish stream failed', e);

                    if (e?.reason === 'mismatch_channel') {
                        // retrying to publish stream to check whether it can publish or not
                        publishHandler.current();
                    }
                });
        }
    };

    const channelJoined =
        joinedStageChannel !== null && joinedStageChannel === channelId;

    publishHandler.current = () => {
        const canPublish = allowToPublish && channelJoined;
        if (!joinedStageChannel) {
            methods.current.addLog(
                'publishHandler call without joined stage channel',
                {
                    canPublish,
                    channelJoined,
                }
            );
        }

        if (canPublish) {
            methods.current.addLog('Publish stream call, allowToPublish:', {
                canPublish,
                joinedStageChannel,
            });
            stageService.logDataToSDK({
                msg: `userRoleUpdateSource -  Role upgrade requested as ${userStageRole} to publish`,
            });
            publishStageStream({
                isEventCloudHost: PermissionUtils.isSessionCloudHost(), // using this param to mute cloudhost's audio
            });
        } else {
            methods.current.addLog('Un-publish stream call, allowToPublish:', {
                canPublish,
                joinedStageChannel,
            });
            unpublishStageStream();
        }
    };

    const unpublishStageStream = () => {
        methods.current.addLog('Un-publish stream method called', {
            allowToPublish,
        });
        if (allowMinRoleHost) {
            stageService.disableAudioVideoControls().catch((e) => {
                logger.info('Disabling the audio/video controls failed', e);
            });
        } else {
            stageService.unpublishStream(true);
        }
    };

    methods.current.leaveStageChannel = async (props = {}) => {
        const { disableAutoJoin = false } = props;
        setDisableAutoJoin(disableAutoJoin);
        stageService && (await stageService.leaveBroadCastRTC());
        methods.current.addLog('Leave Channel', {
            disableAutoJoin,
        });
        setJoinedChannel(null);
    };

    useEffect(() => {
        if (previousChannelId === channelId) {
            return;
        }
        methods.current.addLog(
            'base on previousChannelId updated: reset allowedToPublish',
            {
                previousChannelId,
                channelId,
                allowToPublish,
                joinedStageChannel,
            }
        );
        setAllowToPublish(false);

        // We need to reset the allowToPublish base on channelId update, So not add any other dependency here
    }, [channelId]);

    useEffect(() => {
        if (
            joinedStageChannel &&
            (channelId !== joinedStageChannel || liteModeActive)
        ) {
            methods.current.addLog('Leave Channel on mismatch channel id', {
                channelId,
                joinedStageChannel,
            });
            stageService.logDataToSDK({
                msg:
                    'leaveChannelSource - switched to another session or mismatch of channel id',
            });
            methods.current.leaveStageChannel().then(() => {
                if ([LIVE_AIRMEET_STATE.backStage].includes(currentState)) {
                    methods.current.joinStageChannel(true);
                }
            });
        }
    }, [channelId, joinedStageChannel, liteModeActive]);

    useEffect(() => {
        if (joinedStageChannel) {
            const onConnectionStateChange = (curState) => {
                if (typeof curState === 'object') {
                    curState = curState?.currState;
                }

                const isConnected = curState === 'CONNECTED';
                methods.current.addLog('onConnectionStateChange', {
                    isConnected,
                    curState,
                });
                setReConnectingState(!isConnected);
            };

            stageService.on('connection-state-change', onConnectionStateChange);
            return () => {
                if (stageService) {
                    stageService.off(
                        'connection-state-change',
                        onConnectionStateChange
                    );
                }
            };
        } else {
            setReConnectingState(false);
        }
    }, [joinedStageChannel]);
    useEffect(() => {
        if (disableAutoJoinState) {
            const timeout = setTimeout(() => {
                methods.current.addLog(
                    'Set the disableAutoJoinState to false after timeout'
                );
                setDisableAutoJoin(false);
            }, RESET_DISABLE_AUTO_JOIN_STATE_DURATION);
            return () => {
                timeout && clearTimeout(timeout);
            };
        }
    }, [disableAutoJoinState, setDisableAutoJoin]);
    const leaveChannelTimerEnable =
        joinedStageChannel && hasInReConnectingState;
    useEffect(() => {
        if (leaveChannelTimerEnable) {
            const timeout = setTimeout(() => {
                methods.current.addLog('onInReConnectingState: TIMEOUT');
                const msg = 'Leaving channel - due to the 20 seconds timeout';
                logger.info(msg);
                stageService.logDataToSDK({
                    msg:
                        'leaveChannelSource - 20 seconds timeout after network disconnect',
                });
                methods.current.leaveStageChannel();
            }, 20 * 1000);
            return () => {
                timeout && clearTimeout(timeout);
            };
        }
    }, [leaveChannelTimerEnable, stageService]);

    useEffect(() => {
        if (joinedStageChannel && joinedStageChannel === channelId) {
            if (
                ![
                    LIVE_AIRMEET_STATE.backStage,
                    LIVE_AIRMEET_STATE.goLive,
                ].includes(currentState) ||
                liteModeActive
            ) {
                stageService.logDataToSDK({
                    msg: `leaveChannelSource - because user moved to ${currentState}`,
                });
                methods.current.leaveStageChannel();
            }
        } else {
            if (
                isOnline &&
                !disableAutoJoinState &&
                [
                    LIVE_AIRMEET_STATE.backStage,
                    LIVE_AIRMEET_STATE.goLive,
                ].includes(currentState) &&
                !liteModeActive &&
                !isBreakoutActive &&
                !joinedStageChannel
            ) {
                methods.current.joinStageChannel();
            } else if (
                [
                    LIVE_AIRMEET_STATE.backStage,
                    LIVE_AIRMEET_STATE.goLive,
                ].includes(currentState) &&
                !liteModeActive
            ) {
                methods.current.addLog('Not meet req for join', {
                    isOnline,
                    disableAutoJoinState,
                    joinedStageChannel,
                    isBreakoutActive,
                });
            }
        }
    }, [
        joinedStageChannel,
        currentState,
        disableAutoJoinState,
        liteModeActive,
        isBreakoutActive,
        isOnline,
        channelId,
    ]);

    useEffect(() => {
        methods.current.addLog('Updated allowToPublish', { allowToPublish });
        if (!joinedStageChannel) {
            methods.current.addLog('Channel not joined yet');
            return;
        }

        if (!previousJoinedChannelId && !allowToPublish) {
            methods.current.addLog(
                'Ignore the publisher handler as user just join channel, and allow to publish false'
            );
            return;
        }

        const timeout = setTimeout(() => {
            publishHandler.current();
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [allowToPublish, joinedStageChannel]);

    useEffect(() => {
        if (streamVideoProfile && stageService?.setResolution) {
            stageService.setResolution(streamVideoProfile);
        }
    }, [streamVideoProfile, stageService]);

    // updating the source module
    useEffect(() => {
        if (joinedStageChannel && stageService) {
            stageService.updateSourceModuleName(
                LIVE_AIRMEET_STATE.goLive === currentState
                    ? RTC_SOURCE_MODULES.LIVE_STAGE
                    : RTC_SOURCE_MODULES.BACK_STAGE
            );
        }
    }, [joinedStageChannel, currentState, stageService]);

    // setting up audio/video controls on joining stage
    useEffect(() => {
        if (!stageService) {
            return;
        }
        stageService.setDefaultAVState({
            defaultAudioMute: STAGE_DEFAULT_AV_STATES.isAudioMute,
            defaultVideoMute: STAGE_DEFAULT_AV_STATES.isVideoMute,
        });
    }, [stageService]);

    // this is needed to handle the cases where audio is published and allow to publish is false
    // ex - when host try to move user from stage to backstage and at the same time user unmute the stage mic,
    // so in very edge case user mic will be unmuted on stage while sitting on backstage
    useEffect(() => {
        if (!allowMinRoleHost) {
            return;
        }
        const localStreamAudioUnmute = () => {
            if (!allowToPublishRef.current) {
                methods.current.addLog(
                    'Audio is published even when allowed to publish is false, so needs recover'
                );
                publishHandler.current();
            }
        };
        stageService.on(
            Events.rtcBrodCastLocalStreamAudioUnmute,
            localStreamAudioUnmute
        );

        return () => {
            stageService.off(
                Events.rtcBrodCastLocalStreamAudioUnmute,
                localStreamAudioUnmute
            );
        };
    }, [stageService, allowMinRoleHost]);

    const joinStageChannel = useCallback(
        async (publishVideo, callback = () => {}) => {
            return methods.current.joinStageChannel(publishVideo, callback);
        },
        []
    );

    const leaveStageChannel = useCallback(async (props = {}) => {
        return methods.current.leaveStageChannel(props);
    }, []);

    const stageChannelId = channelId;

    return {
        stageJoining: JoiningProcess.current,
        joinedStageChannel: joinedStageChannel,
        joinStageChannel,
        channelName: channelId,
        joinChannelError,
        allowToPublish,
        setAllowToPublish,
        leaveStageChannel,
        setDisableAutoJoin,
        disableJoinInChannel,
        stageChannelId,
        channelJoined,
    };
}

import { API_SERVICE, callApi } from 'utils/apiService';
import { logger } from './logger';
import AuthService from 'utils/authService';
import { v4 as uuid } from 'uuid';
import PermissionUtils from './permission-utils';

const errorLog = logger.init('Screen recording error', 'red');
const infoLog = logger.init('Screen recording info', 'green');
const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const KEY_SESSION_RECORDING = 'SESSION_RECORDING';
export const KEY_SESSION_RECORDING_STATUS = {
    STARTED: 'STARTED',
    STOPPED: 'STOPPED',
};

export default class ScreenRecording {
    static TIME_OFFSET_FOR_START_RECORDING = 3 * 1000; // 3 seconds
    static uniquActionId = uuid();

    static startScreenRecording = (body = {}) => {
        const { airmeetId, sessionId } = body;
        return callApi({
            method: 'POST',
            endpoint: '/screenRecorder/start',
            body,
            headers,
            type: 'json',
            service: API_SERVICE.SCREEN_RECORDER,
        })
            .then((res) => {
                infoLog(
                    `response of: start screen recording ${JSON.stringify(res)}`
                );
                if (res?.status !== 'Success') {
                    errorLog(`Failed to start the screen recording ${res}`);
                } else {
                    this._addRecordingStatus({
                        status: KEY_SESSION_RECORDING_STATUS.STARTED,
                        airmeetId,
                        sessionId,
                        timestamp: this.TIME_OFFSET_FOR_START_RECORDING,
                    });
                }
            })
            .catch((err) =>
                errorLog(`Failed to start the screen recording ${err}`)
            );
    };

    static stopScreenRecording = (body = {}) => {
        const { airmeetId, sessionId } = body;
        this._addRecordingStatus({
            status: KEY_SESSION_RECORDING_STATUS.STOPPED,
            airmeetId,
            sessionId,
            timestamp: 0,
        });
        return callApi({
            method: 'POST',
            endpoint: '/screenRecorder/stop',
            body,
            type: 'json',
            headers,
            service: API_SERVICE.SCREEN_RECORDER,
        })
            .then((res) => {
                infoLog(
                    `response of: stop screen recording ${JSON.stringify(res)}`
                );
                if (res?.status !== 'Success') {
                    errorLog(`Failed to stop the screen recording ${res}`);
                }
            })
            .catch((err) =>
                errorLog(`Failed to stop the screen recording ${err}`)
            );
    };

    static _addRecordingStatus = ({
        status,
        airmeetId,
        sessionId,
        timestamp,
    }) => {
        const { id: userId, id_seq } = PermissionUtils.authUser;
        infoLog(
            `Recording status put called for airmeet:${airmeetId},session:${sessionId},status:${status},user:${userId},timestamp:${timestamp}`
        );
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AccessToken': AuthService.token,
        };
        const currentTimeStamp = new Date().getTime();
        return callApi({
            method: 'PUT',
            type: 'json',
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/session-recording-status`,
            body: {
                action_id: `${this.uniquActionId}:${currentTimeStamp}:${status}:${id_seq}`,
                action_type: status,
                metadata: {
                    type: KEY_SESSION_RECORDING,
                    diff: timestamp,
                    currentTime: currentTimeStamp,
                },
            },
            headers,
        })
            .then(() => {
                infoLog(
                    'Sucessfully put recording status for screen recording'
                );
            })
            .catch((err) =>
                errorLog(
                    `Failed put recording status for screen recording ${err}`
                )
            );
    };
}

import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { logger } from 'utils/logger';
const { UPDATE_INIVITATION_STATUS } = FEATURE_ACTIONS[
    FEATURE_NAMES.STAGE_INVITE
];
export default function statusUpdateInFirebase({
    status,
    userId,
    senderUserId,
    sessionId,
    dataWriter,
}) {
    const userInviteData = ['accepted', 'removed', 'rejected'].includes(status)
        ? null
        : {
              senderUserId,
              sessionId,
              status,
          };

    dataWriter(
        { data: userInviteData, status },
        {
            action: UPDATE_INIVITATION_STATUS,
            userId,
            sessionId,
        }
    );
}

export function findHandRaisePendingRequest(list, userId) {
    return list.find((l) => l.user.id === userId && l.status === 'pending');
}

export function findHandRaiseActiveRequest(list, userId) {
    return list.find((l) => l.user.id === userId && l.status === 'active');
}

export const DEEP_LINK_JOIN_SESSION_ID_STORAGE_KEY = 'deep_link_session_id';

export const getDeepLinkSessionIdFromStorage = () => {
    return sessionStorage.getItem(DEEP_LINK_JOIN_SESSION_ID_STORAGE_KEY);
};

export const setDeepLinkSessionIdInStorage = (history, canRemove = false) => {
    const query = new URLSearchParams(history.location.search);
    const sessionId = query.get('sessionId');
    if (!sessionId) {
        canRemove && clearDeepLinkSessionIdFromStorage();
        return false;
    }
    logger.info(`Session deep-link: set session id ${sessionId} in storage`);
    sessionStorage.setItem(DEEP_LINK_JOIN_SESSION_ID_STORAGE_KEY, sessionId);
    return true;
};

export const clearDeepLinkSessionIdFromStorage = () => {
    const sessionId = sessionStorage[DEEP_LINK_JOIN_SESSION_ID_STORAGE_KEY];
    if (!sessionId) {
        return;
    }
    logger.info(
        `Session deep-link: remove session id ${sessionId} from storage`
    );
    sessionStorage.removeItem(DEEP_LINK_JOIN_SESSION_ID_STORAGE_KEY);
};

import * as RdxDropdown from '@radix-ui/react-dropdown-menu';
import { default as SVGIcon } from 'foundations/icon';
import { getZIndex } from 'foundations/themeV2/zIndex';
import React from 'react';
import styled from 'styled-components';

interface OptionObj {
    value: any;
    label?: string;
    disabled?: boolean;
    component?: JSX.Element;
    options?: OptionObj[];
}

interface DropdownProps {
    options?: OptionObj[];
    isArrowVisible?: boolean;
    value?: any[];
    isSelectable?: boolean;
    onChange?: (value: any, checked: boolean) => any;
    defaultOpen?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    modal?: boolean;
    isInPortal?: boolean;
    container?: HTMLElement;
    containerId?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
    children,
    options = [],
    isArrowVisible = false,
    value = [],
    isSelectable = false,
    onChange = null,
    defaultOpen,
    open,
    onOpenChange,
    modal = true,
    container,
    containerId,
    isInPortal,
    ...rest
}) => {
    const portalElement = containerId
        ? document.getElementById(containerId)
        : container;

    return (
        <RdxDropdown.Root
            defaultOpen={defaultOpen}
            open={open}
            onOpenChange={onOpenChange}
            modal={modal}
        >
            <RdxDropdown.Trigger asChild>{children}</RdxDropdown.Trigger>
            {Array.isArray(options) && options.length > 0 ? (
                portalElement || isInPortal ? (
                    <RdxDropdown.Portal container={portalElement}>
                        <DropDownContent
                            options={options}
                            isSelectable={isSelectable}
                            value={value}
                            onChange={onChange}
                            isArrowVisible={isArrowVisible}
                            {...rest}
                        />
                    </RdxDropdown.Portal>
                ) : (
                    <DropDownContent
                        options={options}
                        isSelectable={isSelectable}
                        value={value}
                        onChange={onChange}
                        isArrowVisible={isArrowVisible}
                        {...rest}
                    />
                )
            ) : null}
        </RdxDropdown.Root>
    );
};

const DropDownContent = ({
    options,
    isSelectable,
    value,
    onChange,
    isArrowVisible,
    isRadio = false,
    ...rest
}) => {
    return (
        <DropdownMenuContent
            className='dropdownContent'
            sideOffset={5}
            {...rest}
        >
            {Array.isArray(options) && options.length > 0
                ? options.map((option) => {
                      const hasOptions =
                          Array.isArray(option?.options) &&
                          option?.options.length > 0;

                      if (hasOptions) {
                          return (
                              <RdxDropdown.Sub key={option?.value}>
                                  <DropdownMenuSubTrigger>
                                      {option?.label}
                                      <StyledCheck
                                          icon='chevron_right'
                                          height='20px'
                                          width='20px'
                                      />
                                  </DropdownMenuSubTrigger>
                                  <RdxDropdown.Portal>
                                      <DropdownMenuSubContent
                                          sideOffset={2}
                                          alignOffset={-5}
                                          {...rest}
                                      >
                                          {option?.options.map((opt) => (
                                              <MenuItem
                                                  option={opt}
                                                  isSelectable={isSelectable}
                                                  value={value}
                                                  onChange={onChange}
                                                  key={option?.value}
                                              />
                                          ))}
                                      </DropdownMenuSubContent>
                                  </RdxDropdown.Portal>
                              </RdxDropdown.Sub>
                          );
                      }

                      return (
                          <MenuItem
                              option={option}
                              isSelectable={isSelectable}
                              value={value}
                              onChange={onChange}
                              isRadio={isRadio}
                          />
                      );
                  })
                : null}
            {isArrowVisible ? <DropdownMenuArrow /> : null}
        </DropdownMenuContent>
    );
};

export default Dropdown;

const MenuItem = ({
    option,
    isSelectable,
    value,
    onChange,
    isRadio = false,
}) => {
    const isSelected =
        isSelectable &&
        !option?.component &&
        Array.isArray(value) &&
        value.length > 0 &&
        value.includes(option?.value);

    if (isRadio) {
        return (
            <DropdownMenuRadioItem
                key={option?.value}
                disabled={option?.disabled}
                onSelect={option?.onSelect}
                value={option?.value}
            >
                {option?.value === value ? (
                    <SVGIcon
                        icon='radio_button_filled'
                        height='14px'
                        width='14px'
                        fill='text.default.primary'
                    />
                ) : (
                    <SVGIcon
                        icon='radio_button'
                        height='12px'
                        width='12px'
                        fill='text.default.primary'
                        mx='1px'
                    />
                )}
                {option?.component || option?.label}
            </DropdownMenuRadioItem>
        );
    }

    return (
        <DropdownMenuCheckboxItem
            key={option?.value}
            disabled={option?.disabled}
            checked={isSelected}
            onCheckedChange={(val) => onChange && onChange(option?.value, val)}
            onClick={option?.onClick}
            noPadding={option?.noPadding}
        >
            {option?.component || option?.label}
            <DropdownMenuItemIndicator>
                <StyledCheck icon='checkmark' height='20px' width='20px' />
            </DropdownMenuItemIndicator>
        </DropdownMenuCheckboxItem>
    );
};

export const getCommonMenuStyles = ({ theme }) => `
    min-width: 168px;
    background-color: ${theme.colors.fill.default1};
    border: 1px solid ${theme.colors.border.default1};
    border-radius: ${theme.radii.x3};
    color: ${theme.colors.text.default.primary};
    padding-top: 12px;
    padding-bottom: 12px;
    box-shadow: ${theme.shadows.x1};

    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    &[data-side='top'] {
        animation-name: slideDownAndFade;
    }

    &[data-side='right'] {
        animation-name: slideLeftAndFade;
    }

    &[data-side='bottom'] {
        animation-name: slideUpAndFade;
    }

    &[data-side='left'] {
        animation-name: slideRightAndFade;
    }

    @keyframes slideUpAndFade {
        from {
            opacity: 0;
            transform: translateY(2px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideRightAndFade {
        from {
            opacity: 0;
            transform: translateX(-2px);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes slideDownAndFade {
        from {
            opacity: 0;
            transform: translateY(-2px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideLeftAndFade {
        from {
            opacity: 0;
            transform: translateX(2px);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
`;

const DropdownMenuContent = styled(RdxDropdown.Content)`
    ${({ theme }) => getCommonMenuStyles({ theme })}
    z-index: ${({ theme }) =>
        getZIndex({
            zIndexType: theme.zIndices.types.above,
            zIndexRange: theme.zIndices.ranges.xl,
        })};
`;

const DropdownMenuSubContent = styled(RdxDropdown.SubContent)`
    ${({ theme }) => getCommonMenuStyles({ theme })}
`;

export const getCommonItemStyles = ({ theme }) => `
    color: ${theme.colors.text.default.primary};
    cursor: pointer;
    font-family: ${theme.fonts.regular};
    font-size: ${theme.fontSizes.x2};
    line-height: ${theme.lineHeights.x2};
    padding: 14px 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    user-select: none;
    outline: none;

    &[data-disabled] {
        color: ${theme.colors.text.default.disabled};
    }

    &:hover,
    &[data-highlighted] {
        background-color: ${theme.colors.fill.other3};
    }
`;

const DropdownMenuCheckboxItem = styled(RdxDropdown.CheckboxItem)`
    ${({ theme }) => getCommonItemStyles({ theme })}
    ${({ noPadding }) =>
        noPadding &&
        `
        padding: 0;
    `}
`;

const DropdownMenuRadioItem = styled(RdxDropdown.RadioItem)`
    ${({ theme }) => getCommonItemStyles({ theme })}
    justify-content: flex-start;
    padding: ${({ theme }) => `${theme.space.x2} ${theme.space.x4}`};
`;

const DropdownMenuSubTrigger = styled(RdxDropdown.SubTrigger)`
    ${({ theme }) => getCommonItemStyles({ theme })}

    &[data-state='open'] {
        background-color: ${({ theme }) => theme.colors.fill.other3};
    }
`;

const DropdownMenuArrow = styled(RdxDropdown.Arrow)`
    fill: ${({ theme }) => theme.colors.fill.default1};
`;

const DropdownMenuItemIndicator = styled(RdxDropdown.ItemIndicator)``;

const StyledCheck = styled(SVGIcon)`
    fill: ${({ theme }) => theme.colors.text.default.secondary};
`;

import { FlexDiv } from 'components/molecules/utils/basicComponents';
import SVGIcon from 'foundations/icon';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import isNumber from 'lodash/isNumber';
import React, { useContext, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { AnalyticsContext } from '../AnalyticsProvider';
import ChartCard from '../components/ChartCard';
import { Chart } from '../styledComponents';
import { commonDoughnutOptions, getPercentage } from '../utils';

export default function TurnoutCard() {
    const { theme } = useTheme();
    const { attendanceLoading: loading, attendanceData: data } = useContext(
        AnalyticsContext
    );
    const { total_attendees, total_registrations, all } = data?.event || {};

    const totalAttendees = total_attendees || all?.attendees || 0;
    const totalRegistrations = total_registrations || all?.registrations || 0;

    const turnoutPercent = useMemo(
        () => getPercentage(totalAttendees, totalRegistrations),
        [totalRegistrations, totalAttendees]
    );

    return (
        <ChartCard title='Turnout' loading={loading}>
            <Chart mr={3}>
                <Doughnut
                    data={{
                        labels: ['Turnout'],
                        datasets: [
                            {
                                data: [
                                    turnoutPercent || 0,
                                    turnoutPercent > 100
                                        ? 0
                                        : 100 - (turnoutPercent || 0),
                                ],
                                backgroundColor: [
                                    '#4CC3FF',
                                    theme?.isLightTheme
                                        ? theme.colors.ambience[21]
                                        : '#26252D',
                                ],
                                borderWidth: 0,
                            },
                        ],
                    }}
                    options={{
                        ...(commonDoughnutOptions || {}),
                    }}
                />
            </Chart>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {isNumber(turnoutPercent) ? (
                    <Typography
                        variant='subtitle2'
                        color='ambience.0'
                        style={{
                            marginBottom: '16px',
                        }}
                    >
                        <SVGIcon
                            icon='person_available'
                            height='14'
                            width='14'
                            fill={
                                theme?.isLightTheme
                                    ? theme.colors.ambience[21]
                                    : '#4CC3FF'
                            }
                            mr='8px'
                        />
                        {turnoutPercent}% turnout
                    </Typography>
                ) : null}

                <FlexDiv alignItems='center' mb='16px'>
                    <SVGIcon
                        icon='people'
                        height='21'
                        width='21'
                        fill='ambience.16'
                        mr='13px'
                    />
                    <FlexDiv
                        flexWrap='wrap'
                        alignItems='center'
                        maxWidth='100px'
                    >
                        <Typography
                            variant={'subtitle2'}
                            color='ambience.4'
                            mr='10px'
                        >
                            {totalAttendees ?? '-'}
                        </Typography>
                        <Typography variant={'subtext2'} color='ambience.4'>
                            Attendees
                        </Typography>
                    </FlexDiv>
                </FlexDiv>

                <FlexDiv alignItems='center' mb='16px'>
                    <SVGIcon
                        icon='drafts'
                        height='21'
                        width='21'
                        fill='ambience.16'
                        mr='13px'
                    />
                    <FlexDiv
                        flexWrap='wrap'
                        alignItems='center'
                        maxWidth='100px'
                    >
                        <Typography
                            variant={'subtitle2'}
                            color='ambience.4'
                            style={{
                                marginRight: '10px',
                            }}
                        >
                            {totalRegistrations ?? '-'}
                        </Typography>
                        <Typography variant={'subtext2'} color='ambience.4'>
                            Registrations
                        </Typography>
                    </FlexDiv>
                </FlexDiv>
            </div>
        </ChartCard>
    );
}

import { useShallowEqualSelector } from 'hooks/common';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setInvitedSpeakersData } from 'store/actions/liveAirmeet';
import { getInvitedSpeakers } from 'store/selectors/liveAirmeet';
import { logger } from 'utils/logger';
import UserRoles from 'utils/userRoles';
import { isAttendee } from 'utils/users';
import { updateAirmeetData } from '../../store/actions/Lounge';

export const useFetchInviteToStage = (airmeetId, firebaseClient) => {
    const [isValueLoaded, setValueUpdated] = useState(false);

    const dispatch = useDispatch();
    const loadDefault = 'genral';

    const fireBaseKey =
        airmeetId && firebaseClient
            ? `${airmeetId}/liveAirmeet/${loadDefault}`
            : null;

    useEffect(() => {
        if (!fireBaseKey) {
            return;
        }
        const invitedSpeakersKey = `${fireBaseKey}/invitedSpeakers`;
        const valueUpdated = ({ type, key, value }) => {
            if (!isAttendee(UserRoles.getUserEventRole())) {
                logger.info(
                    `invitedSpeakers updated ${JSON.stringify({
                        updated: { value },
                    })}`
                );
            }
            dispatch(setInvitedSpeakersData({ ...value }));
            setValueUpdated(true);
        };
        firebaseClient.getDataSync(invitedSpeakersKey, valueUpdated);
        return () => {
            firebaseClient.clearDataSync(invitedSpeakersKey, valueUpdated);
        };
    }, [firebaseClient, fireBaseKey, dispatch]);

    return { isValueLoaded };
};

export default function useInviteToStage(airmeetId, firebaseClient) {
    const dispatch = useDispatch();
    const { isValueLoaded: isLoaded } = useFetchInviteToStage(
        airmeetId,
        firebaseClient
    );
    const invitedSpeakerListRef = useRef();
    const invitedSpeakerList = useShallowEqualSelector((store) => {
        const invitedSpeakersStore = getInvitedSpeakers(store);
        if (invitedSpeakersStore) {
            if (!invitedSpeakerListRef.current) {
                return invitedSpeakersStore;
            }
            return isEqual(invitedSpeakerListRef.current, invitedSpeakersStore)
                ? invitedSpeakerListRef.current
                : invitedSpeakersStore;
        }
        return null;
    });

    const airmeet = useShallowEqualSelector(({ lounge }) => {
        return lounge.airmeet || {};
    });

    invitedSpeakerListRef.current = invitedSpeakerList;

    useEffect(() => {
        if (
            airmeet.airmeetId &&
            airmeet.safeSessions &&
            !isEqual(invitedSpeakerListRef.current, airmeet.invitedSpeakerList)
        ) {
            if (airmeet.safeSessions) {
                airmeet.sessions = airmeet.safeSessions.map((session) => {
                    return { ...session };
                });
            }
            dispatch(
                updateAirmeetData(
                    airmeet.airmeetId,
                    invitedSpeakerListRef.current || null,
                    { ...airmeet }
                )
            );
        }
    }, [airmeet.safeSessions, invitedSpeakerListRef.current, dispatch]);
    return { isLoaded };
}

import { useEffect, useState } from 'react';
import {
    ATTENDEES_TAB,
    CHAT_TAB,
    DEVICE_CHOOSER_TAB,
    ENABLE_MULTIPLE_LOUNGE,
    MANAGE_TAB,
    POLLS_TAB,
    PRE_RECORDED_VIDEOS_TAB,
    QUESTIONS_TAB,
    RAISE_HAND_TAB,
    SESSIONS_TAB,
} from 'utils/constants/controls';

/**
 * airmeet level controls and features
 */
export const ALL_CONTROLS = {
    SESSIONS: SESSIONS_TAB,
    ATTENDEES: ATTENDEES_TAB,
    CHATS: CHAT_TAB,
    QUESTIONS: QUESTIONS_TAB,
    DEVICE_SWITCH: DEVICE_CHOOSER_TAB,
    POLLS: POLLS_TAB,
    RAISE_HAND: RAISE_HAND_TAB,
    MODERATION: MANAGE_TAB,
    MEDIA: PRE_RECORDED_VIDEOS_TAB,
    ENABLE_MULTIPLE_LOUNGE,
};

export const FLAG_NAMES = {
    TABLE_BUG_REPORT: 'tableBugReport',
    BACKGROUND_FILTERS: 'backgroundFilters',
    RHS_V2: 'rhsV2',
    GROUP_MEETING: 'groupMeeting',
    SOCIAL_LOUNGE_SCROLL: 'socialLoungeScroll',
    ADVANCED_PEOPLE_SEARCH: 'advancedPeopleSearch',
    CHAT_REACTION_FEATURE: 'chatReactionFeature',
    MULTIPLE_CHAT_REACTION_FEATURE: 'multipleChatReaction',
    CLOSED_CAPTIONS_ON_TABLE: 'closedCaptionsOnTable',
    ENABLE_GIF: 'enableGif',
    CLOSED_CAPTIONS_ON_BREAKOUT: 'closedCaptionsOnBreakout',
    TABLE_MODERATION_FEATURE: 'tableModerationFeature',
    RHS_ENAGEMENT_FOR_REPLAY: 'rhsEnagementForReplay',
};

export const ALL_FEATURES = {
    REACTIONS: 'reactions',
    LITE_MODE: 'liteMode',
    REACTION_COUNT: 'reactionCount',
    ATTENDEES_COUNT: 'attendeesCount',
    ADVANCED_POLLS_ENABLED: 'advancedPollsEnabled',
    PEOPLE_LIST_LIMITED: 'peopleListLimited',
    SHOW_STREAM_BASE_NETWORK_QUALITY: 'showStreamBaseNetworkQuality',
    SHOW_FEEDBACK_MODAL: 'showFeedbackModal',
    PUBLISH_LIVESTREAMING_USING_SCREEN_RECORDER:
        'publishLiveStreamingUsingScreenRecorder',
    NETWORKING_SESSIONS_ENABLED: 'networkingSessionsEnabled',
    SHOW_NETWORK_QUALITY: 'showNetworkQuality',
    SHOW_STAGE_STREAM_STATS: 'showStageStreamStats',
    ENABLED_FIREBASE_READ_STAGE_MEMBERS: 'enabledReadStageMembers',
    SHOW_TABLE_RHS: 'showTableRHS',
    ENABLE_GROUP_MEETING_V2: 'enableGroupMeetingV2',
    SHOW_NEW_TABLE_CARD: 'showNewTableCard',
    NETWORKING_SESSIONS_MEETUP_ENABLED: 'networkingSessionsMeetupEnabled',
    DISABLE_STAGE_VISIBILITY_CONTROL: 'disableStageVisibilityControl',
    STAGE_CHAT: 'stageChat',
    USER_CREATE_TABLES: 'isUGC',
    RHS_V2: FLAG_NAMES.RHS_V2,
    COLLAPSIBLE_RHS: 'collapsibleRHS',
    ENABLE_DISCONNECTED_TIMER: 'enableDisconnectTimer',
    SHOW_INVITE_TO_TABLE_CTA: 'showInviteToTableCTA',
    SESSION_BREAKOUT: 'sessionBreakout',
    ENABLE_LIVE_ANNOUNCEMENT: 'is_live_announcement_enabled',
    ENABLE_STAGE_LAYOUT_V2: 'is_stage_layout_v2_enabled',
    SOCIAL_LOUNGE_SCROLL: 'socialLoungeScroll',
    INTRO_VIDEO_HLS_ENABLED: 'introVideoHlsEnabled',
    BANNER_CTA: 'bannerCTA',
    RESOURCE_VIDEO_HLS_ENABLED: 'resourceVideoHlsEnabled',
    PLAY_ORIGINAL_VIDEO: 'playOriginalVideo',
    ENABLE_PLAY_VIDEO_ON_STAGE: 'playVideoOnStage',
    ENABLE_CUSTOM_PRE_RECORDED_VIDEO_URL: 'enableCustomPreRecordedUrl',
    JOIN_BREAKOUT_ROOM_WITH_PERMISSION: 'joinBreakoutRoomWithPermission',
    SPEAKER_ONBOARDING: 'speakerOnboarding',
    RECEPTION_VIDEO_HLS_ENABLED: 'receptionVideoHlsEnabled',
    ALLOCATE_USER_BASE_CHAIR: 'allocateUserBaseChair',
    BREAKOUT_ROOM_MODERATION: 'breakoutRoomModeration',
    BANNER_LOWER_THIRDS: 'bannerLowerThirds',
};

/**
 * list of platform features and controls
 */
export const ALL_PLATFORM_FEATURES = {
    PROXY_COMMUNICATION: 'proxyCommunication',
    PROXY_COMMUNICATION_WITH_PUBLISH: 'proxyCommunicationWithPublish',
    CLOUD_PROXY: 'cloudProxy',
    HOST_DELEGATION: 'hostDelegation',
    AIRMEET_PROXY: 'airmeetProxy',
    AIRMEET_TURN_SERVER: 'airmeetTURNServer',
    CLOSED_CAPTIONS: 'closedCaptions',
    ENABLE_CC_SUBSCRIPTION_VIA_FIREBASE: 'enableCCSubscriptionViaFirebase',
    LOUNGE_TABLES_V3: FLAG_NAMES.LOUNGE_TABLES_V3,
    SESSION_PLAYBACK_ENABLED: 'sessionPlaybackEnabled',
    ENABLED_FIREBASE_READ_STAGE_MEMBERS: 'enabledReadStageMembers',
    CLOSED_CAPTIONS_USING_RTC_SDK: 'closedCaptionsUsingRTCSDK',
    CLOSED_CAPTIONS_SERVICE_PROVIDER: 'closedCaptionsServiceProvider',
    ENABLE_CLOSED_CAPTIONS_TRANSLATION: 'enableClosedCaptionTranslation',
};

export const ALL_PLATFORM_CONFIG = {
    MAX_TABLE_CAPACITY: 'maxTableCapacity',
    MAX_FLUID_ZONE_CAPACITY: 'maxFluidZoneCapacity',
    MAX_AIR_GENIE_IMAGE_GEN_ATTEMPTS: 'maxAirGenieImageGenAttempts',
};

export const ALL_FEATURES_CONFIG = {
    IS_SESSION_PLAYBACK_DISABLED: 'isSessionPlaybackDisabled',
    CHAT_CONFIG: 'chatConfig',
    QUESTION_FEED_CONFIG: 'questionFeedConfig',
    REACTIONS_CONFIG: 'reactionsConfig',
    PEOPLE_TAB_CONFIG: 'peopleTabConfig',
    EVENT_PEOPLE_CONFIG: 'peopleTabConfig',
    SESSION_PEOPLE_CONFIG: 'sessionPeopleConfig',
    BOOTH_PEOPLE_CONFIG: 'boothPeopleConfig',
    FLUID_SPACE_PEOPLE_CONFIG: 'fluidSpacePeopleConfig',
    DATA_WRITER_CONFIG: 'dataWriterConfig',
    DATA_READER_CONFIG: 'dataReaderConfig',
    GEOFENCING: 'geofencing',
    BOOTH_CONFIG: 'boothConfig',
    PERMISSION_WORKFLOW_VERSION: 'permissionWorkflowVersion',
    ENABLE_RTC_NETWORK_INDICATOR: 'enableRTCNetworkIndicator',
    GROUP_MEETING_MAX_TILES_PER_PAGE: 'groupMeetingMaxTilesPerPage',
    MAX_STAGE_CAPACITY: 'maxStageCapacity',
    MAX_PENDING_RAISE_HAND_REQUEST: 'maxPendingRaiseHandRequestAllowed',
    PROXY_COMMUNICATION_USING_SDK: 'proxyCommunicationUsingSDK',
    MUSIC_MODE: 'musicMode',
    VOLUME_CONTROL: 'volumeControl',
    FIREWALL_CHECK_CONFIG_SDK: 'firewallCheckConfig',
    RTC_CONFIG: 'rtcConfig',
    CUSTOM_FPS_CONFIG: 'customFPSConfig',
    ENABLE_CC_FOR_GROUP_MEETINGS: 'enableCCForGroupMeetings',
};

const ADDITIONAL_FEATURES = [
    ALL_FEATURES.PEOPLE_LIST_LIMITED,
    ALL_FEATURES.PUBLISH_LIVESTREAMING_USING_SCREEN_RECORDER,
    ALL_FEATURES.SHOW_STAGE_STREAM_STATS,
    ALL_FEATURES.ENABLED_FIREBASE_READ_STAGE_MEMBERS,
    ALL_FEATURES.SESSION_BREAKOUT,
    ALL_FEATURES.ENABLE_LIVE_ANNOUNCEMENT,
    ALL_FEATURES.ENABLE_STAGE_LAYOUT_V2,
    ALL_FEATURES.SHOW_STREAM_BASE_NETWORK_QUALITY,
    ALL_FEATURES.ENABLE_GROUP_MEETING_V2,
    ALL_FEATURES.BANNER_CTA,
    ALL_FEATURES.PLAY_ORIGINAL_VIDEO,
    ALL_FEATURES.ENABLE_PLAY_VIDEO_ON_STAGE,
    ALL_FEATURES.ENABLE_CUSTOM_PRE_RECORDED_VIDEO_URL,
    ALL_FEATURES.BREAKOUT_ROOM_MODERATION,
];

const ADDITIONAL_PLATFORM_FEATURES = [];

const DEFAULT_CONTROLS = Object.values(ALL_CONTROLS);
const DEFAULT_FEATURES = Object.values(ALL_FEATURES).filter(
    (value) => !ADDITIONAL_FEATURES.includes(value)
);
const DEFAULT_PLATFORM_FEATURES = Object.values(ALL_PLATFORM_FEATURES).filter(
    (value) => !ADDITIONAL_PLATFORM_FEATURES.includes(value)
);

/**
 * @todo
 * convert the data structure from an array
 * to a JSON object for performance
 * @param {*} defaultValue
 * @param {*} config
 */
const filterForEnabled = (defaultValue, config) => {
    const { whitelist, blacklist } = config || {};
    const filtered = whitelist
        ? [...Object.keys(whitelist), ...defaultValue]
        : [...defaultValue];
    if (blacklist) {
        for (let item of Object.keys(blacklist)) {
            const enabledIndex = filtered.indexOf(item);
            if (enabledIndex >= 0) {
                filtered.splice(enabledIndex, 1);
            }
        }
    }

    return [...filtered];
};

const VALID_CALL_SITES = ['LIVE_CONTEXT', 'DASHBOARD', 'EDIT_LANDING_PAGE'];

function useFeatureControl({ airmeetId, firebaseClient, caller }) {
    if (!VALID_CALL_SITES.includes(caller)) {
        throw new Error(
            'useFeatureControl should not be used directly, use useLiveAirmeetContext instead'
        );
    }
    const [controls, setControls] = useState(null);
    const [features, setFeatures] = useState(null);
    const [platformFeatures, setPlatformFeatures] = useState(null);
    const [platformFeaturesConfig, setPlatformFeaturesConfig] = useState();

    const [featuresConfig, setFeaturesConfig] = useState(null);
    const [loadedC, setLoadedC] = useState(false);
    const [loadedF, setLoadedF] = useState(false);
    const [loadedPF, setLoadedPF] = useState(false);
    const [loadedPFC, setLoadedPFC] = useState(false);

    const [loadedFC, setLoadedFC] = useState(false);

    useEffect(() => {
        if (!airmeetId || !firebaseClient) {
            return;
        }

        const controlsPath = `${airmeetId}/featureControl/controls`;
        const featuresPath = `${airmeetId}/featureControl/features`;
        const platformFeaturesPath = 'platformFeatureControl/features';
        const platformFeaturesConfigPath =
            'platformFeatureControl/featuresConfig';
        const featuresConfigPath = `${airmeetId}/featureControl/featuresConfig`;
        const events = firebaseClient.getEmitter();

        const onControlledControls = ({ value }) => {
            setControls(filterForEnabled(DEFAULT_CONTROLS, value));
            setLoadedC(true);
        };

        const onControlledFeatures = ({ value }) => {
            setFeatures(filterForEnabled(DEFAULT_FEATURES, value));
            setLoadedF(true);
        };

        const onControlledPlatformFeatures = ({ value }) => {
            setPlatformFeatures(
                filterForEnabled(DEFAULT_PLATFORM_FEATURES, value)
            );
            setLoadedPF(true);
        };

        const onControlledFeaturesConfig = ({ value }) => {
            setFeaturesConfig(value);
            setLoadedFC(true);
        };

        const onControlledPlatformFeaturesConfig = ({ value }) => {
            setPlatformFeaturesConfig(value);
            setLoadedPFC(true);
        };

        events.on(`${controlsPath}_value`, onControlledControls);
        events.on(`${featuresPath}_value`, onControlledFeatures);
        events.on(
            `${platformFeaturesPath}_value`,
            onControlledPlatformFeatures
        );
        events.on(`${featuresConfigPath}_value`, onControlledFeaturesConfig);
        events.on(
            `${platformFeaturesConfigPath}_value`,
            onControlledPlatformFeaturesConfig
        );
        firebaseClient.getDataRef(controlsPath);
        firebaseClient.getDataRef(featuresPath);
        firebaseClient.getDataRef(platformFeaturesPath);
        firebaseClient.getDataRef(featuresConfigPath);
        firebaseClient.getDataRef(platformFeaturesConfigPath);
        return () => {
            if (!airmeetId || !firebaseClient) {
                return;
            }

            events.off(`${controlsPath}_value`, onControlledControls);
            events.off(`${featuresPath}_value`, onControlledFeatures);
            events.off(
                `${platformFeaturesPath}_value`,
                onControlledPlatformFeatures
            );
            events.off(
                `${featuresConfigPath}_value`,
                onControlledFeaturesConfig
            );
            events.off(
                `${platformFeaturesConfigPath}_value`,
                onControlledPlatformFeaturesConfig
            );

            firebaseClient.unload(controlsPath);
            firebaseClient.unload(featuresPath);
            firebaseClient.unload(platformFeaturesPath);
            firebaseClient.unload(featuresConfigPath);
            firebaseClient.unload(platformFeaturesConfigPath);
        };
    }, [airmeetId, firebaseClient]);

    return {
        loaded: loadedC && loadedF && loadedFC && loadedPFC,
        platformLoaded: loadedPF && loadedPFC,
        enabledControls: controls,
        enabledFeatures: features,
        enabledPlatformFeatures: platformFeatures,
        featuresConfig: featuresConfig,
        platformConfig: platformFeaturesConfig,
    };
}

export default useFeatureControl;

import EventBridge from 'event-bridge';
import ChannelBridge from 'event-bridge/ChannelBridge';
import { DefaultChannelEvents } from 'event-bridge/constants';
import { ChannelBridgeEvents, EventMap } from 'event-bridge/types';
import { spawn, Thread, Worker } from 'threads';
import {
    WORKER_NAME_INTERVALS,
    WORKER_NAME_LOGGER,
    WORKER_NAME_TRANSITIONS,
    WORKER_NAME_RECORDING_TRANSCRIPT,
} from 'utils/constants/workers';
import { logger } from 'utils/logger';

export const SPAWN_TIMEOUT = 50000;

// The build plugin which manages bundling the workers checks for the pattern
// `new Worker('relative-path-to-worker')`
// To prevent all (required, and unneccessary) workers from being downloaded at load,
// use a lazy factory pattern
/**
 * All workers available for the Airmeet App
 */
const WorkerFactories: {
    [key: string]: () => { worker: Worker; events?: ChannelBridgeEvents };
} = {
    [WORKER_NAME_LOGGER]: () => ({
        worker: new Worker('./logger/LogWorker.ts'),
        events: DefaultChannelEvents,
    }),
    [WORKER_NAME_TRANSITIONS]: () => ({
        worker: new Worker('./transition-monitor/TransitionMonitor.ts'),
        events: DefaultChannelEvents,
    }),
    [WORKER_NAME_INTERVALS]: () => ({
        worker: new Worker('./interval-tracker/IntervalTracker.ts'),
        events: DefaultChannelEvents,
    }),
    [WORKER_NAME_RECORDING_TRANSCRIPT]: () => ({
        worker: new Worker(
            './sdk-workers/polyglot-transcript/RecordingTranscriptWorker.ts'
        ),
        events: DefaultChannelEvents,
    }),

    // [WORKER_NAME_USER_CACHE]: () => ({
    //     worker: new Worker('./user-cache/CacheWorker.ts'),
    //     events: DefaultChannelEvents,
    // }),
};

export interface WorkerOptions {
    eager?: boolean;
    events?: EventMap;
}

class WorkerInstance<TEvents extends ChannelBridgeEvents> {
    name: string;
    subs: number = 0;
    worker: any = null;
    bridge: ChannelBridge<TEvents> = null;

    constructor(name: string, worker: any, events: TEvents, subs: number = 0) {
        this.name = name;
        this.subs = subs;
        this.worker = worker;

        // Setup an event bridge channel for the worker
        this.bridge = new ChannelBridge<TEvents>(this.name, events);

        // Attach the bridge channel subscription to the event stream on the worker
        this.bridge.mainChannel.notify.on(({ from, event, data }) => {
            if (from) {
                this.worker.channelEvent(event, ...data);
            }
        });
        // Attach the workers event stream (observable) to the channel method
        this.worker.eventStream().subscribe(({ emit, to, event, args }) => {
            if (emit) {
                this.bridge.mainChannel.notification({
                    to: to,
                    event: event,
                    data: args,
                });
            }
        });
    }
}

const initialized: {
    [workerName: string]: WorkerInstance<ChannelBridgeEvents>;
} = {};

export async function startWorker(
    workerName: string,
    options: WorkerOptions = {}
) {
    if (!(workerName in WorkerFactories)) {
        throw new Error(`Requested app worker: '${workerName}' not found!`);
    }

    let wrappedWorker = initialized[workerName];
    if (Boolean(wrappedWorker)) {
        return wrappedWorker.worker;
    }

    const workerConfig = WorkerFactories[workerName]();
    const instance = await spawn(workerConfig.worker, {
        timeout: SPAWN_TIMEOUT,
    });
    wrappedWorker = new WorkerInstance<typeof workerConfig.events>(
        workerName,
        instance,
        workerConfig.events
    );

    wrappedWorker.bridge.register(EventBridge);

    if (options.eager === true) {
        try {
            const ts = Date.now();
            const resp = await wrappedWorker['test']('Ola!');
            logger.log(`Worker response (${Date.now() - ts}ms) :`, resp);
        } catch (err) {
            logger.error(`Error in eager 'test' call for worker`, err);
        }
    }

    if (Boolean(options.events)) {
        EventBridge.onChannelEvents(workerName, options.events);
    }

    wrappedWorker.subs++;
    initialized[workerName] = wrappedWorker;
    return wrappedWorker.worker;
}

export async function stopWorker(workerName: string) {
    if (initialized[workerName]) {
        const { subs, worker, bridge } = initialized[workerName];
        if (subs <= 1) {
            Thread.terminate(worker);
            bridge.unregister();
            delete initialized[workerName];
        } else {
            initialized[workerName].subs--;
        }
    }
}

import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import useClosedCaptionsSettings from 'hooks/closed-captions/useClosedCaptionsSettings';
import useTheme from 'hooks/useTheme';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAirmeetState } from 'store/actions/cmDashboard/airmeet';
import { callApi } from 'utils/apiService';
import { noop } from 'utils/constants/common';
import { logger } from 'utils/logger';
import SettingsSwitch from '../Settings/SettingsSwitch';
import CcConfirmModal from './CcConfirmModal';
import CcLangsSelect from './CcLangsSelect';
import CcPrimaryLangSelect from './CcPrimaryLangSelect';
import {
    CC_LANGUAGE_OPTIONS_AWS,
    CC_LANGUAGE_OPTIONS_GCP,
    CC_PRIMARY_LANGUAGE_OPTIONS_AWS,
    CC_PRIMARY_LANGUAGE_OPTIONS_GCP,
} from './constants';

export const getClosedCaptionsSelectStyles = (theme) => {
    return {
        control: (baseStyles) => ({
            ...baseStyles,
            minHeight: '50px',
            border: '0px',
            backgroundColor: theme.colors.ambience[20],
            borderRadius: '8px',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            color: theme.colors.ambience[0],
            fontSize: '14px',
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: theme.colors.ambience[20],
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            color: theme.colors.ambience[0],
            backgroundColor:
                state?.isFocused || state?.isSelected
                    ? theme.colors.ambience[18]
                    : 'transparent',
        }),
        multiValue: (baseStyles) => ({
            ...baseStyles,
            color: theme.colors.ambience[24],
        }),
        input: (baseStyles) => ({
            ...baseStyles,
            color: theme.colors.ambience[0],
        }),
    };
};

export default function ClosedCaptions({ airmeet }) {
    const dispatch = useDispatch();
    const { theme } = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [modalSelectedOption, setModalSelectedOption] = useState(null);

    const {
        serviceProvider,
        isCCUsingSDKSelected,
        showCloseCaptionOption,
        allowTranslatedCaptionOption: allowTranslatedCaption,
        defaultPrimaryLanguage,
        isCCTranslationEnabled,
    } = useClosedCaptionsSettings();

    const CC_LANGUAGE_OPTIONS = useMemo(() => {
        return {
            supportedLangs:
                serviceProvider === 1
                    ? CC_LANGUAGE_OPTIONS_AWS
                    : CC_LANGUAGE_OPTIONS_GCP,
            primaryLangs:
                serviceProvider === 1
                    ? CC_PRIMARY_LANGUAGE_OPTIONS_AWS
                    : CC_PRIMARY_LANGUAGE_OPTIONS_GCP,
        };
    }, [serviceProvider]);

    const handleCcLangsSubmit = (value) => {
        updateLiveConfig({ key: 'ccLangs', value });
    };

    const updateLiveConfig = async ({ key, value }) => {
        const {
            airmeetId,
            community_id: communityId,
            live_config: liveConfig,
        } = airmeet || {};
        if (airmeetId && communityId && !isLoading) {
            setIsLoading(true);
            try {
                const newLiveConfig = {
                    ...(liveConfig || {}),
                    [key]: value,
                };
                // Disable allowTranslatedCaption if showCloseCaption is disabled
                if (key === 'showCloseCaption' && !value) {
                    newLiveConfig.allowTranslatedCaption = false;
                }

                // set default values for new keys
                if (newLiveConfig.ccLangs == undefined) {
                    newLiveConfig.ccLangs = [];
                }
                if (newLiveConfig.ccPrimaryLang == undefined) {
                    newLiveConfig.ccPrimaryLang =
                        CC_LANGUAGE_OPTIONS.primaryLangs[0];
                }
                if (newLiveConfig.showCloseCaption == undefined) {
                    newLiveConfig.showCloseCaption = true;
                }
                if (newLiveConfig.allowTranslatedCaption == undefined) {
                    newLiveConfig.allowTranslatedCaption = false;
                }

                if (
                    // newLiveConfig.allowTranslatedCaption &&
                    newLiveConfig?.ccLangs?.length > 0 &&
                    newLiveConfig?.ccPrimaryLang?.value?.length > 0
                ) {
                    // filter out the primary language from the supported languages
                    // let's say en-US is the primary language, then we should not show `en` in the supported languages dropdown
                    newLiveConfig.ccLangs = newLiveConfig.ccLangs?.filter(
                        ({ value }) => {
                            return !newLiveConfig.ccPrimaryLang?.value
                                ?.split?.('-')?.[0]
                                .includes?.(value);
                        }
                    );
                }

                await callApi({
                    endpoint: '/airmeet',
                    method: 'PUT',
                    body: {
                        airmeetId,
                        communityId,
                        live_config: { ...newLiveConfig },
                    },
                    type: 'json',
                });
                await dispatch(
                    updateAirmeetState({
                        ...airmeet,
                        live_config: { ...newLiveConfig },
                    })
                );
            } catch (e) {
                logger.error(e);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleClosedCaptionsChange = () => {
        updateLiveConfig({
            key: 'showCloseCaption',
            value: !showCloseCaptionOption,
        });
    };

    const handleAllowTranslatedCaptionsChange = () => {
        updateLiveConfig({
            key: 'allowTranslatedCaption',
            value: !allowTranslatedCaption,
        });
    };

    const handleCcPrimaryLangChange = (option) => {
        setIsConfirmModalVisible(true);
        setModalSelectedOption(option);
    };

    const handleCcPrimaryLangChangeConfirm = () => {
        setIsConfirmModalVisible(false);
        updateLiveConfig({
            key: 'ccPrimaryLang',
            value: modalSelectedOption,
        });
        setModalSelectedOption(null);
    };

    const selectStyles = getClosedCaptionsSelectStyles(theme);

    const handleModalClose = () =>
        !isLoading && setIsConfirmModalVisible(false);

    return (
        <FlexDiv flexDirection='column' alignItems='stretch' p='8px 16px'>
            <FlexDiv alignItems='center'>
                <FlexDiv flexDirection='column' alignItems='stretch' flex='1'>
                    <Typography
                        variant='subtitle2-bold'
                        color='ambience.0'
                        pb='5px'
                    >
                        Closed Captions
                    </Typography>
                    <Typography variant='subtext2' color='ambience.6'>
                        This will allow attendees to watch closed captions for
                        the sessions they are watching
                    </Typography>
                </FlexDiv>
                <SettingsSwitch
                    loading={isLoading}
                    onChange={handleClosedCaptionsChange}
                    checked={showCloseCaptionOption}
                />
            </FlexDiv>
            {showCloseCaptionOption && isCCUsingSDKSelected ? (
                <FlexDiv
                    flexDirection='column'
                    alignItems='stretch'
                    p='0 0 0 16px'
                >
                    <CcPrimaryLangSelect
                        options={CC_LANGUAGE_OPTIONS}
                        selectStyles={selectStyles}
                        isLoading={isLoading}
                        onChange={handleCcPrimaryLangChange}
                        ccPrimaryLang={defaultPrimaryLanguage}
                    />
                    {isCCTranslationEnabled ? (
                        <>
                            <FlexDiv alignItems='center' pt='16px'>
                                <FlexDiv
                                    flexDirection='column'
                                    alignItems='stretch'
                                    flex='1'
                                >
                                    <Typography
                                        variant='subtitle2-bold'
                                        color='ambience.0'
                                        pb='5px'
                                    >
                                        Allow Translated Captions
                                    </Typography>
                                    <Typography
                                        variant='subtext2'
                                        color='ambience.6'
                                    >
                                        This will alow attendees to choose from
                                        a list of language to show closed
                                        caption in
                                    </Typography>
                                </FlexDiv>
                                <SettingsSwitch
                                    loading={isLoading}
                                    onChange={
                                        handleAllowTranslatedCaptionsChange
                                    }
                                    checked={allowTranslatedCaption}
                                />
                            </FlexDiv>
                            {allowTranslatedCaption ? (
                                <CcLangsSelect
                                    liveConfig={airmeet?.live_config}
                                    selectStyles={selectStyles}
                                    isLoading={isLoading}
                                    onSubmit={handleCcLangsSubmit}
                                />
                            ) : null}
                        </>
                    ) : null}
                </FlexDiv>
            ) : null}
            {isConfirmModalVisible && (
                <CcConfirmModal
                    onClose={handleModalClose}
                    onConfirm={
                        modalSelectedOption
                            ? handleCcPrimaryLangChangeConfirm
                            : noop
                    }
                />
            )}
        </FlexDiv>
    );
}

import {
    CUT_OFF_DATE_LIGHT_THEME_DASHBOARD,
    CUT_OFF_DATE_NEW_LANDING_PAGE,
} from 'components/community/editPublicAirmeet/constants';
import DOMPurify from 'dompurify';
import { FREE_ATTENDEE, FREE_PLAN } from 'hooks/metered-pricing/config';
import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { parse } from 'querystring';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import { isSafari } from 'utils/browserCheck';
import { logger } from 'utils/logger';
import { validationErrors } from './constants/common';
import { ERROR_CODES } from './constants/errorResponseCodes';
import { getCroppedAttendeeImageURL as _getCroppedAttendeeImageURL } from './constants/users';

export function cdnImage(src) {
    if (src.indexOf('/') === 0) {
        src = src.substring(1);
    }
    return `${process.env.REACT_APP_IMAGE_PUBLIC_CDN_URL}${src}`;
}

export function getRandomBetween(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function cdnFile(src) {
    if (src.indexOf('/') === 0) {
        src = src.substring(1);
    }
    return `${process.env.REACT_APP_IMAGE_PUBLIC_CDN_URL}${src}`;
}

export function cdnMinifiedImage(src) {
    if (src.indexOf('/') === 0) {
        src = src.substring(1);
    }
    return `${process.env.REACT_APP_IMAGE_MINIFIED_PUBLIC_CDN_URL}${src}`;
}

export function getOptimizedImagePath(path) {
    try {
        if (path.indexOf('//') > 0) {
            return path;
        }
        return cdnImage(path);
    } catch (e) {
        return null;
    }
}

export function s3toCDNImage(src) {
    if (!src || typeof src !== 'string') return '';

    // Skip CDN compressor for GIF
    return src.endsWith('.gif')
        ? src
        : src.replace(
              process.env.REACT_APP_IMAGE_S3_URL,
              process.env.REACT_APP_IMAGE_MINIFIED_PUBLIC_CDN_URL
          );
}

/**
 * Utility function to pluralize any string based on count
 * @param {Number} count
 * @param {String} noun
 * @param {String} suffix
 */
export const pluralize = (count, noun, suffix = 's') =>
    `${noun}${count !== 1 ? suffix : ''}`;

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

// https://stackoverflow.com/a/1144249/8578337
// compares if two objects are same or not without going into prototyping
// returns true if two objects are similar otherwise false
export function deepCompareObjects(x, y) {
    var i, l, leftChain, rightChain;

    function compare2Objects(x, y) {
        var p;

        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (
            isNaN(x) &&
            isNaN(y) &&
            typeof x === 'number' &&
            typeof y === 'number'
        ) {
            return true;
        }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if (
            (typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)
        ) {
            return x.toString() === y.toString();
        }

        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for (p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for (p in x) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch (typeof x[p]) {
                case 'object':
                case 'function':
                    leftChain.push(x);
                    rightChain.push(y);

                    if (!compare2Objects(x[p], y[p])) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if (x[p] !== y[p]) {
                        return false;
                    }
                    break;
            }
        }

        return true;
    }

    if (arguments.length < 1) {
        return true; //Die silently? Don't know how to handle such case, please help...
        // throw "Need two or more arguments to compare";
    }

    for (i = 1, l = arguments.length; i < l; i++) {
        leftChain = []; //Todo: this can be cached
        rightChain = [];

        if (!compare2Objects(arguments[0], arguments[i])) {
            return false;
        }
    }

    return true;
}

/**
 * A client side validator for email strings
 * @see https://stackoverflow.com/a/46181
 * @param {string} email string to be validated as an email
 */
export const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const getWindowSize = () => {
    return {
        windowWidth:
            window && window.innerWidth
                ? window.innerWidth
                : document.body.clientWidth,
        windowHeight:
            window && window.innerHeight
                ? window.innerHeight
                : document.body.clientHeight,
    };
};

const createDiv = () => {
    if (!document) {
        return;
    }
    const div = document.createElement('div');
    div.setAttribute(
        'style',
        `font: 12px / 16px NotoSans-SemiBold; visibility: hidden; word-break: break-word;`
    );
    const body = document.querySelector('body');

    body.appendChild(div);

    return div;
};

let div = createDiv();

export const getTextHeight = ({
    containerWidth = 0,
    str = '',
    font = '12px / 16px NotoSans-SemiBold',
}) => {
    if (!div) {
        div = createDiv();
    }

    div.setAttribute(
        'style',
        `width: ${containerWidth}px; font: ${font}; visibility: hidden; word-break: break-word; position: absolute; top: 0; pointer-events: none; white-space: pre-line;`
    );
    div.innerHTML = DOMPurify.sanitize(`<span>${str}</span>`);

    return Math.ceil(div.getBoundingClientRect().height);
};

export const getCroppedAttendeeImageURL = _getCroppedAttendeeImageURL;

export const setWindowFlag = (flagName) => {
    if (window) {
        window.AirmeetFlags = window.AirmeetFlags || {};
        window.AirmeetFlags[flagName] = true;
    }
};

/**
 * Function to return the url params in JS Object structure from the route
 */
export const getUrlParams = (props = {}) => {
    const { onlyString, customQs = '' } = props;
    const qs = customQs || window.location.search || '?';
    const queryParamString = qs.substring(1);
    if (onlyString) {
        return queryParamString;
    }
    let params = {};
    try {
        params = parse(queryParamString);
    } catch (e) {
        logger.error(e);
    } finally {
        return params;
    }
};

/**
 * This function checks if given popup window is closed or not (handles adblocker issues)
 * @param {window} popupWindow popupwindow on which open/close check is done
 */
export const isPopupWindowBlocked = (popupWindow) => {
    return (
        !popupWindow ||
        popupWindow.closed ||
        typeof popupWindow.closed == 'undefined'
    );
};

export const getLocalTimezoneAbbr = (date) => {
    return getTimezoneAbbr(date);
};

export const getTimezoneAbbr = (date, timezone) => {
    const zoneName = timezone || moment.tz.guess();
    let zoneAbbr = moment(date).tz(zoneName).zoneAbbr();
    const NAME_ALPHA_REGEX = /^[A-Z]+$/;
    // Setting offset if not found an abbr for a zone
    if (!NAME_ALPHA_REGEX.test(zoneAbbr)) {
        const offsetValue = moment.tz(zoneName).utcOffset();
        const offsetString = moment.tz(zoneName).format('Z');
        zoneAbbr = `UTC${offsetValue !== 0 ? offsetString : ''}`;
    }
    return zoneAbbr;
};

export const isNumber = (num) => {
    return typeof num === 'number' && !Number.isNaN(num);
};

/**
 * A function that returns a promise that resolves in either true or false
 */
export const urlValidator = async (url) => {
    let module = await import('yup');
    return module.string().trim().url().isValid(url);
};

/**
 * A function that checking is iframe embed code is correct or not
 */
export const embedCodeValidator = (code) => {
    const re = /<(“[^”]*”|'[^’]*’|[^'”>])*>/;
    return re.test(String(code).toLowerCase());
};

/**
 * @function getConcatenatedString
 * @param {Array} StringArray Array of Strings to concatenate
 * @param {String} separator Separator to insert between array items
 * @param {String} lastElementSeparator Separator to add before the last item
 * @param {String} endWith Character to end the concatenated string with
 * @returns {String} contatendated string
 */
export const getConcatenatedString = (
    StringArray = [],
    separator = ', ',
    lastElementSeparator = 'and ',
    endWith = '.'
) => {
    return StringArray.join(`${separator} `).replace(
        /, ([^,]*)$/,
        ` ${lastElementSeparator} $1${endWith}`
    );
};

/**
 * @function canShowPremiumFeatures
 * @param {String} subscriptionType Airmeet subscription type
 * @param {Boolean} isTrial Boolean to tell iof Airmeet is in trail mode or not
 * @returns {Boolean} Boolean to tell if premium features can be shown or not
 */
export const canShowPremiumFeatures = (subscriptionType, isTrial) => {
    return (subscriptionType &&
        ![FREE_PLAN, FREE_ATTENDEE].includes(subscriptionType)) ||
        isTrial
        ? true
        : false;
};

/**
 * Variable to store if the current env is a test env or not.
 */
export const isTestEnv = !['production', 'preprod'].includes(
    process.env.REACT_APP_ENV
);

export const getEmailDomain = (email) => {
    if (!email || !email.includes('@')) return null;
    return email.split('@')[1].toLowerCase();
};

export const validateMaliciousString = ({
    regex = /[<>]+|(&gt|&lt)/i,
    value = '',
    returnError = true,
    key = '',
    localisedError = '',
    showShortError = false,
}) => {
    const isMalicious = typeof value === 'string' && regex.test(value);
    if (returnError) {
        return {
            isMalicious,
            errorData: isMalicious
                ? {
                      [key]: {
                          status: true,
                          msg: localisedError
                              ? localisedError
                              : showShortError
                              ? validationErrors.SMALL_FIELD_ERROR
                              : validationErrors.FIELD_ERROR,
                      },
                  }
                : null,
        };
    }
    return isMalicious;
};

export const isMaliciousStringPresent = (code, errors) => {
    // this is a common function for malicious string BE response valdation
    if (!code || !errors) return;
    if (
        code === ERROR_CODES.MALICIOUS_STRING ||
        (code === ERROR_CODES.SCHEMA_VALIDATION_FAILED &&
            Array.isArray(errors) &&
            errors.length > 0 &&
            errors[0]?.code === ERROR_CODES?.PATTERN)
    ) {
        return true;
    }
    return false;
};

export const getIsDefaultLandingPageBanner = (imageUrl) => {
    return !imageUrl || imageUrl.includes('airmeet-default');
};

export const getEventLandingBannerImgSrc = (
    imageUrl,
    isSeriesLandingPage = false
) => {
    return getIsDefaultLandingPageBanner(imageUrl)
        ? cdnImage(
              isSeriesLandingPage
                  ? '/images/airmeet-default.jpg'
                  : '/images/default-landing-banner-min.png'
          )
        : imageUrl;
};

export const isIntegerBetween = (value, range1, range2) => {
    if (!value || (typeof value !== 'number' && isNaN(value))) return;
    if (value >= range1 && value <= range2) {
        return true;
    }
    return false;
};

export const roundNumber = (num, decimal) => {
    return Number(num.toFixed(decimal));
};

export const ScrollToElement = (
    element,
    elementType = 'ref',
    options = {
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
    }
) => {
    if (elementType === 'class') {
        const getElement = document.getElementsByClassName(element);
        if (getElement.length > 0) {
            getElement[0].scrollIntoView(options);
        }
    } else if (elementType === 'id') {
        const getElement = document.getElementById(element);
        if (!!getElement) {
            getElement.scrollIntoView(options);
        }
    } else {
        element.scrollIntoView(options);
    }
};

/**
 * Utility function to get formatted time (default format: "HH:MM AM/PM")
 * @param {Date String} time
 */
export const getFormattedTime = ({ time, timeLocale, timeOptions }) => {
    if (!time) {
        return null;
    }

    const locale = timeLocale ? timeLocale : [];
    const option = timeOptions
        ? timeOptions
        : {
              hour: '2-digit',
              minute: '2-digit',
              hourCycle: 'h12',
          };

    return new Date(time).toLocaleTimeString(locale, option).toUpperCase();
};

/*
 *
 * Promised based scrollIntoView( { behavior: 'smooth' } )
 * source: https://stackoverflow.com/a/57867348/4555568
 *
 * @param { Element } elem
 **  ::An Element on which we'll call scrollIntoView
 * @param { object } [options]
 **  ::An optional scrollIntoViewOptions dictionary
 * @return { Promise } (void)
 **  ::Resolves when the scrolling ends
 *
 */
export function smoothScroll(elem, options = {}) {
    return new Promise((resolve) => {
        if (!(elem instanceof Element)) {
            return resolve();
        }
        let same = 0; // a counter
        let lastPos = null; // last known Y position
        // pass the user defined options along with our default
        const scrollOptions = Object.assign({ behavior: 'smooth' }, options);

        // let's begin
        elem.scrollIntoView(scrollOptions);
        requestAnimationFrame(check);

        // this function will be called every painting frame
        // for the duration of the smooth scroll operation
        function check() {
            // check our current position
            const newPos = elem.getBoundingClientRect().top;

            if (newPos === lastPos) {
                // same as previous
                if (same++ > 2) {
                    return resolve(); // we've come to an halt
                }
            } else {
                same = 0; // reset our counter
                lastPos = newPos; // remember our current position
            }
            // check again next painting frame
            requestAnimationFrame(check);
        }
    });
}

export const getIsNewLandingPage = (created_at, live_config) => {
    return (
        !!live_config?.landingPageBranding?.layout ||
        (created_at &&
            moment(created_at).isAfter(moment(CUT_OFF_DATE_NEW_LANDING_PAGE)))
    );
};

export const getIsLightThemeDashboard = (created_at, isLightTheme) => {
    return isBoolean(isLightTheme)
        ? isLightTheme
        : created_at
        ? moment(created_at).isAfter(moment(CUT_OFF_DATE_LIGHT_THEME_DASHBOARD))
        : true;
};

const getThemeAgnosticAmbience = (colorIdx, theme) => {
    const {
        isLightTheme,
        colors: { ambience },
    } = theme;
    if (!isLightTheme) {
        return colorIdx;
    }
    return ambience.length - 1 - colorIdx;
};

export const getAmbienceColorId = ({ colorId, isAgnostic, theme }) => {
    return isAgnostic ? getThemeAgnosticAmbience(colorId, theme) : colorId;
};

const DEFAULT_REPLACEMENT_TEXTS = {
    Airmeet: 'Event',
    Airmeets: 'Events',
    airmeet: 'event',
    airmeets: 'events',
};
export const whiteLabelString = (
    str,
    replacementTexts = DEFAULT_REPLACEMENT_TEXTS
) => {
    if (!str || str === '') return str;
    const RE = new RegExp(
        `(^|\\s)(${Object.keys(replacementTexts).join('|')})(?=$|\\s)`,
        'g'
    );
    return str.replace(RE, (m, p1, p2) => `${p1}${replacementTexts[p2]}`);
};

export const htmlToText = (html) =>
    isString(html)
        ? html
              .replace(/\n/gi, '')
              .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/gi, '')
              .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/gi, '')
              .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/gi, '')
              .replace(/<img[^>]*>[\s\S]*?<\/img[^>]*>/gi, '')
              .replace(/<\/\s*(?:p|div)>/gi, '\n')
              .replace(/<br[^>]*\/?>/gi, '\n')
              .replace(/<[^>]*>/gi, '')
              .replace('&nbsp;', '')
              .replace('&#160;', '')
              .replace(/[\n]+/gi, ' ')
              .replace(/[\s][\s]+/gi, '\n')
              .replace(/^[\n]/gi, '')
              .replace(/[^\S\r\n\t][^\S\r\n\t]+/gi, '')
        : '';

export const getIsConnectViaProxy = () =>
    getAirmeetUtilInstance() ? getAirmeetUtilInstance().connectViaProxy : false;

export const captureVideoThumbnail = (url) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.addEventListener('loadedmetadata', function () {
            video.currentTime = 2;
        });
        const capture = function () {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            if (!canvas.width && !canvas.height) {
                logger.info('Poster image capture failed', {
                    canvasWidth: canvas.width,
                    canvasHeight: canvas.height,
                });
                return reject('canvas width or height is zero');
            }
            canvas
                .getContext('2d')
                .drawImage(video, 0, 0, canvas.width, canvas.height);
            const image = canvas.toDataURL();
            const success = image.length !== 'data:,';
            if (success) {
                return resolve(image);
            }
            return reject('could not generated image');
        };
        video.addEventListener(isSafari() ? 'loadeddata' : 'seeked', capture);
        video.addEventListener('error', reject);
        video.preload = 'metadata';
        video.src = url;
        video.crossOrigin = 'anonymous';
        // Load video in Safari / IE11
        video.muted = true;
        video.playsInline = true;
        const playPromise = video.play();
        if (playPromise !== undefined) {
            playPromise.catch((error) => {
                return reject(error);
            });
        }
    });
};

// To get the date from milliseconds in any specific format.
export const getDateFromMilliSec = ({
    timezone,
    startTime,
    endTime,
    format = 'D MMM YYYY',
}) => {
    const startDate = momentTz(startTime).tz(timezone).format(format);
    const endDate = momentTz(endTime).tz(timezone).format(format);

    return { startDate, endDate };
};

export function formatBytes(bytes, decimals = 0) {
    if (bytes === 0) return '0 Bytes';
    if (isNaN(bytes)) return '';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

import { RESOLUTIONS_1080P } from 'containers/StageResolutionsList';
import { getIsPersistentBackstageEnabled } from 'context/LiveAirmeet';
import useActiveBreakout from 'hooks/breakout/useActiveBreakout';
import {
    steps,
    useSessionLiveState,
    useSessionLiveStatusData,
} from 'hooks/live-airmeet/useSessionLiveAction';
import { ALL_FEATURES } from 'hooks/useFeatureControl';
import useForbiddenActions from 'hooks/useForbiddenActions';
import useLiveAirmeetBackdrop from 'hooks/useLiveAirmeetBackdrop';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';

import useStageUsersRoles from 'hooks/useStageUsersRoles';
import get from 'lodash/get';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LIVE_AIRMEET_STATE } from 'utils/constants/live-airmeet';
import {
    PERSISTENT_STAGE_LOCATIONS,
    SESSION_TYPES,
} from 'utils/constants/sessions';
import { USER_ROLE } from 'utils/constants/users';
import { logger } from 'utils/logger';
import PermissionUtils from 'utils/permission-utils';

const Session = React.createContext(null);

const GROUP_MEETING_V2_START_DATE = '2022-12-23';
export function SessionProvider(props = {}) {
    const {
        showCDNStreams,
        onLeaveSession,
        onSessionFinishedFromBackStage,
        setLowNetwork,
        children,
        setAllowToPublish,
        allowToPublish,
        isParallelSession,
        liteModeActive,
        sessionReplayId,
        streamingResolution,
    } = props;
    const {
        featureDataClients: { stage: firebaseClient },
        airmeet: { airmeetId, data },
        features,
        user: { isOnline: isUserOnline, isConnected: isConnectedToFirebase },
    } = useLiveAirmeetContext();
    const isPersistentBackstageFeatureEnabled = useLiveAirmeetContext(
        getIsPersistentBackstageEnabled
    );

    const breakoutState = useActiveBreakout();
    const [isLocalStreamActive, setLocalStreamActive] = useState(false);

    const isBackStageInStore = useSelector((store) => store.lounge.isBackStage);
    const { currentAirmeet, currentSession, currentState, userRole } = data;
    const stageId = currentSession ? currentSession.sessionid : airmeetId;
    const { userStageRole } = useStageUsersRoles({
        sessionId: currentSession?.sessionid,
    });
    const isGroupMeetingV2EnabledByDate = currentSession
        ? new Date(GROUP_MEETING_V2_START_DATE) <=
          new Date(currentSession.start_time)
        : false;

    const isGroupMeetingLayoutV2Enabled =
        useMemo(
            () =>
                (features || []).includes(ALL_FEATURES.ENABLE_GROUP_MEETING_V2),
            [features]
        ) || isGroupMeetingV2EnabledByDate;
    const backdrop = useLiveAirmeetBackdrop({
        firebaseClient,
        currentSession,
        airmeetId: airmeetId,
    });

    const currentSessionId = currentSession?.sessionid;
    const liveStatusData = useSessionLiveStatusData({
        sessionId: currentSession?.sessionid,
        airmeetId: airmeetId,
    });
    const step = useSessionLiveState({
        sessionId: currentSession?.sessionid,
        airmeetId: airmeetId,
    });

    const { checkAccessDenied } = useForbiddenActions();

    const accessDenied = useMemo(() => checkAccessDenied(currentSessionId), [
        currentSessionId,
        checkAccessDenied,
    ]);
    const stageStoredVolume = sessionStorage.getItem(
        `stage-volume-${PERSISTENT_STAGE_LOCATIONS.LIVESTAGE}`
    );
    const backstageStoredVolume = sessionStorage.getItem(
        `stage-volume-${PERSISTENT_STAGE_LOCATIONS.BACKSTAGE}`
    );
    const stageDefaultAudioVolume =
        stageStoredVolume !== null ? parseInt(stageStoredVolume) : 10;
    const backstageDefaultAudioVolume =
        backstageStoredVolume !== null ? parseInt(backstageStoredVolume) : 10;
    const [stageAudioVolume, setStageAudioState] = useState(
        stageDefaultAudioVolume
    );
    const setStageAudioVolume = useCallback((volume) => {
        logger.info('stage audio control changed', { volume });
        setStageAudioState(volume);
    }, []);
    const [backstageAudioVolume, setBackstageAudioState] = useState(
        backstageDefaultAudioVolume
    );
    const setBackstageAudioVolume = useCallback((volume) => {
        logger.info('Backstage audio control changed', { volume });
        setBackstageAudioState(volume);
    }, []);
    const isBackStage = currentState === LIVE_AIRMEET_STATE.backStage;

    const showSessionStart =
        currentSession &&
        [steps.ready, steps.countDown, steps.connecting].includes(step);

    const isLive = currentState === LIVE_AIRMEET_STATE.goLive;

    const hasSessionStarted = [steps.start].includes(step);
    const hasLiveStageReady = useMemo(() => {
        return isLive && [steps.ready, steps.start].includes(step);
    }, [isLive, step]);

    const isOrganizerInPersistentStage =
        isPersistentBackstageFeatureEnabled && userRole === USER_ROLE.ORGANIZER;
    const shouldLoadStage =
        (userStageRole === USER_ROLE.ATTENDEE &&
            !isOrganizerInPersistentStage) ||
        PermissionUtils.isSessionCloudHost()
            ? isBackStage || hasLiveStageReady
            : true;
    const is1080PSession = streamingResolution === RESOLUTIONS_1080P;
    let stageType = '';
    if (currentSession?.type) {
        stageType = currentSession?.type;
    }

    return (
        <Session.Provider
            value={{
                ...props,
                accessDenied,
                stageId: stageId,
                stageRoomNode: `${airmeetId}/meta-data/sessions/${stageId}`,
                liveStageRoomNode: `${airmeetId}/liveAirmeet/sessions/${stageId}`,
                userRole,
                liveStatusData,
                isUserOnline: isUserOnline,
                isConnectedToFirebase: isConnectedToFirebase,
                airmeet: currentAirmeet,
                isScreenRecorderEnabled:
                    currentAirmeet.is_screen_recorder_enabled,
                isCloudHostEnabled: currentAirmeet.is_cloud_host_enabled,
                isLive,
                shouldLoadStage,
                hasLiveStageReady,
                hasSessionStarted,
                is1080PSession,
                isBackStage,
                hasRequestForBackstage:
                    isBackStageInStore &&
                    ![
                        LIVE_AIRMEET_STATE.backStage,
                        LIVE_AIRMEET_STATE.goLive,
                    ].includes(currentState),
                currentState,
                currentSession,
                setLowNetwork,
                leaveSession: onLeaveSession,
                onSessionFinishedFromBackStage,
                showCDNStreams,
                showSessionStart,
                step,
                setAllowToPublish,
                allowToPublish,
                isGroupMeetingLayoutV2Enabled,
                isParallelSession,
                liteModeActive,
                userStageRole,
                sessionReplayId,
                breakoutState,
                backdrop,
                isGroupMeetingSession:
                    currentSession?.type === SESSION_TYPES.GROUP_MEETING,
                isHostingSession:
                    currentSession?.type === SESSION_TYPES.HOSTING,
                isPersistentBackstageEnabled:
                    isPersistentBackstageFeatureEnabled &&
                    currentSession?.type === SESSION_TYPES.HOSTING,
                isSpeedNetworking:
                    currentSession?.type === SESSION_TYPES.SPEED_NETWORKING,
                isStreamingSession:
                    currentSession?.type === SESSION_TYPES.STREAMING,
                isFluidSpace:
                    currentSession?.type === SESSION_TYPES.FLUID_LOUNGE,
                stageAudioVolume,
                setStageAudioVolume,
                backstageAudioVolume,
                setBackstageAudioVolume,
                isLocalStreamActive,
                setLocalStreamActive,
                stageType,
            }}
        >
            {children}
        </Session.Provider>
    );
}

export default Session;

/**
 * Accepts a selector function which can be used to select a value at any
 * level of the LiveStreams context, like the `useSelector` hook from redux
 *
 * @param {(context) => {}} callback
 */
export function useLiveSessionContext(callback = (context) => context) {
    // The live session context
    const ctx = useContext(Session);

    // Return the up-to-date selected value
    return callback(ctx);
}

export const getStageId = (ctx) => get(ctx, ['stageId']);
export const getStageRoomNode = (ctx) => get(ctx, ['stageRoomNode']);
export const getIsGroupMeetingLayoutV2Enabled = (ctx) =>
    get(ctx, ['isGroupMeetingLayoutV2Enabled']);
export const getBackdrop = (ctx) => get(ctx, ['backdrop']);
export const getShouldLoadStage = (ctx) => get(ctx, ['shouldLoadStage']);
export const getStreamingResolution = (ctx) =>
    get(ctx, ['streamingResolution']);

import { IS_CUSTOM_TENANT } from 'utils/tenant';

export const EVENT_TYPES = {
    DEFAULT: 'DEFAULT',
    MEETUP: 'MEETUP',
    CONFERENCE: 'CONFERENCE',
    VIRTUAL_SERIES: 'VIRTUAL_SERIES',
};

export const EVENT_SUB_TYPES = {
    HYBRID_CONFERENCE: 'HYBRID_CONFERENCE',
    IN_PERSON_CONFERENCE: 'IN_PERSON_CONFERENCE',
    LITE: 'LITE',
};

const AIRMEET_DUPLICATION_STATUS = {
    PENDING: 'PENDING',
    FAILED: 'FAILED',
    PARTIAL_SUCCESS: 'PARTIAL_SUCCESS',
};

export const AIRMEET_STATUS = {
    ONGOING: 'ONGOING',
    CREATED: 'CREATED',
    PAUSED: 'PAUSED',
    FINISHED: 'FINISHED',
    PRE_EVENT_ACCESS: 'PRE_EVENT_ACCESS',
    ...AIRMEET_DUPLICATION_STATUS,
};
export const CODE = 'code';
export const EXHIBITOR_CODE = 'exhibitorToken';
export const MAGIC_LINK_DETAILS = 'magicLinkDetails';
export const SERIES_MAGIC_LINK = 'seriesMagicLink';
export const OTP_INFO = 'otpInfo';
export const LIVE_DEMO_EVENT_ID = process.env.REACT_APP_LIVE_DEMO_EVENT;

export const EVENT_TEMPLATE_TYPES = {
    WEBINAR: 'WEBINAR',
    BRAND_CONFERENCE: 'BRAND_CONFERENCE',
    TOWNHALL: 'TOWNHALL',
    TRADESHOW: 'TRADESHOW',
    PRODUCT_WORKSHOP: 'PRODUCT_WORKSHOP',
    SALES_TRAINING: 'SALES_TRAINING',
    CUSTOMER_CARE_LOUNGE: 'CUSTOMER_CARE_LOUNGE',
    VIRTUAL_OFFICE_SPACE: 'VIRTUAL_OFFICE_SPACE',
    GROUP_PRODUCT_DEMO: 'GROUP_PRODUCT_DEMO',
    PRODUCT_LAUNCH: 'PRODUCT_LAUNCH',
    VIP_PROSPECT_EXPERIENCE: 'VIP_PROSPECT_EXPERIENCE',
    FEATURE_DEEP_DIVE: 'FEATURE_DEEP_DIVE',
    COMMUNITY_MEETUP: 'COMMUNITY_MEETUP',
    SALES_KICKOFF: 'SALES_KICKOFF',
    VIRTUAL_SALES_OFFICE: 'VIRTUAL_SALES_OFFICE',
    CUSTOMER_ONBOARDING: 'CUSTOMER_ONBOARDING',
    REGIONAL_CUSTOMER_MEETUP: 'REGIONAL_CUSTOMER_MEETUP',
    INDUSTRY_ROUNDTABLE: 'INDUSTRY_ROUNDTABLE',
    CUSTOMER_SPOTLIGHT: 'CUSTOMER_SPOTLIGHT',
    TEAM_BONDING: 'TEAM_BONDING',
    RECRUITMENT: 'RECRUITMENT',
    ONBOARDING: 'ONBOARDING',
    HACKATHON: 'HACKATHON',
};

export const EVENT_TEMPLATE_TITLE = {
    SIMPLE_WEBINAR_MEETUP: 'Webinar',
    PROFESSIONAL_MIXER_MEETUP: 'Professional Mixer',
    COCKTAIL_PARTY_MEETUP: 'Cocktail Party',
    TOWNHALL_MEETUP: 'Townhall',
};

export const HEADER_HEIGHT = 56;
export const STATUSBAR_HEIGHT = 64;
export const DEFAULT_EVENT_DURATION = 30; // in minutes

/**
 * @function getEnvUrl
 * @param {String} url environment url variable
 * @returns {String} Returns url with current domain
 * Keeping it here to avoid cyclic imports issue
 */
export const getEnvUrl = (url) => {
    if (!url || url === '') return url;

    const hostname = window?.location?.hostname || 'airmeet.com';
    if (!hostname.includes('airmeet.com') && !hostname.includes('localhost')) {
        return url.replace('airmeet.com', hostname);
    }

    return url;
};

// Env URLs
export const REACT_APP_SERVER_BASE_URL = getEnvUrl(
    process.env.REACT_APP_SERVER_BASE_URL
);
export const REACT_APP_API_BASE_URL = getEnvUrl(
    process.env.REACT_APP_API_BASE_URL
);
export const REACT_APP_CONTENT_HUB_BASE_URL = getEnvUrl(
    process.env.REACT_APP_CONTENT_HUB_BASE_URL
);
export const REACT_APP_BFF_BASE_URL = getEnvUrl(
    process.env.REACT_APP_BFF_BASE_URL
);
export const REACT_APP_PLATFORM_BASE_URL = getEnvUrl(
    process.env.REACT_APP_PLATFORM_BASE_URL
);
export const REACT_APP_ANALYTICS_BASE_URL = getEnvUrl(
    process.env.REACT_APP_ANALYTICS_BASE_URL
);
export const REACT_APP_OPS_BASE_URL = getEnvUrl(
    process.env.REACT_APP_OPS_BASE_URL
);
export const REACT_APP_INTERCEPTOR_ENDPOINT = getEnvUrl(
    process.env.REACT_APP_INTERCEPTOR_ENDPOINT
);
export const REACT_APP_AIRMEET_PROXY_SERVER_URL = getEnvUrl(
    process.env.REACT_APP_AIRMEET_PROXY_SERVER_URL
);
export const REACT_APP_NOTIFICATION_BASE_URL = getEnvUrl(
    process.env.REACT_APP_NOTIFICATION_BASE_URL
);
export const REACT_APP_INTERCEPTOR_BASE_URL = getEnvUrl(
    process.env.REACT_APP_INTERCEPTOR_BASE_URL
);
export const REACT_APP_CAS_SERVER_BASE_URL = getEnvUrl(
    process.env.REACT_APP_CAS_SERVER_BASE_URL ||
        process.env.REACT_APP_SERVER_BASE_URL
);
export const REACT_APP_CAS_API_BASE_URL = getEnvUrl(
    process.env.REACT_APP_CAS_API_BASE_URL || process.env.REACT_APP_API_BASE_URL
);
export const REACT_APP_GLOBAL_BASE_URL = getEnvUrl(
    IS_CUSTOM_TENANT
        ? process.env.REACT_APP_BASE_URL
        : process.env.REACT_APP_GLOBAL_BASE_URL ||
              process.env.REACT_APP_BASE_URL
);
export const REACT_APP_EU_BASE_URL = getEnvUrl(
    process.env.REACT_APP_EU_BASE_URL
);
export const REACT_APP_US_BASE_URL = getEnvUrl(
    process.env.REACT_APP_US_BASE_URL
);
export const REACT_APP_PLATFORM_BASE_URL_IN = getEnvUrl(
    process.env.REACT_APP_PLATFORM_BASE_URL_IN
);
export const REACT_APP_PLATFORM_BASE_URL_EU = getEnvUrl(
    process.env.REACT_APP_PLATFORM_BASE_URL_EU
);
export const REACT_APP_PLATFORM_BASE_URL_US = getEnvUrl(
    process.env.REACT_APP_PLATFORM_BASE_URL_US
);
export const REACT_APP_API_BASE_URL_IN = getEnvUrl(
    process.env.REACT_APP_API_BASE_URL_IN
);
export const REACT_APP_API_BASE_URL_EU = getEnvUrl(
    process.env.REACT_APP_API_BASE_URL_EU
);
export const REACT_APP_API_BASE_URL_US = getEnvUrl(
    process.env.REACT_APP_API_BASE_URL_US
);
export const REACT_APP_CAS_BFF_BASE_URL = getEnvUrl(
    process.env.REACT_APP_CAS_BFF_BASE_URL || process.env.REACT_APP_BFF_BASE_URL
);

export const REACT_APP_PERFLOW_BASE_URL = getEnvUrl(
    process.env.REACT_APP_PERFLOW_BASE_URL
);

export const REACT_APP_DATA_WRITER_API_URL = getEnvUrl(
    process.env.REACT_APP_DATA_WRITER_API_URL
);

export const REACT_APP_NEW_RTS_URL = getEnvUrl(
    process.env.REACT_APP_NEW_RTS_URL
);

export const REACT_APP_AIRGENIE_BASE_URL = getEnvUrl(
    process.env.REACT_APP_AIRGENIE_BASE_URL
);

export const REACT_APP_POLYGLOT_BASE_URL = getEnvUrl(
    process.env.REACT_APP_POLYGLOT_BASE_URL
);

export const REACT_APP_SCREEN_RECORDING_API_BASE_URL = getEnvUrl(
    process.env.REACT_APP_SCREEN_RECORDING_API_BASE_URL
);

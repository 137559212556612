import { DEFAULT_VENDOR } from 'hooks/rtcVendor/constants';
import {
    RTC_SOURCE_MODULES,
    VENDOR_DAILY,
    emptyObject,
} from 'utils/constants/common';
import { STAGE_STREAM_MODES } from '../../utils/constants/stageStreamModes';
import createReducer from '../../utils/createReducer';
import { logger } from '../../utils/logger';
import {
    BREAKOUT_ROOM_JOIN_STATUS,
    CHANGE_INVITE_CARD_VISIBILITY,
    CHANGE_RESOLUTION_MENU_STATUS,
    DELETE_STAGE_JOIN_REQUEST_DATA,
    INVITE_TO_STAGE_PERMISSION_CHECK,
    IS_PLAY_VIDEO_ON_STAGE,
    LIVE_AIRMEET_SET_DATA,
    LIVE_AIRMEET_UPDATE_REACTIONS_LIST,
    SET_ACTIVE_WATCH_MODE,
    SET_AIRMEET_STATUS_BAR_ACTIVE,
    SET_AIRMEET_VISIT_ID,
    SET_BANNER_FORM_DATA,
    SET_BANNER_PREVIEW,
    SET_BREAKOUT_ACTIVE,
    SET_CUSTOM_FPS_CONFIG,
    SET_FILTER_INFO,
    SET_INVITED_SPEAKERS_DATA,
    SET_IN_AIRMEET_PERMISSION_SETUP_COMPLETE,
    SET_LAST_DM_NOTIFICATION_TIME,
    SET_LITE_MODE_ENABLED,
    SET_LOCAL_STREAM_IN_MIRROR,
    SET_MUSIC_MODE,
    SET_PERMISSION_SETUP_COMPLETE,
    SET_PERMISSION_WORKFLOW_VERSION,
    SET_SOCIAL_STREAMING,
    SET_STAGE_ID,
    SET_STAGE_INVITATION_DATA,
    SET_STAGE_JOIN_REQUEST_DATA,
    SET_STAGE_MEMBER_LIMIT_REACHED,
    SET_STREAM_LIMIT_MODAL_VISIBILITY,
    SET_TABLE_CODE,
    SET_VIDEO_HEADLINES_LIST,
    SET_VOLUME_CONTROL,
    UPDATE_CHECK_DM_NOTIFICATION_THRESHOLD,
    UPDATE_RTC_VENDOR_CONFIG,
    UPDATE_STAGE_ONLINE_MEMBERS,
    UPDATE_USER_PRESENCE_MEMBERS,
    sessionBaseProps,
} from '../actions/liveAirmeet';
const liteVersionLog = logger.init('Lite version', 'blue');

const initialState = {
    currentState: '',
    liteModeActive: false,
    isAirmeetStatusBarActive: false,
    isStreamLimitModalVisible: false,
    hasStageMemberLimitReached: false,
    genral: {
        layout: {},
    },
    invitations: {},
    stageJoinRequests: {},
    invitedSpeakers: {},
    inviteCardVisible: false,
    sessions: {},
    stageMembers: {},
    membersInContext: {},
    tableCode: null,
    activeWatchMode: STAGE_STREAM_MODES.highResolution,
    mirrorVideo: true,
    checkDMNotificationThreshold: false,
    lastDMNotificationTime: 0,
    socialStreaming: false,
    filterInfo: {
        type: 'none',
    },
    permissionWorkflowVersion: 3,
    isBreakoutActive: false,
    inAirmeetPermissionSetupComplete: false,
    isPermissionSetupComplete:
        localStorage.getItem('is_permission_setup_complete') || false,
    bannerFormData: {},
    bannerData: {},
    rtcConfig: {
        vendors: {
            [RTC_SOURCE_MODULES.BACK_STAGE]: DEFAULT_VENDOR,
            [RTC_SOURCE_MODULES.BOOTH_BROADCAST]: DEFAULT_VENDOR,
            [RTC_SOURCE_MODULES.BOOTH_LOUNGE]: DEFAULT_VENDOR,
            [RTC_SOURCE_MODULES.BREAKOUT_ROOM]: DEFAULT_VENDOR,
            [RTC_SOURCE_MODULES.FLUID_LOUNGE]: DEFAULT_VENDOR,
            [RTC_SOURCE_MODULES.LIVE_ANNOUNCEMENT]: DEFAULT_VENDOR,
            [RTC_SOURCE_MODULES.LIVE_STAGE]: DEFAULT_VENDOR,
            [RTC_SOURCE_MODULES.SCHEDULED_MEETING]: DEFAULT_VENDOR,
            [RTC_SOURCE_MODULES.SOCIAL_LOUNGE]: DEFAULT_VENDOR,
            [RTC_SOURCE_MODULES.SPEED_NETWORK]: DEFAULT_VENDOR,
            [RTC_SOURCE_MODULES.GROUP_MEETING]: VENDOR_DAILY,
        },
    },
};

const handlers = {
    [SET_PERMISSION_SETUP_COMPLETE]: (state = initialState, { payload }) => {
        if (payload) {
            localStorage.setItem('is_permission_setup_complete', payload);
        } else {
            localStorage.removeItem('is_permission_setup_complete');
        }
        return state.isPermissionSetupComplete === payload
            ? state
            : { ...state, isPermissionSetupComplete: payload };
    },
    [SET_IN_AIRMEET_PERMISSION_SETUP_COMPLETE]: (
        state = initialState,
        { payload }
    ) => {
        if (payload) {
            localStorage.setItem(
                'inairmeet_permission_setup_complete',
                payload
            );
        } else {
            localStorage.removeItem('inairmeet_permission_setup_complete');
        }
        return state.inAirmeetPermissionSetupComplete === payload
            ? state
            : { ...state, inAirmeetPermissionSetupComplete: payload };
    },
    [LIVE_AIRMEET_SET_DATA]: (state = initialState, { payload }) => {
        if (
            JSON.stringify(state) === JSON.stringify({ ...state, ...payload })
        ) {
            return state;
        }
        if (payload.sessions) {
            const sessions = {};
            Object.keys(payload.sessions).forEach((sessionId) => {
                sessions[sessionId] = {
                    ...sessionBaseProps,
                    ...payload.sessions[sessionId],
                };
            });
            payload.sessions = sessions;
        }
        // console.log(JSON.stringify(state));
        // console.log(JSON.stringify({ ...state, ...payload }));
        return { ...state, ...payload };
    },
    [LIVE_AIRMEET_UPDATE_REACTIONS_LIST]: (
        state = initialState,
        { payload }
    ) => {
        let { sessions } = state;
        const { sessionId, reactions } = payload;
        if (!sessions[sessionId]) {
            sessions[sessionId] = sessionBaseProps;
        }
        sessions[sessionId].reactions = reactions;
        return { ...state, sessions: { ...sessions } };
    },
    // [LIVE_AIRMEET_UPDATE_STREAMS_LAYOUT]: (
    //     state = initialState,
    //     { payload }
    // ) => {
    //     state.genral.layout = payload;
    //     return { ...state };
    // },
    [UPDATE_STAGE_ONLINE_MEMBERS]: (state = initialState, { payload }) => {
        if (!state.stageMembers[payload.stageId]) {
            state.stageMembers[payload.stageId] = {};
        }
        state.stageMembers[payload.stageId] = { ...payload.members };
        return state;
    },
    [UPDATE_USER_PRESENCE_MEMBERS]: (state = initialState, { payload }) => {
        if (!state.membersInContext[payload.presenceIn]) {
            state.membersInContext[payload.presenceIn] = {};
        }
        state.membersInContext[payload.presenceIn] = { ...payload.members };
        return state;
    },
    [SET_LITE_MODE_ENABLED]: (state, { payload }) => {
        liteVersionLog('liteMode visibility state updated to', payload);
        return { ...state, liteModeActive: payload };
    },
    [SET_ACTIVE_WATCH_MODE]: (state, { payload = 'default' }) => {
        return {
            ...state,
            activeWatchMode: payload,
            activeModeUpdatedAt: Date.now(),
        };
    },
    [SET_STAGE_ID]: (state, { payload = null }) => {
        return { ...state, stageId: payload };
    },
    [SET_INVITED_SPEAKERS_DATA]: (state, { payload = emptyObject }) => {
        return { ...state, invitedSpeakers: { ...payload } };
    },
    [CHANGE_RESOLUTION_MENU_STATUS]: (state, { payload }) => {
        return { ...state, isResolutionMenuOpen: payload };
    },
    [SET_STAGE_INVITATION_DATA]: (state = initialState, { payload }) => {
        state.invitations = payload;
        return { ...state };
    },
    [CHANGE_INVITE_CARD_VISIBILITY]: (state = initialState, { payload }) => {
        state.inviteCardVisible = payload;
        return { ...state };
    },
    [SET_STREAM_LIMIT_MODAL_VISIBILITY]: (
        state = initialState,
        { payload }
    ) => {
        state.isStreamLimitModalVisible = payload;
        return { ...state };
    },
    [SET_STAGE_MEMBER_LIMIT_REACHED]: (state = initialState, { payload }) => {
        if (state.hasStageMemberLimitReached === payload) {
            return state;
        }
        state.hasStageMemberLimitReached = payload;
        return { ...state };
    },
    [SET_TABLE_CODE]: (state = initialState, { payload }) => {
        return { ...state, tableCode: payload };
    },
    [SET_AIRMEET_VISIT_ID]: (state, { payload }) => {
        return { ...state, airmeetVisitId: payload };
    },
    [SET_LOCAL_STREAM_IN_MIRROR]: (state) => {
        return {
            ...state,
            mirrorVideo: !state.mirrorVideo,
        };
    },
    [UPDATE_CHECK_DM_NOTIFICATION_THRESHOLD]: (
        state = initialState,
        { payload }
    ) => {
        return {
            ...state,
            checkDMNotificationThreshold: payload,
        };
    },
    [SET_LAST_DM_NOTIFICATION_TIME]: (state = initialState, { payload }) => {
        return {
            ...state,
            lastDMNotificationTime: payload,
        };
    },
    [SET_SOCIAL_STREAMING]: (state, { payload }) => {
        return { ...state, socialStreaming: payload };
    },
    [SET_FILTER_INFO]: (state, { payload }) => ({
        ...state,
        filterInfo: payload,
    }),
    [SET_PERMISSION_WORKFLOW_VERSION]: (state = initialState, { payload }) => ({
        ...state,
        permissionWorkflowVersion: payload,
    }),
    [SET_BREAKOUT_ACTIVE]: (state, { payload }) => {
        return { ...state, isBreakoutActive: payload };
    },
    [BREAKOUT_ROOM_JOIN_STATUS]: (state = initialState, { payload }) => ({
        ...state,
        isJoinedBreakoutRoom: payload,
    }),
    [SET_STAGE_JOIN_REQUEST_DATA]: (state = initialState, { payload }) => {
        return {
            ...state,
            stageJoinRequests: {
                ...state.stageJoinRequests,
                ...payload,
            },
        };
    },
    [DELETE_STAGE_JOIN_REQUEST_DATA]: (state = initialState, { payload }) => {
        const { userId } = payload;
        const { stageJoinRequests: currentStageJoinRequests } = state;
        if (userId && currentStageJoinRequests[userId]) {
            delete currentStageJoinRequests[userId];
            return {
                ...state,
                stageJoinRequests: { ...currentStageJoinRequests },
            };
        }
        return state;
    },
    [SET_BANNER_FORM_DATA]: (state = initialState, { payload }) => {
        const { entityId } = payload;
        if (!entityId) return state;
        return {
            ...state,
            bannerFormData: { ...state.bannerFormData, [entityId]: payload },
        };
    },
    [SET_BANNER_PREVIEW]: (state = initialState, { payload }) => {
        const { entityId, contentType, contentId, isPreviewMode } = payload;
        if (!entityId || !contentType || !contentId) return state;
        const newPayload = {
            ...state.bannerData?.[entityId],
            [contentType]: {
                [contentId]: payload,
            },
        };
        if (!isPreviewMode) {
            delete newPayload[contentType];
        }
        return {
            ...state,
            bannerData: {
                ...state.bannerData,
                [entityId]: newPayload,
            },
        };
    },
    [SET_MUSIC_MODE]: (state, { payload }) => ({
        ...state,
        isMusicModeEnabled: payload,
    }),
    [SET_VOLUME_CONTROL]: (state, { payload }) => ({
        ...state,
        isVolumeControlEnabled: payload,
    }),
    [IS_PLAY_VIDEO_ON_STAGE]: (state = initialState, { payload }) => {
        const { isVideoPlayOnStage } = payload;
        state.isVideoPlayOnStage = isVideoPlayOnStage;
        return state;
    },
    [INVITE_TO_STAGE_PERMISSION_CHECK]: (state, { payload }) => {
        return { ...state, isInviteToStagePermissionInProgress: payload };
    },
    [UPDATE_RTC_VENDOR_CONFIG]: (state, { payload }) => {
        return {
            ...state,
            rtcConfig: {
                ...state.rtcConfig,
                vendors: {
                    ...state.rtcConfig.vendors,
                    ...payload,
                },
            },
        };
    },
    [SET_VIDEO_HEADLINES_LIST]: (state, { payload = {} }) => {
        return { ...state, videoHeadlines: payload };
    },
    [SET_CUSTOM_FPS_CONFIG]: (state, { payload }) => {
        const obj = { ...state, customFPSConfig: payload };
        return obj;
    },
};

handlers[SET_AIRMEET_STATUS_BAR_ACTIVE] = (state = initialState, action) => {
    return {
        ...state,
        isAirmeetStatusBarActive: action.payload,
    };
};

export default createReducer(initialState, handlers);

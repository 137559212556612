import {
    accessCodeInUrl,
    authenticateAccessCode,
    getQueryParamFromUrl,
    setAccessCodeInSession,
} from 'hooks/live-airmeet/useAccessCodeUserToken';
import React, { useMemo } from 'react';
import { Route } from 'react-router-dom';
import { setDeepLinkSessionIdInStorage } from 'utils/liveEvent';
import history from '../../history';
import useRouteBase from './common';

const PublicRoute = ({ component: Component, ...rest }) => {
    useRouteBase({ ...rest });
    const isEventRoute = rest.exact && '/e/:id' === rest.path;
    const isEventSeriesRoute =
        rest.exact &&
        (rest.path.includes('/es/:eventSeriesId') ||
            rest.path.includes('/es/:eventSeriesId/mobile-series'));
    useMemo(() => {
        if (!isEventRoute && !isEventSeriesRoute) {
            return false;
        }
        const replaceUrl = rest?.location?.pathname;
        let needToReplaceURL = setDeepLinkSessionIdInStorage(history);
        let queryName = getQueryParamFromUrl(history);
        if (queryName) {
            needToReplaceURL = setAccessCodeInSession({
                queryParam: queryName,
                tokenVal: accessCodeInUrl(queryName, history),
                airmeetId: isEventSeriesRoute
                    ? rest.computedMatch.params?.eventSeriesId
                    : rest.computedMatch.params?.id,
                replaceUrl: '', // move the reload later as we also need to remove sessionId from query params
                isEventSeries: isEventSeriesRoute,
            });
        } else if (!needToReplaceURL) {
            authenticateAccessCode(
                isEventSeriesRoute
                    ? rest.computedMatch.params?.eventSeriesId
                    : rest.computedMatch.params?.id,
                isEventSeriesRoute
            );
        }

        if (needToReplaceURL && replaceUrl) {
            window.location.href = replaceUrl;
        }
    }, [
        isEventRoute,
        isEventSeriesRoute,
        rest.computedMatch.params.id,
        rest.computedMatch.params.eventSeriesId,
        rest.location.pathname,
    ]);

    return (
        <Route
            {...rest}
            render={(props) => {
                return <Component {...props} />;
            }}
        />
    );
};

export default PublicRoute;

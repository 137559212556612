import Button from 'atoms/Button/button';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import RelativeToolTip from 'components/tooltip/relativeToolTip';
import { Typography } from 'foundations/typography';
import history from 'history.js';
import useEventDashboardFeatures from 'hooks/dashboard/useEventDashboardFeatures';
import isObject from 'lodash/isObject';
import orderBy from 'lodash/orderBy';
import React, { useMemo, useState } from 'react';
import { TRACKING_URL_TAB } from '../../constants/tabs';
import BarChartCard from '../components/BarChartCard';

const TYPES = ['Overall', 'Source', 'Medium', 'Campaign'];

const getTotal = (data) => {
    const keys = Object.keys(data || {});
    if (keys.length > 0)
        return Object.keys(data || {})
            .map((key) => data[key])
            .reduce((a, b) => a + b);

    return 0;
};

export default function TrafficSourcesCard({
    loading,
    data,
    eventStarted,
    airmeetFormat,
    totalRegistrations = 0,
}) {
    const [selectedType, setSelectedType] = useState(TYPES[0]);

    const {
        sources,
        sources_visits,
        sources_unique_visits,
        mediums,
        mediums_visits,
        mediums_unique_visits,
        campaigns,
        campaigns_visits,
        campaigns_unique_visits,
        total_visits,
    } = data || {};

    const isV2Api = useMemo(
        () => isObject(sources_visits) && isObject(sources_unique_visits),
        [sources_visits, sources_unique_visits]
    );

    const { sourcesTotal, mediumsTotal, campaignsTotal } = useMemo(() => {
        return {
            sourcesTotal: getTotal(sources),
            mediumsTotal: getTotal(mediums),
            campaignsTotal: getTotal(campaigns),
        };
    }, [sources, mediums, campaigns]);

    const isEmptyData =
        !sourcesTotal &&
        !mediumsTotal &&
        !campaignsTotal &&
        !totalRegistrations;

    const { chartLabels, datasets, orderedData, chartData } = useMemo(() => {
        const chartLabels = [];
        const chartData = [];
        const datasets = [
            { label: 'Visitors', backgroundColor: '#4F30E8', data: [] },
            // { label: 'Unique Visitors', backgroundColor: '#6A4CFF', data: [] },
            { label: 'Registrations', backgroundColor: '#9580FF', data: [] },
        ];

        let data = null,
            dataVisits = null,
            dataUniqueVisits = null,
            orderedData = [];

        if (selectedType === 'Overall') {
            chartLabels.push('Overall');

            // V1 API
            chartData.push([total_visits, totalRegistrations]);

            // V2 API
            datasets[0].data.push(total_visits);
            datasets[1].data.push(totalRegistrations);
        } else {
            switch (selectedType) {
                case 'Source':
                    data = sources;
                    dataVisits = sources_visits;
                    dataUniqueVisits = sources_unique_visits;
                    break;
                case 'Medium':
                    data = mediums;
                    dataVisits = mediums_visits;
                    dataUniqueVisits = mediums_unique_visits;
                    break;
                case 'Campaign':
                    data = campaigns;
                    dataVisits = campaigns_visits;
                    dataUniqueVisits = campaigns_unique_visits;
                    break;
                default:
                    return {};
            }

            orderedData = orderBy(
                Object.keys(data || {}).map((key) => ({
                    key,
                    count: data ? data[key] : 0,
                    countVisits: dataVisits ? dataVisits[key] : 0,
                    countUniqueVisits: dataUniqueVisits
                        ? dataUniqueVisits[key]
                        : 0,
                })),
                ['count', 'key'],
                ['desc', 'asc']
            );
            orderedData.forEach((item) => {
                chartLabels.push(item?.key);

                // V1 API
                chartData.push(item?.count);

                // V2 API
                datasets[0].data.push(item?.countVisits);
                // datasets[1].data.push(item?.countUniqueVisits);
                datasets[1].data.push(item?.count);
            });
        }

        return {
            chartLabels,
            datasets,
            orderedData,
            chartData,
        };
    }, [
        selectedType,
        sources,
        sources_visits,
        sources_unique_visits,
        mediums,
        mediums_visits,
        mediums_unique_visits,
        campaigns,
        campaigns_visits,
        campaigns_unique_visits,
        totalRegistrations,
        total_visits,
    ]);

    const { getFeatureUrl } = useEventDashboardFeatures();

    const handleClick = () => {
        history.push(getFeatureUrl(TRACKING_URL_TAB));
    };

    return (
        <BarChartCard
            title={
                <FlexDiv alignItems='center' id={'trafficSourceCard'}>
                    Traffic Attributes
                    <RelativeToolTip
                        place='top'
                        svgWidth='13px'
                        svgHeight='13px'
                        svgIcon='icon-fill-info'
                        svgFill='accentPrimary.0'
                        tooltipText='This section lets you see how many registrants came from different marketing channels.'
                        textColor='ambience.18'
                        textVariant='caption'
                        minWidth={290}
                        style={{ marginLeft: '10px' }}
                        showStyledSvgSprite={true}
                    />
                </FlexDiv>
            }
            topData={isV2Api ? [] : orderedData}
            chartLabels={chartLabels}
            chartData={isV2Api ? null : chartData}
            datasets={isV2Api ? datasets : null}
            types={TYPES}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            loading={loading}
            isEmpty={isEmptyData}
            label={selectedType}
            eventStarted={eventStarted}
            airmeetFormat={airmeetFormat}
            emptyTitle={<EmptyData onClick={handleClick} />}
            emptyContentStyle={{ alignItems: 'flex-start' }}
            chartOptions={
                isV2Api
                    ? {
                          scales: {
                              xAxes: [
                                  {
                                      stacked: false,
                                      ticks: {},
                                      maxBarThickness: 72,
                                  },
                              ],
                              yAxes: [
                                  {
                                      stacked: false,
                                      ticks: {
                                          precision: 0,
                                          beginAtZero: true,
                                      },
                                  },
                              ],
                          },
                      }
                    : {}
            }
        />
    );
}

const EmptyData = ({ onClick }) => {
    return (
        <FlexDiv flexDirection='column' alignItems='stretch'>
            <Typography variant='subtext0' color='ambience.0' pb='x4'>
                You will be able to track the registration source if UTM
                parameters are added to the shared event URLs.
            </Typography>
            <Typography variant='subtext0' color='ambience.0' pb='x4'>
                To add UTM enabled URLs for tracking registrations, please click
                below.
            </Typography>
            <FlexDiv justifyContent='flex-end'>
                <Button
                    variant='secondary'
                    width='auto'
                    height='36px'
                    p='0 12px'
                    onClick={onClick}
                >
                    <StyledSvgSprite
                        icon='arrow-hook'
                        height='20px'
                        width='20px'
                        pr='4px'
                        fill='accentPrimary.0'
                        mr='0px !important'
                    />
                    Go to Registrations
                </Button>
            </FlexDiv>
        </FlexDiv>
    );
};

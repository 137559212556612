import ToggleGroup from 'atoms/ToggleGroup/ToggleGroup';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import useTheme from 'hooks/useTheme';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { logger } from 'utils/logger';
import LoadingOverlay from './components/LoadingOverlay';
import PostEventBarChart from './components/PostEventBarChart';
import { Card, LegendDot } from './styledComponents';
import { getTrafficSources } from './utils/api';

const TYPE_OPTIONS = [
    { value: 'overall', label: 'Overall' },
    { value: 'sources', label: 'Source' },
    { value: 'mediums', label: 'Medium' },
    { value: 'campaigns', label: 'Campaign' },
];

function TrafficSourcesCard({ logEvent, airmeetId, totalRegistrations = 0 }) {
    const { theme } = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [apiData, setApiData] = useState({});
    const [activeType, setActiveType] = useState(TYPE_OPTIONS[0]?.value);

    const legend = useMemo(
        () => [
            {
                value: 'visitors',
                label: 'Visitors',
                backgroundColor: theme.colors.module.viz.sky['600'],
            },
            // {
            //     value: 'unique_visitors',
            //     label: 'Unique visitors',
            //     backgroundColor: theme.colors.module.viz.sky['800'],
            // },
            {
                value: 'registrations',
                label: 'Registrations',
                backgroundColor: theme.colors.module.viz.sky['00'],
            },
        ],
        [theme]
    );

    const fetchData = useCallback(async () => {
        try {
            if (airmeetId) {
                setIsLoading(true);
                const response = await getTrafficSources({
                    airmeetId,
                });
                if (!isEmpty(response?.data?.traffic_source)) {
                    setApiData(response?.data?.traffic_source);
                }
            }
        } catch (e) {
            logger.error('TrafficSourcesCard.fetchData: ', e);
        } finally {
            setIsLoading(false);
        }
    }, [airmeetId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const { chartLabels, datasets } = useMemo(() => {
        if (activeType === 'overall') {
            const datasets = [
                {
                    label: legend[0]?.label,
                    backgroundColor: legend[0]?.backgroundColor,
                    data: [apiData?.total_visits],
                },
                {
                    label: legend[1]?.label,
                    backgroundColor: legend[1]?.backgroundColor,
                    data: [totalRegistrations],
                },
            ];

            return {
                chartLabels: ['Overall'],
                datasets,
            };
        } else {
            const visitors_data = get(apiData, `${activeType}_visits`, {});
            // const unique_visitors_data = get(
            //     apiData,
            //     `${activeType}_unique_visits`,
            //     {}
            // );
            const registrations_data = get(apiData, activeType, {});

            const datasets = [
                {
                    label: legend[0]?.label,
                    backgroundColor: legend[0]?.backgroundColor,
                    data: Object.keys(visitors_data).map(
                        (key) => visitors_data[key]
                    ),
                },
                // {
                //     label: legend[1]?.label,
                //     backgroundColor: legend[1]?.backgroundColor,
                //     data: Object.keys(unique_visitors_data).map(
                //         (key) => unique_visitors_data[key]
                //     ),
                // },
                {
                    label: legend[1]?.label,
                    backgroundColor: legend[1]?.backgroundColor,
                    data: Object.keys(registrations_data).map(
                        (key) => registrations_data[key]
                    ),
                },
            ];

            return {
                chartLabels: Object.keys(registrations_data),
                datasets,
            };
        }
    }, [legend, activeType, apiData, totalRegistrations]);

    const handleTypeChange = (value) => {
        if (logEvent) {
            const prevOption = TYPE_OPTIONS.find(
                (option) => option?.value === activeType
            );
            const option = TYPE_OPTIONS.find(
                (option) => option?.value === value
            );

            logEvent('trafficSourceTypeChange', {
                prevOption,
                option,
            });
        }

        setActiveType(value);
    };

    return (
        <Card flex='1'>
            <FlexDiv alignItems='flex-start' pb='x5' flex='1'>
                <FlexDiv
                    alignItems='center'
                    justifyContent='space-between'
                    flex='1'
                >
                    <Text
                        variant='body2'
                        color='text.default.primary'
                        flex='1'
                        pr='x4'
                    >
                        Traffic source
                    </Text>
                </FlexDiv>
            </FlexDiv>
            <PostEventBarChart
                chartLabels={chartLabels}
                datasets={datasets}
                height='12rem'
                stacked={false}
                cornerRadius={0}
            />
            <FlexDiv justifyContent='space-between' alignItems='center' pt='x6'>
                <ToggleGroup
                    items={TYPE_OPTIONS}
                    size='small'
                    value={activeType}
                    onValueChange={handleTypeChange}
                />
                <FlexDiv alignItems='center'>
                    {legend.map((item) => (
                        <FlexDiv key={item?.value} alignItems='center' pl='x7'>
                            <LegendDot bg={item?.backgroundColor} />
                            <Text variant='caption10' pl='x1'>
                                {item?.label}
                            </Text>
                        </FlexDiv>
                    ))}
                </FlexDiv>
            </FlexDiv>
            {isLoading && <LoadingOverlay />}
        </Card>
    );
}

export default TrafficSourcesCard;

import { useContainerNotifications } from 'atoms/Container/container';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Events } from 'utils/constants/containers/airmeet';
import {
    AV_SETTINGS_CLOSE,
    AV_SETTINGS_OPEN,
    MUTE_STAGE_USER_AV,
    REVERT_STAGE_USER_AV,
} from 'utils/constants/sessions';
import { logger } from 'utils/logger';

export default function useStageVideoHandler({ stageService }) {
    const { onNotify, offNotify } = useContainerNotifications();
    const [isVideoMuted, setVideoMuted] = useState(true);
    const logPrefix = useRef();
    logPrefix.current = `Stage video handler: ${
        stageService?.serviceName ?? 'default'
    }:`;
    const progressState = useRef(false);
    const isVideoMutedPrevious = useRef();

    const getLocalVideoStateRef = useRef(null);
    getLocalVideoStateRef.current = () =>
        !stageService?.localStream?.isVideoOn();

    const muteVideoHandler = useCallback(async () => {
        if (progressState.current) {
            logger.info(`${logPrefix.current} skip for mute video`, {
                progressState: progressState.current,
            });
            return;
        }
        logger.info(`${logPrefix.current} mute video init`);
        progressState.current = true;
        let result;
        try {
            result = await stageService.muteLocalVideo(false);
        } catch (e) {
            logger.info(`${logPrefix.current} mute video failed`, {
                e,
            });
            result = false;
        }
        // if operation failed
        if (!result) {
            progressState.current = false;
            logger.info(`${logPrefix.current} mute video failed`, {
                result,
            });
            return;
        }
        progressState.current = false;
        logger.info(`${logPrefix.current} mute video success`, {
            progressState: progressState.current,
        });
        setVideoMuted(getLocalVideoStateRef.current());
    }, [stageService]);

    const unMuteVideoHandler = useCallback(async () => {
        if (progressState.current) {
            logger.info(`${logPrefix.current} skip for unmute video`, {
                progressState: progressState.current,
            });
            return;
        }
        progressState.current = true;
        logger.info(`${logPrefix.current} unmute video init`);
        let result;
        try {
            result = await stageService.muteLocalVideo(true);
        } catch (e) {
            logger.info(`${logPrefix.current} unmute video failed`, {
                e,
            });
            result = false;
        }
        // if operation failed
        if (!result) {
            progressState.current = false;
            logger.info(`${logPrefix.current} unmute video failed`, {
                result,
            });
            return;
        }
        progressState.current = false;
        logger.info(`${logPrefix.current} unmute video success`, {
            progressState: progressState.current,
        });
        setVideoMuted(getLocalVideoStateRef.current());
    }, [stageService]);

    useEffect(() => {
        const localStreamVideoMute = ({ localStream }) => {
            const isVideoOn = localStream ? localStream.isVideoOn() : false;
            logger.info(`${logPrefix.current} on localStreamVideoMute`, {
                localStream: !!localStream,
                isVideoOn: isVideoOn,
            });
            setVideoMuted(!isVideoOn);
        };
        const localStreamVideoUnmute = ({ localStream }) => {
            const isVideoOn = localStream ? localStream.isVideoOn() : false;
            logger.info(`${logPrefix.current} on localStreamVideoUnmute`, {
                localStream: !!localStream,
                isVideoOn: isVideoOn,
            });
            setVideoMuted(!isVideoOn);
        };
        const onStreamUnpublish = () => {
            logger.info(
                `${logPrefix.current} on onStreamUnpublish setting video muted to true`
            );
            isVideoMutedPrevious.current = true;
            setVideoMuted(true);
        };
        const onUnmuteFailed = ({ localStream }) => {
            const isVideoOn = localStream ? localStream.isVideoOn() : false;
            logger.info(`${logPrefix.current} on onUnmuteFailed`, {
                localStream: !!localStream,
                isVideoOn,
            });
            setVideoMuted(!isVideoOn);
        };

        // mute the camera when permissions revoked
        const onDevicePermissionRevoked = ({ deviceType }) => {
            if (deviceType === 'camera') {
                logger.info(
                    `${logPrefix.current} initiate the camera mute on permission revoked`
                );
                muteVideoHandler();
            }
        };
        stageService.on(
            Events.rtcBrodCastLocalStreamVideoMute,
            localStreamVideoMute
        );
        stageService.on(
            Events.rtcBrodCastLocalStreamVideoUnmute,
            localStreamVideoUnmute
        );
        stageService.on(Events.rtcLiveStreamUnpublished, onStreamUnpublish);
        stageService.on(Events.videoUnmuteFailed, onUnmuteFailed);
        stageService.on(Events.permissionsRevoked, onDevicePermissionRevoked);

        return () => {
            stageService.off(
                Events.rtcBrodCastLocalStreamVideoMute,
                localStreamVideoMute
            );
            stageService.off(
                Events.rtcBrodCastLocalStreamVideoUnmute,
                localStreamVideoUnmute
            );
            stageService.off(
                Events.rtcLiveStreamUnpublished,
                onStreamUnpublish
            );
            stageService.off(Events.videoUnmuteFailed, onUnmuteFailed);
            stageService.off(
                Events.permissionsRevoked,
                onDevicePermissionRevoked
            );
        };
    }, [stageService]);

    useEffect(() => {
        const muteMedia = async ({ data }) => {
            if (!stageService?.hasPublished()) {
                return;
            }
            isVideoMutedPrevious.current = isVideoMuted;
            const { source } = data;
            if (!isVideoMuted) {
                try {
                    logger.info(
                        'Stage video handler: muteMedia - muting the video',
                        {
                            source,
                        }
                    );
                    await muteVideoHandler();
                } catch (e) {
                    logger.error(
                        'Stage video handler: Failed to toggle video',
                        e
                    );
                }
            }
        };
        const revertMedia = async ({ data }) => {
            if (!stageService?.hasPublished()) {
                return;
            }
            const { source } = data;
            if (
                isVideoMutedPrevious.current !== undefined &&
                !isVideoMutedPrevious.current
            ) {
                logger.info(
                    'Stage video handler: revertMedia - unmuting the video',
                    {
                        source,
                    }
                );
                await unMuteVideoHandler();
            }
        };
        onNotify(AV_SETTINGS_OPEN, muteMedia);
        onNotify(AV_SETTINGS_CLOSE, revertMedia);
        onNotify(MUTE_STAGE_USER_AV, muteMedia);
        onNotify(REVERT_STAGE_USER_AV, revertMedia);
        return () => {
            offNotify(AV_SETTINGS_OPEN, muteMedia);
            offNotify(AV_SETTINGS_CLOSE, revertMedia);
            offNotify(MUTE_STAGE_USER_AV, muteMedia);
            offNotify(REVERT_STAGE_USER_AV, revertMedia);
        };
    }, [onNotify, offNotify, isVideoMuted, muteVideoHandler, stageService]);

    return {
        isVideoMuted,
        muteVideoHandler,
        unMuteVideoHandler,
    };
}

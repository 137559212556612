export const LIVE_TABLE_STREAM_STATUS = {
    ERROR: 'error',
    LEAVING: 'leaving',
    INIT: 'init',
    WAIT: 'wait',
    INITIALIZED: 'initialized',
    JOINING: 'joining',
    CONNECTED: 'connected',
    PUBLISHING: 'publishing',
    UNPUBLISHING: 'unpublishing',
    PUBLISHED: 'published',
    DISCONNECTED: 'disconnected',
};

export const DEFAULT_TABLE_STREAM_QUALITY = {
    minBitrate: 240,
    maxBitrate: 400,
    height: 360,
    width: 640,
    frameRate: 15,
};

export const TABLE_GRID_ITEM_STYLES = {
    width: 264,
    height: 320,
    padding: 8,
};

export const STREAM_LABELS_HIDE_DELAY = 2000;
export const TABLE_DROP_OFF_TOLERANCE_THRESHOLD_WITH_FLAG = 30000;
export const TABLE_DROP_OFF_TOLERANCE_THRESHOLD_WITHOUT_FLAG = 2000;
export const TABLE_DROP_OFF_TOLERANCE_THRESHOLD = 2000;
export const TABLE_DROP_OFF_LATENCY_BUFFER = 200;

export const USER_JOIN_TIME = 'user_join_time';

export const SCREEN_SHARE_SOURCE_MONITOR = 'monitor';

export const tableCacheKey = (airmeetId) => `live-table-${airmeetId}`;

export const findMemberSeat = (id, members) => {
    for (let [seat, member] of Object.entries(members)) {
        if (member.id === id) return seat;
    }
    return null;
};

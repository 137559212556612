import createReducer from 'utils/createReducer';
import {
    SET_ARE_FILTERS_APPLIED,
    SET_FILTER_BODY,
    SET_SEARCH_KEYS,
    SET_SEARCH_STRING,
} from '../actions/PeopleSearch';

interface PeopleSearchState {
    searchKeys: Array<string>;
    searchValue: string;
    filterBody: object;
    areFiltersApplied: boolean;
}

export const initialPeopleSearchState: PeopleSearchState = {
    searchKeys: [],
    searchValue: '',
    filterBody: {},
    areFiltersApplied: false,
};

interface PeopleSearchCollection {
    [namespace: string]: PeopleSearchState;
}

const handlers = {
    [SET_SEARCH_STRING]: (
        state: PeopleSearchCollection,
        { payload: { body, namespace } }
    ) => ({
        ...state,
        [namespace]: {
            ...(state[namespace] || initialPeopleSearchState),
            searchValue: body,
        },
    }),
    [SET_ARE_FILTERS_APPLIED]: (
        state: PeopleSearchCollection,
        { payload: { body, namespace } }
    ) => ({
        ...state,
        [namespace]: {
            ...(state[namespace] || initialPeopleSearchState),
            areFiltersApplied: body,
        },
    }),
    [SET_FILTER_BODY]: (
        state: PeopleSearchCollection,
        { payload: { body, namespace } }
    ) => ({
        ...state,
        [namespace]: {
            ...(state[namespace] || initialPeopleSearchState),
            filterBody: body,
        },
    }),
    [SET_SEARCH_KEYS]: (
        state: PeopleSearchCollection,
        { payload: { body, namespace } }
    ) => ({
        ...state,
        [namespace]: {
            ...(state[namespace] || initialPeopleSearchState),
            searchKeys: body,
        },
    }),
};

export default createReducer({}, handlers);

import {
    DailyEventObjectNetworkQualityEvent,
    DailyRoomInfo,
} from '@daily-co/daily-js';
import {
    GROUP_MEETING_ADD_CHAT_MESSAGE,
    GROUP_MEETING_ADD_SCREEN_SHARE,
    GROUP_MEETING_CHAT_TAB_VISIBILITY,
    GROUP_MEETING_CLEAR_ALL_MESSAGES,
    GROUP_MEETING_ERROR,
    GROUP_MEETING_LOCAL_SCREEN_SHARED,
    GROUP_MEETING_NETWORK_QUALITY_CHANGE,
    GROUP_MEETING_PARTICIPANT_JOINED,
    GROUP_MEETING_PARTICIPANT_LEFT,
    GROUP_MEETING_PEOPLE_TAB_VISIBILITY,
    GROUP_MEETING_RECORDING_ACTIVE,
    GROUP_MEETING_REMOVE_SCREEN_SHARE,
    GROUP_MEETING_RESET_TO_DEFAULT,
    GROUP_MEETING_ROOM_INFO,
    GROUP_MEETING_SET_MAX_TILES_PER_PAGE,
    GROUP_MEETING_SET_WRAPPER,
    GROUP_MEETING_START_TIMER,
    GROUP_MEETING_UPDATE_AV_STATE,
    GROUP_MEETING_WEBVIEW,
    GROUP_MEETING_WEBVIEW_CALL_LEFT,
    GROUP_MEETING_CC,
} from 'store/actions/groupMeeeting';
import createReducer from 'utils/createReducer';
import {
    DEFAULT_AUDIO_VIDEO_STATE,
    DEFAULT_MAX_TILES_PER_PAGE,
    GM_MEDIA_STATE,
    MEDIA_STATES,
} from 'utils/DailyUIWrapper/constants';
import { IDailyUIWrapper } from 'utils/DailyUIWrapper/IDailyUIWrapper';

export interface GroupMeetingState {
    dailyWrapper: IDailyUIWrapper | null;
    maxTilesPerPage: number;
    peopleTabOpen: boolean;
    chatTabOpen: boolean;
    chatMessages: Array<any>;
    participants: Array<string>;
    participantsCount: number;
    localScreenShared: boolean;
    networkQuality: DailyEventObjectNetworkQualityEvent | null;
    mediaState: GM_MEDIA_STATE;
    screenShares: Array<string>;
    error: any;
    isRecordingActive: boolean;
    roomInfo: DailyRoomInfo;
    showStartTimerFor: string;
    webview: boolean;
    webviewCallLeft: boolean;
    showCC: boolean;
}

const initialState: GroupMeetingState = {
    dailyWrapper: null,
    maxTilesPerPage: DEFAULT_MAX_TILES_PER_PAGE,
    peopleTabOpen: false,
    chatTabOpen: false,
    chatMessages: [],
    participants: [],
    participantsCount: 0,
    localScreenShared: false,
    networkQuality: null,
    mediaState: DEFAULT_AUDIO_VIDEO_STATE,
    screenShares: [],
    error: null,
    isRecordingActive: false,
    roomInfo: null,
    showStartTimerFor: null,
    webview: false,
    webviewCallLeft: false,
    showCC: false,
};

const handlers = {
    [GROUP_MEETING_NETWORK_QUALITY_CHANGE]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        networkQuality: payload,
    }),
    [GROUP_MEETING_ERROR]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        error: payload,
    }),
    [GROUP_MEETING_SET_WRAPPER]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        dailyWrapper: payload,
    }),
    [GROUP_MEETING_RESET_TO_DEFAULT]: (state: GroupMeetingState) => ({
        ...initialState,
        webview: state.webview,
        isEnabled: state.isEnabled,
        showCC: state.showCC,
        maxTilesPerPage: state.maxTilesPerPage,
    }),
    [GROUP_MEETING_PEOPLE_TAB_VISIBILITY]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => {
        return {
            ...state,
            peopleTabOpen: payload,
            chatTabOpen: payload ? false : state.chatTabOpen,
        };
    },
    [GROUP_MEETING_CHAT_TAB_VISIBILITY]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => {
        return {
            ...state,
            chatTabOpen: payload,
            peopleTabOpen: payload ? false : state.peopleTabOpen,
        };
    },
    [GROUP_MEETING_ADD_CHAT_MESSAGE]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => {
        return {
            ...state,
            chatMessages: [...state.chatMessages, payload],
        };
    },
    [GROUP_MEETING_CLEAR_ALL_MESSAGES]: (
        state: GroupMeetingState = initialState
    ) => ({
        ...state,
        chatMessages: [],
    }),
    [GROUP_MEETING_PARTICIPANT_JOINED]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => {
        const newParticipantsAr = payload.isLocal
            ? [payload.id, ...state.participants]
            : [...state.participants, payload.id];

        return {
            ...state,
            participants: newParticipantsAr,
            participantsCount: newParticipantsAr.length,
        };
    },
    [GROUP_MEETING_PARTICIPANT_LEFT]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => {
        const newParticipantsAr = state.participants.filter(
            (id) => id !== payload
        );

        return {
            ...state,
            participants: newParticipantsAr,
            participantsCount: newParticipantsAr.length,
            screenShares: state.screenShares.filter((id) => id !== payload),
        };
    },
    [GROUP_MEETING_LOCAL_SCREEN_SHARED]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        localScreenShared: payload,
    }),
    [GROUP_MEETING_UPDATE_AV_STATE]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => {
        const curMediaState = { ...state.mediaState };
        if (curMediaState.audio !== MEDIA_STATES.BLOCKED) {
            curMediaState.audio = payload.audio;
        }
        if (curMediaState.video !== MEDIA_STATES.BLOCKED) {
            curMediaState.video = payload.video;
        }
        return {
            ...state,
            mediaState: curMediaState,
        };
    },
    [GROUP_MEETING_ADD_SCREEN_SHARE]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        screenShares: state.screenShares.includes(payload)
            ? state.screenShares
            : [...state.screenShares, payload],
    }),
    [GROUP_MEETING_REMOVE_SCREEN_SHARE]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        screenShares: state.screenShares.includes(payload)
            ? state.screenShares.filter((id) => id !== payload)
            : state.screenShares,
    }),
    [GROUP_MEETING_SET_MAX_TILES_PER_PAGE]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        maxTilesPerPage: payload,
    }),
    [GROUP_MEETING_RECORDING_ACTIVE]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        isRecordingActive: payload,
    }),
    [GROUP_MEETING_ROOM_INFO]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        roomInfo: payload,
    }),
    [GROUP_MEETING_START_TIMER]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        showStartTimerFor: payload,
    }),
    [GROUP_MEETING_WEBVIEW]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        webview: payload,
    }),
    [GROUP_MEETING_WEBVIEW_CALL_LEFT]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        webviewCallLeft: payload,
    }),
    [GROUP_MEETING_CC]: (
        state: GroupMeetingState = initialState,
        { payload }
    ) => ({
        ...state,
        showCC: payload,
    }),
};

export default createReducer(initialState, handlers);

import { ALL_PLATFORM_FEATURES } from '../../hooks/useFeatureControl';

const default_CLOSED_CAPTIONS_USING_RTC_SDK = 'true'; // false:'Symbl', true:'Polyglot'
const default_CLOSED_CAPTIONS_SERVICE_PROVIDER = '0'; // 0:'Google', 1:'AWS' (if default_CLOSED_CAPTIONS_USING_RTC_SDK is false(Symbl), NO impact of this value)
const default_CLOSED_CAPTIONS_DEFAULT_LANGUAGE = 'en-US'; // (if default_CLOSED_CAPTIONS_USING_RTC_SDK is false(Symbl), NO impact of this value)
const default_ENABLE_CLOSED_CAPTIONS_TRANSLATION = false;

const CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION = {
    google: '0', // Google
    aws: '1', // AWS
};

export const DEFAULT_CC_LANGUAGE = 'default';

export const defaultSelectedCCLanguage = {
    label: 'Off',
    value: 'off',
};

function parseBoolean(x: string) {
    return x?.length > 0 ? x.toLowerCase() === 'true' : false;
}

export const getStorageKeyForCCSubscriptionLanguage = (
    sessionId: string,
    userId: string
) => `${sessionId}-${userId}-closed-captions-language`;

export const createClosedCaptionsConfig = ({
    airmeetId,
    communityId,
    featuresConfig,
    backendConfig,
    live_config,
    closed_captions,
    defaultPrimaryLanguage,
}: {
    airmeetId: string;
    communityId: string;
    featuresConfig: Object;
    backendConfig: Object;
    live_config: any;
    closed_captions: string;
    defaultPrimaryLanguage: any;
}) => {
    let closedCaptionsUsingRTCSDK = default_CLOSED_CAPTIONS_USING_RTC_SDK;
    let closedCaptionsServiceProvider = default_CLOSED_CAPTIONS_SERVICE_PROVIDER;
    let enabledClosedCaptionTranslation = default_ENABLE_CLOSED_CAPTIONS_TRANSLATION;

    if (
        featuresConfig?.hasOwnProperty(
            ALL_PLATFORM_FEATURES.CLOSED_CAPTIONS_USING_RTC_SDK
        )
    ) {
        closedCaptionsUsingRTCSDK =
            featuresConfig?.[
                ALL_PLATFORM_FEATURES.CLOSED_CAPTIONS_USING_RTC_SDK
            ];
    }

    if (
        featuresConfig?.hasOwnProperty(
            ALL_PLATFORM_FEATURES.CLOSED_CAPTIONS_SERVICE_PROVIDER
        )
    ) {
        closedCaptionsServiceProvider =
            featuresConfig?.[
                ALL_PLATFORM_FEATURES.CLOSED_CAPTIONS_SERVICE_PROVIDER
            ]; // values comes like 0(for google) or 1(for aws)
    } else if (
        backendConfig?.hasOwnProperty(
            ALL_PLATFORM_FEATURES.CLOSED_CAPTIONS_SERVICE_PROVIDER
        )
    ) {
        closedCaptionsServiceProvider =
            CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION[
                backendConfig?.[
                    ALL_PLATFORM_FEATURES.CLOSED_CAPTIONS_SERVICE_PROVIDER
                ]
            ]; // values comes like 'google'/'aws', converting it 0/1 ('google' to 0 and 'aws' to 1)
    }

    if (
        featuresConfig?.hasOwnProperty(
            ALL_PLATFORM_FEATURES.ENABLE_CLOSED_CAPTIONS_TRANSLATION
        )
    ) {
        // Double negation to convert string/number/any-value to boolean
        enabledClosedCaptionTranslation = !!featuresConfig?.[
            ALL_PLATFORM_FEATURES.ENABLE_CLOSED_CAPTIONS_TRANSLATION
        ];
    }

    const isCCTranslationEnabled =
        enabledClosedCaptionTranslation ??
        default_ENABLE_CLOSED_CAPTIONS_TRANSLATION;
    return {
        isCCUsingRTCSDK: parseBoolean(
            closedCaptionsUsingRTCSDK ?? default_CLOSED_CAPTIONS_USING_RTC_SDK
        ),
        ccPolyglotServiceProvider: parseInt(
            closedCaptionsServiceProvider ??
                default_CLOSED_CAPTIONS_SERVICE_PROVIDER
        ),
        communityId,
        airmeetId,
        ccLangs:
            isCCTranslationEnabled && live_config?.allowTranslatedCaption
                ? live_config?.ccLangs
                : [],
        ccPrimaryLang: live_config?.ccPrimaryLang ?? defaultPrimaryLanguage,
        showCloseCaption: live_config?.showCloseCaption,
        allowTranslatedCaption: live_config?.allowTranslatedCaption,
        closed_captions,
        isCCTranslationEnabled,
    };
};

export const CC_LANGUAGES_WITH_BIGGER_FONTS_HEIGHT = [
    'te', // Telugu
    'kn', // Kannada
];

export const CC_LANGUAGES_WITH_CUSTOM_FONTS_FILE = [
    'mni-Mtei', // Manipuri
];

import { getDateStr } from 'components/molecules/card/AirmeetCard/utils';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import * as Yup from 'yup';

export const CHAR_LIMIT_MAP = {
    name: { min: 3, max: 80 },
    eventOrganiserName: { max: 100 },
    description: { min: 3, max: 150 },
    supportEmail: { min: 3, max: 254 },
    venue_capacity: { min: 0, max: 999999999 },
    duration: { min: 1, max: 99999 },
};

const FORBIDDEN_HTML_TAGS = [
    'audio',
    'video',
    'iframe',
    'source',
    'embed',
    'style',
    'input',
    'button',
    'meta',
    'script',
];

export const yupValidateHTMLString = (path = 'longDesc') =>
    Yup.string().test({
        name: 'no-forbidden-tags',
        test: function (value) {
            if (value) {
                // Find which forbidden tags are present in the string
                const foundTags = FORBIDDEN_HTML_TAGS.filter((tag) => {
                    const regex = new RegExp(`<\\s*${tag}\\b`, 'i');
                    return regex.test(value);
                });

                // If any forbidden tags are found, return a dynamic error message
                if (foundTags.length > 0) {
                    return this.createError({
                        message: `${foundTags.join(', ')} tag${
                            foundTags.length > 1 ? 's are' : ' is'
                        } not allowed.`,
                        path,
                    });
                }
            }
            return true; // If no forbidden tags, return valid
        },
    });

const CreateAirmeetV2Schema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .min(
            CHAR_LIMIT_MAP.name.min,
            `Minimum ${CHAR_LIMIT_MAP.name.min} characters required`
        ),
    isPrivate: Yup.boolean(),
    guestAuth: Yup.boolean(),
    initDate: Yup.date()
        .test({
            name: 'min-datetime',
            test: function (initDt) {
                const { timeZone, editMode } = this.options.context;
                const currentZonedTime = new Date(
                    getDateStr({
                        start_time: new Date(),
                        timezone: timeZone,
                        format: 'YYYY-MM-DD HH:mm:ss',
                    })
                );
                if (initDt < currentZonedTime && !editMode) {
                    if (
                        initDt.toDateString() ===
                        currentZonedTime.toDateString()
                    ) {
                        return this.createError({
                            message: 'Cannot create event for past time',
                            path: 'initTime',
                        });
                    } else {
                        return this.createError({
                            message: 'Cannot create event for past date',
                            path: 'initDate',
                        });
                    }
                }
                return true;
            },
        })
        .max(
            moment().add({ years: 1 }),
            'Cannot enter date 1 year from current date'
        ),
    finalDate: Yup.date()
        .min(Yup.ref('initDate'), 'End Date cannot be earlier than start date')
        .max(
            moment(Yup.ref('initDate')).add({ years: 5 }),
            'Cannot enter a date 5 years from start date'
        ),
    timeZone: Yup.string().required('Time zone is required'),
    hasVirtualTables: Yup.boolean(),
    tableCount: Yup.number().when('hasVirtualTables', {
        is: true,
        then: Yup.number()
            .min(1, 'At least one table is required')
            .max(500, 'Max 500 tables allowed'),
    }),
    supportEmail: Yup.string()
        .trim()
        .lowercase()
        .email('Please provide a valid email address'),

    duration: Yup.string()
        .test(
            'valid-duration',
            'Duration should be in minute format',
            function (value) {
                const { meetupSubType } = this.options.context;
                if (meetupSubType === 'LITE') {
                    if (value === '') {
                        return this.createError({
                            message: 'Duration cannot be empty',
                            path: 'duration',
                        });
                    } else if (value < CHAR_LIMIT_MAP.duration.min) {
                        return this.createError({
                            message: 'Please enter a valid value',
                            path: 'duration',
                        });
                    } else if (value > CHAR_LIMIT_MAP.duration.max) {
                        return this.createError({
                            message:
                                'Duration cannot be greater than 99999 minutes',
                            path: 'duration',
                        });
                    } else {
                        return !isNaN(value);
                    }
                }
                return true;
            }
        )
        .max(
            CHAR_LIMIT_MAP.duration.max,
            'Duration cannot be greater than 99999 minutes'
        ),
    eventVenue: Yup.object().test({
        name: 'eventVenue-capacity',
        test: function (eventVenue) {
            const { venue_capacity } = this.options.context;
            if (
                (venue_capacity || venue_capacity === 0) &&
                isEmpty(eventVenue)
            ) {
                return this.createError({
                    message: 'Event venue is required',
                    path: 'eventVenue',
                });
            }
            return true;
        },
    }),
    venue_capacity: Yup.string()
        .nullable(true)
        .min(
            CHAR_LIMIT_MAP.venue_capacity.min,
            'Minimum value of venue capacity is 0'
        )
        .max(
            CHAR_LIMIT_MAP.venue_capacity.max,
            `Maximum value of venue capacity is ${CHAR_LIMIT_MAP.venue_capacity.max}`
        )
        .test('integer', 'Venue capacity should be an integer', (value) => {
            if (value === '' || value === undefined || value === null) {
                return true;
            } else if (
                !Number.isInteger(parseInt(value)) ||
                String(value).includes('.') ||
                String(value).includes(' ')
            ) {
                return false;
            }
            return true;
        }),
    longDesc: yupValidateHTMLString(),
});

const CreateAirmeetV2OnboardingSchema = Yup.object().shape({
    eventOrganiserName: Yup.string()
        .trim()
        .required(`Please mention who the event is 'Organised by'`),
});

export const validateFormData = async (data, options, setErrors) => {
    try {
        if (!options.isDuplicate) {
            await CreateAirmeetV2OnboardingSchema.validate(data, options);
        }
        await CreateAirmeetV2Schema.validate(data, options);
        setErrors({});
        return true;
    } catch (error) {
        let errorData = [];
        (error.inner || []).map((inr) => {
            if (!errorData[inr.path]) {
                errorData[inr.path] = inr.message;
            }
            return inr;
        });
        setErrors(errorData);
        return false;
    }
};

export const validateSingleField = async (
    name,
    value,
    setErrors,
    isOnboardingFieldCheck = false
) => {
    try {
        if (isOnboardingFieldCheck) {
            await Yup.reach(CreateAirmeetV2OnboardingSchema, name).validate(
                value.trim()
            );
        } else {
            await Yup.reach(CreateAirmeetV2Schema, name).validate(value.trim());
        }
        setErrors((errors) => ({ ...errors, [name]: '' }));
        return true;
    } catch (error) {
        setErrors((errors) => ({ ...errors, [name]: error.message }));
        return false;
    }
};

import { LazyLoaded } from 'hooks/common';
import isFunction from 'lodash/isFunction';
import React from 'react';
import { IS_CUSTOM_TENANT } from 'utils/tenant';

const BeamerButton = LazyLoaded(React.lazy(() => import('../BeamerButton')));
const HelpButton = LazyLoaded(React.lazy(() => import('../HelpButton')));
const SupportButton = LazyLoaded(React.lazy(() => import('../SupportButton')));
const AcademyButton = LazyLoaded(React.lazy(() => import('../AcademyButton')));
const DemoButtonV2 = LazyLoaded(React.lazy(() => import('../DemoButtonV2')));
const BeaconBar = LazyLoaded(React.lazy(() => import('../BeaconBar')));

// video editor exclusive
const RecordingTitle = LazyLoaded(
    React.lazy(() => import('components/videoEditor/header/RecordingTitle'))
);
const UndoButton = LazyLoaded(
    React.lazy(() => import('components/videoEditor/header/UndoButton'))
);
const RedoButton = LazyLoaded(
    React.lazy(() => import('components/videoEditor/header/RedoButton'))
);
const RevertToOriginalButton = LazyLoaded(
    React.lazy(() =>
        import('components/videoEditor/header/RevertToOriginalButton')
    )
);
const SaveClipButton = LazyLoaded(
    React.lazy(() => import('components/videoEditor/header/SaveButton'))
);

export default function CommunityHeader({
    isMobileOrTablet,
    isEventDashboard,
    hideBeaconBar = false,
    fromVideoEditor,
    handleExport,
    handleRevertToOriginal,
    handleUndo,
    handleRedo,
    recordingName,
    canUndo,
    canRedo,
    isTrimTab,
}) {
    const HEADER_LEFT = [];

    if (!hideBeaconBar)
        HEADER_LEFT.push(<BeaconBar isEventDashboard={isEventDashboard} />);

    const HEADER_CONTENT = [];

    const HEADER_RIGHT = isMobileOrTablet
        ? []
        : [
              <DemoButtonV2 />,
              <AcademyButton />,
              <SupportButton />,
              <HelpButton />,
              process.env.REACT_APP_BEAMER_WIDGET_ENABLED === 'true' &&
              !IS_CUSTOM_TENANT ? (
                  <BeamerButton />
              ) : null,
          ];

    if (fromVideoEditor) {
        if (recordingName)
            HEADER_LEFT.push(<RecordingTitle recordingName={recordingName} />);
        if (isFunction(handleUndo) && isTrimTab)
            HEADER_CONTENT.push(
                <UndoButton canUndo={canUndo} handleUndo={handleUndo} />
            );
        if (isFunction(handleRedo) && isTrimTab)
            HEADER_CONTENT.push(
                <RedoButton canRedo={canRedo} handleRedo={handleRedo} />
            );
        if (isFunction(handleRevertToOriginal)) {
            HEADER_CONTENT.push(
                <RevertToOriginalButton
                    handleRevertToOriginal={handleRevertToOriginal}
                />
            );
        }
        if (isFunction(handleExport)) {
            HEADER_CONTENT.push(<SaveClipButton handleExport={handleExport} />);
        }
    }

    return [HEADER_LEFT, HEADER_CONTENT, HEADER_RIGHT];
}

import ReactHtmlParser from 'react-html-parser';
import { ExternalUrls } from 'utils/redirections';

//plans
export const FREE = 'Free';
export const BUSINESS = 'Social Webinar';
export const BUSINESS_OLD = 'Business';
export const ENTERPRISE = 'Pro';
export const ENTERPRISE_OLD = 'Conference';
export const BUSINESS_PRO = 'Social Webinar Pro';
export const BUSINESS_PRO_OLD = 'Business Pro';
export const CONFERENCE_STARTER = 'Conference Starter';
export const CONFERENCE_PREMIUM = 'Conference Premium';
export const CONFERENCE_PRO = 'Conference Pro';
export const STANDARD = 'Standard';

// attendee feature list mapping flags
export const ATTENDEE_STARTER = 'STARTER_ATTENDEE';
export const ATTENDEE_PRO = 'PRO_ATTENDEE';
export const ATTENDEE_ENTERPRISE = 'ENTERPRISE_ATTENDEE';

//Attendee starter plan subtypes
export const STARTER_SELF_SERVE_ATTENDEE =
    process.env.REACT_APP_STARTER_SELF_SERVE_ATTENDEE ||
    'starter-self-serve-plan';
export const STARTER_ANNUAL_SELF_SERVE_ATTENDEE =
    process.env.REACT_APP_STARTER_ANNUAL_SELF_SERVE_ATTENDEE ||
    'starter-self-serve-annual-plan';
export const STARTER_SELF_SERVE_ATTENDEE_V2 =
    process.env.REACT_APP_STARTER_SELF_SERVE_ATTENDEE_V2;
export const STARTER_ANNUAL_SELF_SERVE_ATTENDEE_V2 =
    process.env.REACT_APP_STARTER_ANNUAL_SELF_SERVE_ATTENDEE_V2;

//business plan subtypes
export const BUSINESS_MONTHLY = 'Business_Monthly';
export const BUSINESS_YEARLY = 'Business_Yearly';
export const STANDARD_YEARLY = 'Standard_Yearly';
export const ONE_TIME_30_DAYS_PLAN = 'social-webinar-30-days-pe-one-time';
export const ONE_TIME_60_DAYS_PLAN = 'social-webinar-60-days-pe-one-time';

//conference plan subtypes
export const CONFERENCE_STARTER_MONTHLY = 'conference-starter-tiered';
export const CONFERENCE_PRO_MONTHLY = 'conference-pro';

//misc
export const BILLING_HOME_SCREEN = 'BILLING_HOME_SCREEN';
export const PLAN_UPGRADE_SCREEN = 'PLAN_UPGRADE_SCREEN';
export const SOCIAL_WEBINAR_TAB = 'SOCIAL_WEBINAR_TAB';
export const CONFERENCE_TAB = 'CONFERENCE_TAB';
export const COMBINED_TAB = 'COMBINED_TAB';
export const EVENTS_TAB = 'EVENTS_TAB';
export const BILLED_MONTHLY_TAB = 'Bill Monthly';
export const BILLED_ANNUALLY_TAB = 'Bill Annually– Save 10%';
export const BILLING_SECTION = 'BILLING_SECTION';
export const VALIDITY_SECTION = 'VALIDITY_SECTION';
export const PAY_MONTHLY = 'Pay monthly';
export const PAY_ANNUALLY = 'Pay annually';
export const VALIDITY_30_DAYS = '30 days validity';
export const VALIDITY_60_DAYS = '60 days validity';
export const UNLIMITED_COUPON_VALIDITY = 'unlimited';

// billing period units
export const MONTH = 'month';
export const YEAR = 'year';

// plan intents
// Passed as param when user is redirected from hub to auth page
export const PREMIUM_WEBINAR_INTENT = 'premium_webinar';
export const FREE_INTENT = 'free';

// coupon ids to show limited banner for
export const SUMMER_SAVER = 'SUMMERSAVER';

// Use coupon ids in uppercase only
export const limitedOfferBannerValidCouponIds = [];

export const planMappingToSubTypes = {
    [FREE]: 'Starter',
    [BUSINESS]: 'Growth',
    [ENTERPRISE]: 'Enterprise',
    [BUSINESS_PRO]: 'Business Pro',
    [BUSINESS_OLD]: 'Growth',
    [ENTERPRISE_OLD]: 'Enterprise',
    [CONFERENCE_STARTER]: 'Conference Starter',
    [CONFERENCE_PREMIUM]: 'Enterprise',
    [CONFERENCE_PRO]: 'Conference Pro',
    [STANDARD]: 'Standard',
    [ATTENDEE_STARTER]: 'Attendee Starter',
    [ATTENDEE_PRO]: 'Attendee Professional',
    [ATTENDEE_ENTERPRISE]: 'Attendee Enterprise',
};

export const planIntentToAirmeetSubTypeMapping = {
    [PREMIUM_WEBINAR_INTENT]: planMappingToSubTypes[ATTENDEE_STARTER],
};

// Full feature list sub headings
const BRANDING_AND_CUSTOMIZATIONS = 'Branding and Customizations';
const ENGAGEMENT_AND_NETWORKING = 'Engagement and Networking';
const SETUP_AND_MANAGEMENT = 'Setup and Management';
const DATA_AND_ANALYTICS = 'Data and Analytics';
const EXHIBITOR_AND_SPONSOR_EXPERIENCE = 'Exhibitor and Sponsor Experience';
const SUPPORT_AND_SERVICES = 'Support and Services';

export const plansDetailsMapping = (theme) => ({
    [planMappingToSubTypes[FREE]]: {
        displayName: FREE,
        summary:
            'For small engaging events, training sessions, and community meet-ups',
        features: [
            { title: 'Get 1 organizer license' },
            { title: 'Host unlimited events' },
            { title: 'Run sessions up to 90 mins' },
            { title: 'Download SD recordings' },
            { title: 'Play pre-recorded videos' },
            { title: 'Plan speed networking' },
            { title: 'Sell and manage tickets' },
            { title: 'Stream on social media' },
            { title: 'Track real-time event analytics' },
        ],
        pricing: '0',
        paymentCycle: null,
        extraFeature: 'Up to 100 registrations/event',
        sliderText: 'Registrations per event',
        callToAction: 'Your Current Plan',
        buttonId: 'current-plan-button',
        listSubHeading: null,
        listSuffixText: null,
        priceOnRequest: false,
        redirectToContactSalesTypeform: false,
        planBtnDisabled: true,
        isMostPopularPlan: false,
        hasStartingPrice: false,
        additionalText: '',
        planBtnBackground: 'transparent',
        planBtnBorder: 'transparent',
        planBtnColor: 'ambience.6',
        planTitleColor: 'accentContentDisable',
        planSubheadingColor: 'accentContentDisable',
        planPriceColor: 'accentContentTertiary',
        eventType: planMappingToSubTypes[FREE],
        additionalPricingInfo: '',
        registrationsStripText: 'Upto 100 registrations/event',
        registrationsDropdown: false,
    },
    [planMappingToSubTypes[BUSINESS]]: {
        displayName: BUSINESS,
        summary:
            'Customizable, live and on-demand webinars, that connect with your tools',
        features: [
            {
                title: 'up to 1000 Registrations/event',
                hideLineItem: true,
            },
            {
                title: '10 Organizers',
                hideLineItem: true,
            },
            {
                title: 'Unlimited session duration',
            },
            {
                title: 'HD Recording',
            },
            {
                title: 'Custom registration forms',
            },
            {
                title: 'Event branding',
            },
            {
                title: 'Sponsor showcase',
            },
            {
                title: 'Advanced analytics',
            },
            {
                title: 'Zapier, HubSpot, MailChimp, Eventbrite integrations',
            },
            {
                title: 'Public APIs',
            },
            {
                title: 'Priority support',
            },
        ],
        pricing: '$99',
        paymentCycle: '/mo',
        extraFeature: '100 registrations/event',
        quoteSubText: {
            [BUSINESS_MONTHLY]: '',
            [BUSINESS_YEARLY]: 'billed annually',
        },
        callToAction: 'Upgrade',
        buttonId: 'buy-plan-button',
        listSubHeading: `Everything in ${FREE}, plus...`,
        listSuffixText: null,
        priceOnRequest: false,
        redirectToContactSalesTypeform: false,
        planBtnDisabled: false,
        isMostPopularPlan: true,
        hasStartingPrice: true,
        additionalText: 'Everything in "FREE" plus...',
        planBtnBackground: theme.colors.lighting[7],
        planBtnBorder: 'transparent',
        planBtnColor: 'ambience.24',
        eventType: planMappingToSubTypes[BUSINESS],
        additionalPricingInfo: '',
    },
    [planMappingToSubTypes[ENTERPRISE]]: {
        displayName: 'Pro',
        summary:
            'For large virtual & hybrid conferences, conventions, and tradeshows.',
        features: [
            { title: 'Host multi-track events' },
            { title: 'Get session replays' },
            { title: 'Customize Receptions' },
            { title: 'Brand your event URL' },
            { title: 'Get a lounge space for fluid conversations' },
            { title: 'Use event gamification tools' },
            { title: 'Expand networking tables up to 50 people' },
            { title: 'Access via single sign-on (SSO)' },
            {
                title: 'Scheduled group and 1:1 meetings',
            },
        ],
        pricing: '10,000',
        paymentCycle: '',
        extraFeature: 'Upto 100,000 registrations/event',
        quoteSubText: {
            [BUSINESS_MONTHLY]: '',
            [BUSINESS_YEARLY]: 'billed annually',
        },
        callToAction: 'Talk To Us',
        buttonId: 'contact-sales-button',
        listSubHeading: `Everything in ${FREE}, plus...`,
        listSuffixText: null,
        hasComparePlanButton: false,
        priceOnRequest: false,
        redirectToContactSalesTypeform: true,
        planBtnDisabled: false,
        isMostPopularPlan: true,
        hasStartingPrice: true,
        additionalText: 'Everything in “Standard”, plus...',
        planBtnBackground: theme.colors.accentPrimary[0],
        planBtnBorder: 'transparent',
        planBtnColor: 'ambience.0',
        planTitleColor: 'ambience.0',
        eventType: planMappingToSubTypes[ENTERPRISE],
        additionalPricingInfo: 'billed annually',
        registrationsStripText: 'Scale up to 100,000 registrations',
        registrationsDropdown: false,
    },
    [planMappingToSubTypes[BUSINESS_PRO]]: {
        displayName: BUSINESS_PRO,
        summary:
            'Fully customizable enterprise grade webinar marketing solution',
        features: [
            {
                title: 'up to 100,000 Registrations/event',
                hideLineItem: true,
            },
            {
                title: 'Closed captions',
            },
            {
                title: 'Interprify (Live translation)',
            },
            {
                title: 'SSO',
            },
            {
                title:
                    'Salesforce, Marketo, MS Dynamics & all future integrations',
            },
            {
                title: 'Custom subdomain',
            },
            {
                title: '50 people breakout rooms',
            },
        ],
        pricing: '4000',
        paymentCycle: null,
        extraFeature: '100 registrations/event',
        quoteSubText: {
            [BUSINESS_MONTHLY]: '',
            [BUSINESS_YEARLY]: 'billed annually',
        },
        callToAction: 'Contact Sales',
        buttonId: 'buy-plan-button',
        listSubHeading: null,
        listSuffixText: null,
        priceOnRequest: false,
        redirectToContactSalesTypeform: true,
        planBtnDisabled: false,
        isMostPopularPlan: false,
        hasStartingPrice: true,
        additionalText: 'Everything in "Social Webinar" plus...',
        planBtnBackground: 'transparent',
        planBtnBorder: theme.colors.lighting[7],
        planBtnColor: theme.colors.lighting[7],
        planTitleColor: theme.colors.lighting[7],
        eventType: BUSINESS_PRO,
        additionalPricingInfo: 'billed annually',
        registrationsStripText: '100 registrations/event',
        registrationsDropdown: true,
    },
    [planMappingToSubTypes[CONFERENCE_STARTER]]: {
        displayName: CONFERENCE_STARTER,
        summary: 'Small events, conferences and expos with parallel tracks',
        features: [
            {
                title: 'up to 300 Registrations/month',
                hideLineItem: true,
                textDecoration: 'none',
            },
            {
                title: '3 Organizers',
                textDecoration: 'none',
                hideLineItem: true,
            },
            {
                title: 'Parallel tracks',
                textDecoration: 'none',
            },
            { title: 'Reception ', textDecoration: 'none' },
            {
                title: 'Exhibitor booths',
                textDecoration: 'none',
            },
            {
                title: 'Basic analytics',
                textDecoration: 'none',
            },
            {
                title: 'Hybrid ready',
                textDecoration: 'none',
            },
            {
                title: 'Priority Support',
                textDecoration: 'none',
            },
            {
                title: 'Custom Registration Forms',
                textDecoration: 'none',
            },
            {
                title: 'Event Branding',
                textDecoration: 'line-through',
                hideLineItem: true,
            },
            {
                title: 'Sponsor Showcase',
                textDecoration: 'line-through',
                hideLineItem: true,
            },
            {
                title: 'Session Replay',
                textDecoration: 'line-through',
                hideLineItem: true,
            },
            {
                title: 'Space Customization',
                textDecoration: 'line-through',
                hideLineItem: true,
            },
            {
                title: 'Booth Broadcasts',
                textDecoration: 'line-through',
                hideLineItem: true,
            },
            {
                title: 'OOB Integrations',
                textDecoration: 'line-through',
                hideLineItem: true,
            },
        ],
        pricing: '$99',
        paymentCycle: '/mo',
        extraFeature: 'Up to 300 registrations/event',
        quoteSubText: {
            [BUSINESS_MONTHLY]: '',
            [BUSINESS_YEARLY]: 'billed annually',
        },
        callToAction: 'Upgrade',
        buttonId: 'buy-plan-button',
        listSubHeading: `Everything in ${FREE}, plus...`,
        listSuffixText: null,
        priceOnRequest: false,
        redirectToContactSalesTypeform: true,
        planBtnDisabled: false,
        isMostPopularPlan: false,
        hasStartingPrice: true,
        additionalText: '',
        planBtnBackground: theme.colors.lighting[1],
        planBtnBorder: theme.colors.lighting[1],
        planBtnColor: 'ambience.22',
        eventType: CONFERENCE_STARTER,
        additionalPricingInfo: '',
    },
    [planMappingToSubTypes[CONFERENCE_PRO]]: {
        features: [
            // {
            //     title: '5 team members', Will need to modify later based on team member limits
            //     tooltipText: '',
            // },
            {
                title: 'Parallel tracks',
                tooltipText: '',
            },
            {
                title: 'Custom registration forms',
                tooltipText: '',
            },
            {
                title: 'Custom subdomain',
                tooltipText: '',
            },
            {
                title: 'Session replays',
                tooltipText: '',
            },
            {
                title: 'Space customization',
                tooltipText: '',
            },
            {
                title: 'Booth broadcast',
                tooltipText: '',
            },
            {
                title: 'Scheduled meetings',
                tooltipText: '',
            },
            {
                title: 'Advanced analytics',
                tooltipText: '',
            },
            {
                title: 'All Pro Integrations',
                tooltipText: '',
            },
        ],
    },
    [planMappingToSubTypes[STANDARD]]: {
        displayName: 'Standard',
        summary:
            'Host branded webinars, product launches, and customer workshops.',
        features: [
            {
                title: 'Run endless sessions',
            },
            {
                title: 'Get session replays',
            },
            {
                title: 'Delegate host rights',
            },
            {
                title: 'Download HD recordings',
            },
            {
                title: 'Customize registration form',
            },
            {
                title: 'Stream-in via RTMP',
            },
            {
                title: 'Customize event invitation emails',
            },
            {
                title: 'Use advanced analytics with segmentation',
            },
            {
                title:
                    'Connect with Zapier, HubSpot, Salesforce and 8 other integrations',
            },
        ],
        pricing: ['200', '400', '500'],
        paymentCycle: ' /month',
        extraFeature: '100 registrations/event',
        quoteSubText: {
            [BUSINESS_MONTHLY]: '',
            [BUSINESS_YEARLY]: 'billed annually',
        },
        callToAction: 'Upgrade Now',
        buttonId: 'buy-plan-button',
        listSubHeading: null,
        listSuffixText: null,
        priceOnRequest: false,
        redirectToContactSalesTypeform: false,
        planBtnDisabled: false,
        isMostPopularPlan: false,
        hasStartingPrice: true,
        additionalText: 'Run endless sessions',
        planBtnBackground: theme.colors.accentPrimary[0],
        planBtnBorder: 'transparent',
        planBtnColor: 'ambience.0',
        planTitleColor: 'ambience.0',
        eventType: BUSINESS_PRO,
        additionalPricingInfo: 'billed annually',
        registrationsStripText: ['100', '300', '500'],
        registrationsDropdown: true,
    },
    [planMappingToSubTypes[ATTENDEE_PRO]]: {
        displayName: 'Events',
        summary:
            'Branded and engaging virtual and hybrid events that maximize ROI',
        features: [
            { title: 'Interactive Reception' },
            { title: '1080p recordings' },
            { title: '3D Booths with Custom Layouts' },
            { title: 'Sponsor Booths' },
            { title: 'Early Event Access' },
            { title: 'Multi-Track Events' },
            { title: 'Selective Session Access' },
            { title: 'Advanced Report Center' },
            {
                title: 'Segment Based Personalisation',
            },
        ],
        freePlanStateCardFeatures: [
            { title: 'In-person, virtual & hybrid event formats' },
            { title: 'Multi-track events with parallel sessions' },
            { title: 'Customizable welcome & reception' },
            { title: 'Live video announcements' },
            { title: 'Personalized attendee experiences' },
            { title: 'Custom email domain' },
            { title: 'Early Event Access' },
            { title: 'Scheduled Private Meetings' },
            { title: 'Comprehensive support services' },
            { title: 'Mobile app for on-site check-ins' },
            { title: 'Dedicated customer success manager' },
        ],
        callToAction: 'Talk to us',
        hidePrice: true,
        redirectToContactSalesTypeform: true,
        additionalText: 'Everything in Premium Webinar, plus',
        planBtnBackground: theme.isLightTheme
            ? theme.colors.ambience[22]
            : theme.colors.ambience[20],
        planBtnBorder: theme.isLightTheme
            ? theme.colors.ambience[19]
            : theme.colors.ambience[15],
        planBtnColor: theme.isLightTheme
            ? theme.colors.ambience[0]
            : theme.colors.darkContentPrimary,
        planTitleColor: theme.colors.ambience[0],
        eventType: planMappingToSubTypes[ATTENDEE_PRO],
        attractiveAdditionalFeature: 'Unlimited events',
        registrationsStripText: 'Starting from 100 Attendees/Event',
        scalableUptoCount: 10000,
        organizersCount: 5,
        eventFormats: [
            {
                text: 'Webinar',
                isIncludedInPlan: true,
            },
            {
                text: 'In-Person event',
                isIncludedInPlan: true,
            },
            {
                text: 'Virtual event',
                isIncludedInPlan: true,
            },
            {
                text: 'Hybrid event (Add-on)',
                isIncludedInPlan: true,
            },
        ],
    },
    [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: {
        displayName: 'Managed Events',
        summary:
            'Designed for enterprise scale & custom experiences of hybrid & virtual events',
        features: [
            { title: 'Custom Mobile App' },
            { title: 'Custom Event URL' },
            { title: 'Booth Halls' },
            { title: 'Hybrid Event Capabilities' },
            { title: 'Single Sign-on (SSO)' },
            { title: '0% commission for tickets' },
            { title: 'Hybrid Event Attendee Data' },
            { title: 'Dedicated Event Success Manager' },
        ],
        agencyFeatures: [
            { title: 'Hybrid Event Capabilities' },
            { title: '2% commission for tickets' },
            { title: 'Hybrid Event Attendee Data' },
            { title: 'Resource Hub' },
            { title: 'Profanity Filter' },
            { title: 'Closed Captions' },
            { title: 'Advanced Report Center' },
        ],
        freePlanStateCardFeatures: [
            { title: 'Full-service event planning' },
            { title: 'Custom landing page design & setup' },
            { title: 'End-to-end registration management' },
            { title: 'Promotional content creation' },
            { title: 'Virtual event setup & management' },
            { title: 'Speaker preparation & dry-runs' },
            { title: 'Professional event hosting' },
            { title: 'Detailed insights & analytics reporting' },
            { title: 'CRM synchronization' },
            { title: 'Post-event engagement strategies' },
        ],
        hidePrice: true,
        callToAction: 'Talk to us',
        redirectToContactSalesTypeform: true,
        additionalText: 'Everything in Events, plus',
        planBtnBackground: theme.isLightTheme
            ? theme.colors.ambience[22]
            : theme.colors.ambience[20],
        planBtnBorder: theme.isLightTheme
            ? theme.colors.ambience[19]
            : theme.colors.ambience[15],
        planBtnColor: theme.isLightTheme
            ? theme.colors.ambience[0]
            : theme.colors.darkContentPrimary,
        planTitleColor: theme.colors.ambience[0],
        eventType: planMappingToSubTypes[ATTENDEE_ENTERPRISE],
        attractiveAdditionalFeature: 'Quoted as per event cost',
        registrationsStripText: 'Starting from 100 Attendees/Event',
        scalableUptoCount: 10000,
        organizersCount: 'Solutions for events of all sizes',
        eventFormats: [
            {
                text: 'Webinar',
                isIncludedInPlan: true,
            },
            {
                text: 'In-Person event',
                isIncludedInPlan: true,
            },
            {
                text: 'Virtual event',
                isIncludedInPlan: true,
            },
            {
                text: 'Hybrid event',
                isIncludedInPlan: true,
            },
        ],
    },
    [planMappingToSubTypes[ATTENDEE_STARTER]]: {
        displayName: 'Premium Webinar',
        summary: 'Webinars that perform big on engagement and ROI',
        freePlanStateCardFeatures: [
            { title: 'Live, simulive & on-demand webinars' },
            { title: 'In-depth analytics with report builder' },
            { title: 'Immersive stage with instant replays' },
            { title: 'Multi-format audience engagement tools' },
            { title: 'Auto-branded customizable experiences' },
            { title: 'Multilingual closed captions & transcripts' },
            { title: 'One-click registration & CRM integrations' },
            { title: 'Advanced networking features' },
            { title: 'Monetization with ticketing & sponsorship' },
            { title: 'iOS & Android apps for seamless access' },
            { title: 'Setup team alerts for high intent actions' },
            { title: 'Content repurposing' },
        ],
        features: [
            { title: 'Event Theme Customization' },
            { title: 'Unlimited Session Length' },
            { title: 'Session as Video Calls' },
            { title: 'Session Replay / Event Replay' },
            { title: 'Leaderboard & Gamification' },
            { title: 'Integrations with Popular Platforms' },
            { title: 'Ticketing' },
            { title: 'Dedicated Account Manager ' },
            {
                title: 'Event Audience Segmentation',
            },
        ],
        hidePrice: false,
        callToAction: 'Buy now',
        additionalText: 'Top features',
        planBtnBackground: theme.colors.accentPrimary[1],
        planBtnBorder: 'transparent',
        planBtnColor: theme.colors.white,
        planTitleColor: theme.colors.ambience[0],
        eventType: planMappingToSubTypes[ATTENDEE_STARTER],
        isMostPopularPlan: true,
        attractiveAdditionalFeature: 'Unlimited webinars',
        registrationsStripText: 'Starting from 100 Attendees/Event',
        scalableUptoCount: 10000,
        organizersCount: 2,
        subtypes: [
            {
                planId: STARTER_SELF_SERVE_ATTENDEE_V2,
                displayName: 'Premium Webinar',
                isDefault: false,
                billingPeriodUnit: MONTH,
            },
            {
                planId: STARTER_ANNUAL_SELF_SERVE_ATTENDEE_V2,
                displayName: 'Premium Webinar',
                isDefault: true,
                billingPeriodUnit: YEAR,
                showAmountThatCanBeSaved: true,
            },
        ],
        pricingText: ({ startPrice, discountedPrice }) =>
            startPrice && discountedPrice
                ? ReactHtmlParser(
                      `Starts at <s>$${startPrice}</s> <b>$${discountedPrice}</b> a month when billed annually`
                  )
                : startPrice
                ? ReactHtmlParser(
                      `Starts at <b>$${startPrice}</b> a month when billed annually`
                  )
                : '',
        couponData: {
            code: process.env.REACT_APP_PLAN_CHECKOUT_COUPON_CODE,
            expiry: process.env.REACT_APP_PLAN_CHECKOUT_COUPON_EXPIRY_TIME,
            limitedOfferText: (discountPercentage) =>
                ReactHtmlParser(
                    `Limited Offer - Get <b>${discountPercentage}%</b> off`
                ),
        },
        eventFormats: [
            {
                text: 'Webinar',
                isIncludedInPlan: true,
            },
            {
                text: 'In-Person event',
                isIncludedInPlan: false,
            },
            {
                text: 'Virtual event',
                isIncludedInPlan: false,
            },
            {
                text: 'Hybrid event',
                isIncludedInPlan: false,
            },
        ],
    },
});

// here year corresponds to price per month billed annually
export const pricePlanMap = {
    0: { month: '99', year: '89' },
    35: { month: '249', year: '224' },
    70: { month: '349', year: '314' },
    100: { month: '599', year: '539' },
};

export const positionValueMap = { 0: 100, 35: 300, 70: 500, 100: 1000 }; //slider val wrt registrants number

export const positionIndexMap = { 0: 0, 1: 35, 2: 70, 3: 100 };
export const GLOBAL_FREE_PLAN_CONTACT_SALES_URL =
    'https://www.airmeet.com/hub/book-your-demo/?utm_campaign=EXC&utm_source=referral&utm_medium=airmeet-product&utm_content=airmeet-plg&utm_term=airmeet-plg';
export const URLS = {
    support: ExternalUrls.SUPPORT_LOUNGE,
    upgradeToEnterprise: 'https://www.airmeet.com/hub/talk-to-us/',
    getInTouch: 'https://airmeet.typeform.com/to/ITGNxu06',
    requestPlanChange: 'https://airmeet.typeform.com/to/KFn4yeKv',
    airmeetPricingHub: ExternalUrls.PRICING_PAGE,
    contactSales: 'https://www.airmeet.com/hub/talk-to-us/',
};

export const CHARGEBEE_CONFIG = {
    site: process.env.REACT_APP_CHARGEBEE_SCRIPT_VERSION,
    url: 'https://js.chargebee.com/v2/chargebee.js',
    publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY,
};

export const eventActionLogsMapping = {
    Growth: 'buyNowButtonClicked',
    Enterprise: 'proTalkToUsButtonClicked',
    pageView: 'billingPageView',
    toggleEvent: 'annualMonthlyToggle',
    oneTimeBilling: 'oneTimeBilling',
    subscriptionBilling: 'subscriptionBilling',
    oneTimeValidity: 'oneTimeValidity',
    subscriptionValidity: 'subscriptionValidity',
    getInTouch: 'getInTouch',
    contactSupport: 'contactSupport',
    requestPlanChange: 'requestPlanChange',
    featureListClicked: 'featureListClicked',
    faqClicked: 'faqClicked',
    hoverFeatureItems: 'hoverFeatureItems',
    upgradeMyAccountClicked: 'upgradeMyAccountClicked',
    applyCouponClicked: 'applyCouponClicked',
    removeCouponClicked: 'removeCouponClicked',
    planSizeChanged: 'planSizeChanged',
    addingBillingDetails: 'addingBillingDetails',
    addingCardDetails: 'addingCardDetails',
    filledBillingDetails: 'filledBillingDetails',
    filledCardDetails: 'filledCardDetails',
    viewCustomerPortal: 'viewCustomerPortal',
    subscriptionCreationSuccess: 'subscriptionCreationSuccess',
    subscriptionCreationFailure: 'subscriptionCreationFailure',
    lockinConsentCaptured: 'lockinConsentCaptured',
    toggleBillingTabs: 'billingTabsToggled',
    disabledUpgradeMyAccountClicked: 'disabledUpgradeMyAccountClicked',
    [CONFERENCE_PREMIUM]: 'conferencePremiumContactSalesButtonClicked',
    [CONFERENCE_STARTER]: 'conferenceStarterBuyNowButtonClicked',
    [BUSINESS_PRO]: 'swproContactSalesClicked',
    [STANDARD]: 'standardTalkToUsButtonClicked',
    [planMappingToSubTypes[ATTENDEE_STARTER]]:
        'premiumWebinarBuyNowButtonClicked',
    [planMappingToSubTypes[ATTENDEE_PRO]]: 'virtualEventsTalkToUsButtonClicked',
    [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]:
        'allInSuiteTalkToUsButtonClicked',
    comparePlans: 'comparePlansButtonClicked',
    oneTimeEventOption: 'oneTimeEventOptionClicked',
    backButtonClicked: 'backButtonClicked',
    privacyPolicyClicked: 'privacyPolicyClicked',
    termsOfServiceClicked: 'termsOfServiceClicked',
};

export const PERIOD_UNIT_WITH_SUFFIXES = {
    day: 'daily',
    month: 'monthly',
    year: 'yearly',
    once: 'once',
};

export const MANAGE_ACCOUNT_PLANS = [
    {
        title: 'Account information',
        img_path: '/images/community/portal-info.svg',
    },
    {
        title: 'Payment methods',
        img_path: '/images/community/portal-payment.svg',
    },
    {
        title: 'Billing history',
        img_path: '/images/community/portal-history.svg',
    },
];

export const BREAD_CRUMBS = [
    {
        id: '1',
        text: 'Billing plans',
    },
    {
        id: '2',
        text: 'Upgrade to Business',
    },
];

export const SOCIAL_WEBINAR_FOOTER_LOGO = [];

export const EVENT_FOOTER_LOGO = [];

export const eventTypes = {
    [SOCIAL_WEBINAR_TAB]: {
        title: 'Webinar',
        titleColor: 'lighting.7',
        description:
            'Best for results-driven webinars, webinar series, workshops, cohort-based courses, and town halls that feel like real-life gatherings ',
        logos: [
            {
                title: '',
                image_path: '',
            },
        ],
        comparePlanTitle: 'Compare Plans',
        comparePlanUrl:
            'https://www.airmeet.com/hub/compare-plans-social-webinar/',
        hasInlineComparePlanButton: false,
        paymentType: STANDARD_YEARLY,
        paymentCycle: STANDARD_YEARLY,
        currentFooterLogo: SOCIAL_WEBINAR_FOOTER_LOGO,
    },
    [CONFERENCE_TAB]: {
        title: 'Virtual Event',
        titleColor: 'lighting.1',
        description:
            'Best for visually stunning and ROI-centric virtual and hybrid summits, multi-track events, expos, and fairs',
        logos: [
            {
                title: '',
                image_path: '',
            },
        ],
        comparePlanTitle: 'View Details',
        comparePlanUrl: 'https://www.airmeet.com/hub/conference-plan/',
        hasInlineComparePlanButton: true,
        paymentType: CONFERENCE_STARTER_MONTHLY,
        paymentCycle: CONFERENCE_STARTER_MONTHLY,
        currentFooterLogo: EVENT_FOOTER_LOGO,
    },
    [COMBINED_TAB]: {
        title: 'Social Webinar',
        titleColor: 'lighting.7',
        description:
            'Best for results-driven webinars, webinar series, workshops, cohort-based courses, and town halls that feel like real-life gatherings ',
        logos: [
            {
                title: '',
                image_path: '',
            },
        ],
        comparePlanTitle: 'Compare Plans',
        comparePlanUrl: 'https://www.airmeet.com/hub/compare-pricing-plans/',
        hasInlineComparePlanButton: false,
        paymentType: STANDARD_YEARLY,
        paymentCycle: STANDARD_YEARLY,
        currentFooterLogo: SOCIAL_WEBINAR_FOOTER_LOGO,
    },
};

export const plansShowcasedInBillingPage = [
    planMappingToSubTypes[ATTENDEE_STARTER],
    planMappingToSubTypes[ATTENDEE_PRO],
    planMappingToSubTypes[ATTENDEE_ENTERPRISE],
];

export const PRICING_MAIN_FOOTER_LOGO = [
    {
        darkThemeSrc: '/images/community/workramp.svg',
        lightThemeSrc: '/images/community/workramp-light.svg',
        alt: 'workramp-logo',
        height: '27px',
        width: '151px',
    },
    {
        darkThemeSrc: '/images/community/pwc.svg',
        lightThemeSrc: '/images/community/pwc-light.svg',
        alt: 'pwc-logo',
        height: '43px',
        width: '57px',
    },
    {
        darkThemeSrc: '/images/community/jardines.svg',
        lightThemeSrc: '/images/community/jardines-light.svg',
        alt: 'jardines-logo',
        height: '53px',
        width: '98px',
    },
    {
        darkThemeSrc: '/images/community/capgemini.svg',
        lightThemeSrc: '/images/community/capgemini-light.svg',
        alt: 'capgemini-logo',
        height: '26px',
        width: '118px',
    },
    {
        darkThemeSrc: '/images/community/kaiser-permanente.svg',
        lightThemeSrc: '/images/community/kaiser-permanente-light.svg',
        alt: 'kaiser-permanente-logo',
        height: '36px',
        width: '119px',
    },
    {
        darkThemeSrc: '/images/community/ford.svg',
        lightThemeSrc: '/images/community/ford-light.svg',
        alt: 'ford-logo',
        height: 'auto',
        width: '86px',
    },
];

export const getPricingFooterLogos = (isLightTheme) => {
    return PRICING_MAIN_FOOTER_LOGO.map(
        ({ lightThemeSrc, darkThemeSrc, ...rest }) => ({
            src: isLightTheme ? lightThemeSrc : darkThemeSrc,
            ...rest,
        })
    );
};

export const BILLING_TYPES = [
    { key: 'subscription', title: 'Subscriptions', showBestValue: true },
    { key: 'one-time', title: 'One-Time payment', showBestValue: false },
];

export const BILLING_INFO = {
    [ONE_TIME_30_DAYS_PLAN]: 'For 30 days',
    [ONE_TIME_60_DAYS_PLAN]: 'For 60 days',
    [BUSINESS_MONTHLY]: 'Monthly',
    [BUSINESS_YEARLY]: 'Annually',
    [CONFERENCE_STARTER_MONTHLY]: 'Monthly',
    [STANDARD_YEARLY]: 'Annual subscription',
    [STARTER_ANNUAL_SELF_SERVE_ATTENDEE]: 'Annually',
    [STARTER_SELF_SERVE_ATTENDEE]: 'Monthly',
    [STARTER_ANNUAL_SELF_SERVE_ATTENDEE_V2]: 'Annually',
    [STARTER_SELF_SERVE_ATTENDEE_V2]: 'Monthly',
};

export const fullPlanFeaturesList = {
    [BRANDING_AND_CUSTOMIZATIONS]: {
        'Customizable Landing Pages': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Custom Registration Form': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Customized Event Branding': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Event Theme Customization': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Dynamic Stage Backdrops': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Branded Logo and Favicon': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Custom Event Navigation Labels': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Interactive Reception': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Welcome Video': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Video Notifications': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Custom Email Domain': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Custom Mobile App': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: false,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: '2 Events',
        },
        'Custom Event URL': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: 'ADD-ON',
            [planMappingToSubTypes[ATTENDEE_PRO]]: 'ADD-ON',
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: '2 Events',
        },
        'Airstudio 3D': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: 'ADD-ON',
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: 'ADD-ON',
        },
        'Streaming and Recording': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: '720p',
            [planMappingToSubTypes[ATTENDEE_PRO]]: '1080p',
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: '1080p',
        },
        'Advanced Email Editor': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'In-event Branding': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
    },
    [ENGAGEMENT_AND_NETWORKING]: {
        Booths: {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Booth Halls': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: false,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Social Lounge': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: '30 per table',
            [planMappingToSubTypes[ATTENDEE_PRO]]: '125 per table',
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: '125 per table',
        },
        'Fluid Spaces': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'In-session Breakout Rooms': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: '30 people per room',
            [planMappingToSubTypes[ATTENDEE_PRO]]: '30 people per room',
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: '30 people per room',
        },
        'iOS and Android Apps': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Attendee Profiles': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Attendee Created Tables': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Attendee Controls': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Speed Networking with custom rules': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Meeting Scheduler': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Default Emails': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Default Emoji Reactions': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Attendee Stage Engagement': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Dynamic Calls-to-action': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'QnA, Comments, Upvotes': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Spotlight Chat Messaging': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Reaction and GIFs in Chat': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Session Reminders': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Abuse Management': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Text Annoucements': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Automated Calendar Invites': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Resource Hub': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Session iFrame Integrations': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Instant Session Replay / Event Replay': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'QnA Moderation': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Profanity Filter': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Leaderboard and Gamification': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Early Event Access': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
    },
    [SETUP_AND_MANAGEMENT]: {
        'Event Templates': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Social Live Streaming': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Private Backstage and Speaker Chat': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Team Management': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Event Preview': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Web RTMP and Pre-recorded Video Upload': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Event Duplication': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Automated Sessions': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Registrations Management': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Email Communication Customization': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Marketo, Eloqua, Mailchimp and more': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Zapier, Public API Access and more': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Salesforce, Hubspot, Microsoft Dynamic 365 and more': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Closed Caption': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Multi-Track Events': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Live Translator (Interprefy)': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Selective Session Access': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Single Sign-on (SSO)': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: 'ADD-ON',
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Ticketing - Tiers, Promocodes and Access': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: '4% Commission',
            [planMappingToSubTypes[ATTENDEE_PRO]]: '2% Commission',
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: '0% Commission',
        },
        'Sendoso Integrations': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
    },
    [DATA_AND_ANALYTICS]: {
        'Live Registrations and Attendee Counter': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Basic Event Reports': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Real-time Event Analytics': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Event Audience Segmentation': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Advanced Report Center': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Account Level Audience Cohort Analytics': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Segment Based Personalisation': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Hybrid Event Attendee Data': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: 'ADD-ON',
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Booth Overview Anlalytics': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Real-time Lead and Visitor Tracking': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Booth Engagement Report': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Lead Magnet Report': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
    },
    [EXHIBITOR_AND_SPONSOR_EXPERIENCE]: {
        'Hybrid Event Attendee Data': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Booth Overview Anlalytics': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Real-time Lead and Visitor Tracking': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Booth Engagement Report': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Lead Magnet Report': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
    },
    [SUPPORT_AND_SERVICES]: {
        'Priority 24/7 Customer Support': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: true,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Platform Onboarding and Training': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Dedicated Account Manager': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: true,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'Dedicated Event Success Manager': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: false,
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: true,
        },
        'SLA (Product Uptime)': {
            [planMappingToSubTypes[ATTENDEE_STARTER]]: false,
            [planMappingToSubTypes[ATTENDEE_PRO]]: '99.95% monthly',
            [planMappingToSubTypes[ATTENDEE_ENTERPRISE]]: '99.95% monthly',
        },
    },
};

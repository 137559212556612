export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const SET_ARE_FILTERS_APPLIED = 'SET_ARE_FILTERS_APPLIED';
export const SET_FILTER_BODY = 'SET_FILTER_BODY';
export const SET_SEARCH_KEYS = 'SET_SEARCH_KEYS';

export const setSearchString = (namespace: string, searchString: string) => ({
    type: SET_SEARCH_STRING,
    payload: {
        body: searchString,
        namespace,
    },
});

export const setAreFiltersApplied = (
    namespace: string,
    areFiltersApplied: boolean
) => ({
    type: SET_ARE_FILTERS_APPLIED,
    payload: {
        body: areFiltersApplied,
        namespace,
    },
});

export const setFilterBody = (namespace: string, body: object) => ({
    type: SET_FILTER_BODY,
    payload: {
        body,
        namespace,
    },
});

export const setSearchKeys = (namespace: string, keys: Array<string>) => ({
    type: SET_SEARCH_KEYS,
    payload: {
        body: keys,
        namespace,
    },
});

export const createPeopleSearchAction = (namespace: string) => ({
    setSearchString: (searchString: string) =>
        setSearchString(namespace, searchString),
    setAreFiltersApplied: (areFiltersApplied: boolean) =>
        setAreFiltersApplied(namespace, areFiltersApplied),
    setFilterBody: (body: object) => setFilterBody(namespace, body),
    setSearchKeys: (keys: Array<string>) => setSearchKeys(namespace, keys),
});

import { stringify } from 'querystring';
import { API_SERVICE } from 'utils/apiService';
import { userTransform } from 'utils/constants/users';
import AuthService from '../../utils/authService';
import { CALL_API } from '../api';

export const AIRMEET_UPDATE_REQUEST = 'AIRMEET_UPDATE_REQUEST';
export const AIRMEET_UPDATE_SUCCESS = 'AIRMEET_UPDATE_SUCCESS';
export const AIRMEET_STATUS = 'AirmeetStatus';
export const AIRMEET_SESSION_START_RECORDING_REQUEST =
    'AIRMEET_SESSION_START_RECORDING_REQUEST';
export const AIRMEET_SESSION_START_RECORDING_RESPONSE =
    'AIRMEET_SESSION_START_RECORDING_RESPONSE';

export const AIRMEET_SESSION_STOP_RECORDING_REQUEST =
    'AIRMEET_SESSION_STOP_RECORDING_REQUEST';
export const AIRMEET_SESSION_STOP_RECORDING_RESPONSE =
    'AIRMEET_SESSION_STOP_RECORDING_RESPONSE';
export const AIRMEET_SESSION_STOP_RECORDING = 'AIRMEET_SESSION_STOP_RECORDING';

export const AIRMEET_SESSION_UPDATE_LAYOUT_RECORDING_REQUEST =
    'AIRMEET_SESSION_UPDATE_LAYOUT_RECORDING_REQUEST';
export const AIRMEET_SESSION_UPDATE_LAYOUT_RECORDING_RESPONSE =
    'AIRMEET_SESSION_UPDATE_LAYOUT_RECORDING_RESPONSE';
export const AIRMEET_SESSION_RECORDING_QUERY_REQUEST =
    'AIRMEET_SESSION_RECORDING_QUERY_REQUEST';
export const AIRMEET_SESSION_RECORDING_QUERY_RESPONSE =
    'AIRMEET_SESSION_RECORDING_QUERY_RESPONSE';
export const AIRMEET_FEATURED_REQUEST = 'AIRMEET_FEATURED_REQUEST';
export const AIRMEET_FEATURED_SUCCESS = 'AIRMEET_FEATURED_SUCCESS';
export const AIRMEET_REQUEST = 'AIRMEET_REQUEST';
export const AIRMEET_RESPONSE = 'AIRMEET_RESPONSE';
export const COMMUNITY_AIRMEET_UPDATE_REQUEST =
    'COMMUNITY_AIRMEET_UPDATE_REQUEST';
export const COMMUNITY_AIRMEET_UPDATE_SUCCESS =
    'COMMUNITY_AIRMEET_UPDATE_SUCCESS';
export const AIRMEET_SESSION_REQUEST = 'AIRMEET_SESSION_REQUEST';
export const AIRMEET_SESSION_RESPONSE = 'AIRMEET_SESSION_RESPONSE';
export const AIRMEET_ALL_UPDATE_REQUEST = 'AIRMEET_ALL_UPDATE_REQUEST';
export const AIRMEET_ALL_UPDATE_SUCCESS = 'AIRMEET_ALL_UPDATE_SUCCESS';
export const AIRMEET_SPEAKER_UPDATE_REQUEST = 'AIRMEET_SPEAKER_UPDATE_REQUEST';
export const AIRMEET_SPEAKER_UPDATE_SUCCESS = 'AIRMEET_SPEAKER_UPDATE_SUCCESS';

export const AIRMEET_SPEAKER_UPDATE_HEADLINE_REQUEST =
    'AIRMEET_SPEAKER_UPDATE_HEADLINE_REQUEST';
export const AIRMEET_SPEAKER_UPDATE_HEADLINE_SUCCESS =
    'AIRMEET_SPEAKER_UPDATE_HEADLINE_SUCCESS';
export const AIRMEET_SESSION_CREATE_CLOUDHOST_CONTAINER_REQUEST =
    'AIRMEET_SESSION_CREATE_CLOUDHOST_CONTAINER_REQUEST';
export const AIRMEET_SESSION_CREATE_CLOUDHOST_CONTAINER_SUCCESS =
    'AIRMEET_SESSION_CREATE_CLOUDHOST_CONTAINER_SUCCESS';
export const REGISTER_INVITED_USER = 'REGISTER_INVITED_USER';
export const GUEST_USER_JOIN_REQUEST = 'GUEST_USER_LOGIN_REQUEST';
export const GUEST_USER_JOIN_RESPONSE = 'GUEST_USER_LOGIN_RESPONSE';
export const DELETE_SPEAKER_REQUEST = 'DELETE_SPEAKER_REQUEST';
export const DELETE_SPEAKER_RESPONSE = 'DELETE_SPEAKER_RESPONSE';

export const BRANDING_UPDATE_REQUEST = 'BRANDING_UPDATE_REQUEST';
export const BRANDING_UPDATE_SUCCESS = 'BRANDING_UPDATE_SUCCESS';

export const LIVE_STREAM_CREATE_REQUEST = 'LIVE_STREAM_CREATE_REQUEST';
export const LIVE_STREAM_CREATE_SUCCESS = 'LIVE_STREAM_CREATE_SUCCESS';
export const LIVE_STREAM_UPDATE_REQUEST = 'LIVE_STREAM_UPDATE_REQUEST';
export const LIVE_STREAM_UPDATE_SUCCESS = 'LIVE_STREAM_UPDATE_SUCCESS';
export const LIVE_STREAM_GET_REQUEST = 'LIVE_STREAM_GET_REQUEST';
export const LIVE_STREAM_GET_SUCCESS = 'LIVE_STREAM_GET_SUCCESS';
export const LIVE_STREAM_DELETE_REQUEST = 'LIVE_STREAM_DELETE_REQUEST';
export const LIVE_STREAM_DELETE_SUCCESS = 'LIVE_STREAM_DELETE_SUCCESS';
export const SESSION_LIVE_STREAM_GET_REQUEST =
    'SESSION_LIVE_STREAM_GET_REQUEST';
export const SESSION_LIVE_STREAM_GET_SUCCESS =
    'SESSION_LIVE_STREAM_GET_SUCCESS';
export const SESSION_LITE_MODE_RTMP_GET_REQUEST =
    'SESSION_LITE_MODE_RTMP_GET_REQUEST';
export const SESSION_LITE_MODE_RTMP_SUCCESS = 'SESSION_LITE_MODE_RTMP_SUCCESS';
export const SESSION_STREAM_GET_REQUEST = 'SESSION_STREAM_GET_REQUEST';
export const SESSION_STREAM_GET_SUCCESS = 'SESSION_STREAM_GET_SUCCESS';
export const FETCH_AIRMEET_TEAM_MEMBERS = 'FETCH_AIRMEET_TEAM_MEMBERS';
export const UPDATE_AIRMEET_USER_DETAILS_REQUEST =
    'UPDATE_AIRMEET_USER_DETAILS_REQUEST';
export const UPDATE_AIRMEET_USER_DETAILS_RESPONSE =
    'UPDATE_AIRMEET_USER_DETAILS_RESPONSE';
export const RESET_COMMUNITY_LOADER = 'RESET_COMMUNITY_LOADER';
export const CREATE_MEETING = 'CREATE_MEETING';
export const DELETE_MEETING = 'DELETE_MEETING';
export const FETCH_MEETING_LIST = 'FETCH_MEETING_LIST';
export const UPDATE_MEETING_DETAILS = 'UPDATE_MEETING_DETAILS';
export const RICH_PROFILE_INTERESTS = 'RICH_PROFILE_INTERESTS';
export const FETCH_CUSTOM_FORM_FILE = 'FETCH_CUSTOM_FORM_FILE';

export const VALIDATE_OVERLAPPING_SESSION_REQUEST =
    'VALIDATE_OVERLAPPING_SESSION_REQUEST';
export const VALIDATE_OVERLAPPING_SESSION_RESPONSE =
    'VALIDATE_OVERLAPPING_SESSION_RESPONSE';

export const UPDATE_AIRMEET_USER_METADATA_REQUEST =
    'UPDATE_AIRMEET_USER_METADATA_REQUEST';
export const UPDATE_AIRMEET_USER_METADATA_RESPONSE =
    'UPDATE_AIRMEET_USER_METADATA_RESPONSE';
export const UPDATE_EVENT_USER_CHECK_IN_REQUEST =
    'UPDATE_EVENT_USER_CHECK_IN_REQUEST';

export const FETCH_USER_HUBSPOT_DETAILS = 'FETCH_USER_HUBSPOT_DETAILS';
export const UPDATE_USER_HUBSPOT_DETAILS = 'UPDATE_USER_HUBSPOT_DETAILS';

export function airmeetStatusUpdate(status = '') {
    /*let headers = {
        "Access-Control-Allow-Origin": "*"
        };
    return dispatch => {
        axios.get('https://apitest.airmeet.com/api/v1/airmeet?airmeetId='+id,{},{'headers': headers })
            .then(response => {
                if(response.status === 200) {
                    let sData = response.data
                    dispatch(createNewAirmeet(sData));
                } else {
                    dispatch(showError());
                }
                
            })
            .catch(error => dispatch(showError()) );
    }*/
    return {
        type: AIRMEET_STATUS,
        payload: status,
    };
}

export function createSessionCloudhostContainerIfAbsent(
    airmeetId,
    sessionId,
    spawnScreenRecorder = false,
    spawnCloudHost = true
) {
    let data = {
        airmeetId: airmeetId,
        entityId: sessionId,
        entityType: 'SESSION',
        spawnScreenRecorder,
        spawnCloudHost,
        webUrl:
            process.env.REACT_APP_ENV === 'test'
                ? window.location.origin
                : null,
    };
    return {
        [CALL_API]: {
            types: [
                AIRMEET_SESSION_CREATE_CLOUDHOST_CONTAINER_REQUEST,
                AIRMEET_SESSION_CREATE_CLOUDHOST_CONTAINER_SUCCESS,
            ],
            endpoint: `/cloud/container/spawn`,
            body: data,
            type: 'json',
            method: 'POST',
        },
    };
}

export function airmeetUpdate(sessionid, status) {
    let data = {
        sessionId: sessionid,
        status,
    };
    return {
        [CALL_API]: {
            types: [AIRMEET_UPDATE_REQUEST, AIRMEET_UPDATE_SUCCESS],
            endpoint: `/airmeet/session`,
            body: data,
            type: 'json',
            method: 'PUT',
        },
    };
}

export function airmeetAllUpdate(data, method) {
    return {
        [CALL_API]: {
            types: [AIRMEET_ALL_UPDATE_REQUEST, AIRMEET_ALL_UPDATE_SUCCESS],
            endpoint: `/airmeet/session`,
            body: data,
            type: 'json',
            method: method,
        },
    };
}

export function comunityAirmeetUpdate(data) {
    return {
        [CALL_API]: {
            types: [
                COMMUNITY_AIRMEET_UPDATE_REQUEST,
                COMMUNITY_AIRMEET_UPDATE_SUCCESS,
            ],
            endpoint: `/airmeet`,
            body: data,
            type: 'json',
            method: 'PUT',
        },
    };
}

export function resetCommunityLoader(loadingState) {
    return {
        type: RESET_COMMUNITY_LOADER,
        payload: { isLoading: loadingState },
    };
}

export function airmeetSpeakerUpdate(data, method = 'PUT') {
    return {
        [CALL_API]: {
            types: [
                AIRMEET_SPEAKER_UPDATE_REQUEST,
                AIRMEET_SPEAKER_UPDATE_SUCCESS,
            ],
            endpoint: `/airmeet/session/speaker`,
            body: data,
            type: 'json',
            method,
        },
    };
}

export function updateVideoHeadline(airmeetId, data) {
    return {
        [CALL_API]: {
            types: [
                AIRMEET_SPEAKER_UPDATE_HEADLINE_REQUEST,
                AIRMEET_SPEAKER_UPDATE_HEADLINE_SUCCESS,
            ],
            endpoint: `/airmeet/${airmeetId}/headline`,
            body: data,
            type: 'json',
            method: 'PUT',
        },
    };
}

export function getFeaturedAirmeets() {
    return {
        [CALL_API]: {
            types: [AIRMEET_FEATURED_REQUEST, AIRMEET_FEATURED_SUCCESS],
            endpoint: `/airmeet/getFeaturedAirmeets`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getAirmeets(airmeetId, params = '') {
    return {
        [CALL_API]: {
            types: [AIRMEET_REQUEST, AIRMEET_RESPONSE],
            endpoint: `/airmeet?airmeetId=${airmeetId}&includes=${params}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getAirmeetsSession(sessionId) {
    return {
        [CALL_API]: {
            types: [AIRMEET_SESSION_REQUEST, AIRMEET_SESSION_RESPONSE],
            endpoint: `/airmeet/session?sessionId=${sessionId}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function startSessionRecording(sessionId, channelId) {
    let data = {
        sessionId,
        channelId,
    };

    return {
        [CALL_API]: {
            types: [
                AIRMEET_SESSION_START_RECORDING_REQUEST,
                AIRMEET_SESSION_START_RECORDING_RESPONSE,
            ],
            endpoint: `/session/recordings/start`,
            body: data,
            type: 'json',
            method: 'POST',
            transform: (json) => ({
                cname: channelId,
                sessionId: sessionId,
            }),
        },
    };
}

export function stopSessionRecordingAPICall({ sessionId }) {
    let data = {
        sessionId,
    };

    return {
        [CALL_API]: {
            types: [
                AIRMEET_SESSION_STOP_RECORDING_REQUEST,
                AIRMEET_SESSION_STOP_RECORDING_RESPONSE,
            ],
            endpoint: `/session/recordings/stop`,
            body: data,
            type: 'json',
            method: 'POST',
        },
    };
}

export function updateLayoutSessionRecording({
    airmeetId,
    sessionId,
    layoutType,
    mainStreamUids,
    smallStreamUids,
    maxResolutionUid,
    noOfStreams,
    excludeStreamsUid,
}) {
    return {
        [CALL_API]: {
            types: [
                AIRMEET_SESSION_UPDATE_LAYOUT_RECORDING_REQUEST,
                AIRMEET_SESSION_UPDATE_LAYOUT_RECORDING_RESPONSE,
            ],
            endpoint: '/session/recordings/v2/update-layout',
            body: {
                airmeetId,
                mainStreamUids,
                smallStreamUids,
                sessionId,
                layoutType,
                maxResolutionUid,
                noOfStreams,
                excludeStreamsUid,
            },
            type: 'json',
            method: 'POST',
        },
    };
}

export function stopSessionRecording({ sessionId }) {
    let data = {
        sessionId,
    };
    console.log(data);
    return {
        type: AIRMEET_SESSION_STOP_RECORDING,
        payload: data,
    };
}

export function joinAsGuest(details = {}, isNewFlow = false) {
    if (!details?.airmeetId) {
        return;
    }
    let data = {};
    const setDetailsFrom = (container) => {
        const {
            name,
            airmeetId,
            profileImage,
            userProfile,
            tags,
            ...params
        } = container;

        if (airmeetId) data.airmeetId = airmeetId;
        if (name) data.name = name;
        if (profileImage) data.profile_img = profileImage;
        if (tags) data.tags = tags;
        // Handle flexible params separately
        if (!isNewFlow) {
            data.params = container.params || params || {};
        } else {
            data = { ...data, userProfile };
        }
    };

    // Append any cached guest details
    const cached = AuthService.guest;
    if (cached?.id) {
        // Set ID always, if found
        data.userId = cached.id;
        // Try setting the details from cache
        setDetailsFrom(cached);
    }
    setDetailsFrom(details);

    return {
        [CALL_API]: {
            types: [GUEST_USER_JOIN_REQUEST, GUEST_USER_JOIN_RESPONSE],
            endpoint: `/airmeet/guest`,
            body: data,
            type: 'json',
            method: 'POST',
        },
    };
}

export function deleteSessionSpeaker({ sessionId, speakerId }) {
    return {
        [CALL_API]: {
            types: [DELETE_SPEAKER_REQUEST, DELETE_SPEAKER_RESPONSE],
            endpoint: '/airmeet/session/speaker',
            body: {
                sessionId,
                speakerId,
            },
            type: 'json',
            method: 'DELETE',
        },
    };
}
export function registerInvitedUser(airmeetId, data) {
    return {
        [CALL_API]: {
            types: [REGISTER_INVITED_USER],
            endpoint: `/airmeet/${airmeetId}/attendee-profile`,
            body: data,
            type: 'json',
            method: 'PUT',
        },
    };
}

//BRANDING request
export function airmeetBrandingUpdate(airmeetId, data) {
    return {
        [CALL_API]: {
            types: [BRANDING_UPDATE_REQUEST, BRANDING_UPDATE_SUCCESS],
            endpoint: `/airmeet/${airmeetId}/branding`,
            body: data,
            type: 'json',
            method: 'PUT',
        },
    };
}

// live stream requests
export function createLiveStream(airmeetId, data) {
    return {
        [CALL_API]: {
            types: [LIVE_STREAM_CREATE_REQUEST, LIVE_STREAM_CREATE_SUCCESS],
            endpoint: `/airmeet/${airmeetId}/social-stream`,
            body: data,
            type: 'json',
            method: 'POST',
        },
    };
}

export function updateLiveStream(airmeetId, { streamId, ...data }) {
    return {
        [CALL_API]: {
            types: [LIVE_STREAM_UPDATE_REQUEST, LIVE_STREAM_UPDATE_SUCCESS],
            endpoint: `/airmeet/${airmeetId}/social-stream/${streamId}`,
            body: data,
            type: 'json',
            method: 'PUT',
        },
    };
}

export function getLiveStreams(airmeetId) {
    return {
        [CALL_API]: {
            types: [LIVE_STREAM_GET_REQUEST, LIVE_STREAM_GET_SUCCESS],
            endpoint: `/airmeet/${airmeetId}/social-streams`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getSessionLiveStreams(sessionId, params = {}) {
    const queryParams = stringify(params);
    return {
        [CALL_API]: {
            types: [
                SESSION_LIVE_STREAM_GET_REQUEST,
                SESSION_LIVE_STREAM_GET_SUCCESS,
            ],
            endpoint: `/airmeet/session/${sessionId}/social-streams?${queryParams}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getSessionLiteModeStreamDetails(
    airmeetId,
    sessionId,
    params = {}
) {
    const queryParams = stringify({
        stream_type: params.stream_type,
        quality: params.quality,
    });
    return {
        [CALL_API]: {
            types: [
                SESSION_LITE_MODE_RTMP_GET_REQUEST,
                SESSION_LITE_MODE_RTMP_SUCCESS,
            ],
            endpoint: `/airmeet/${airmeetId}/${sessionId}/streaming?${queryParams}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function deleteLiveStream(airmeetId, streamId) {
    return {
        [CALL_API]: {
            types: [LIVE_STREAM_DELETE_REQUEST, LIVE_STREAM_DELETE_SUCCESS],
            endpoint: `/airmeet/${airmeetId}/social-stream/${streamId}`,
            type: 'json',
            method: 'DELETE',
        },
    };
}

export function getStreamInfo(airmeetId, sessionId) {
    return {
        [CALL_API]: {
            types: [SESSION_STREAM_GET_REQUEST, SESSION_STREAM_GET_SUCCESS],
            endpoint: `/airmeet/${airmeetId}/${sessionId}/streaming`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getAirmeetTeamMembers(airmeetId, sessionId) {
    return {
        [CALL_API]: {
            types: [FETCH_AIRMEET_TEAM_MEMBERS],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/valid-hosts`,
            type: 'json',
        },
    };
}

export function updateUserDetails(airmeetId, details = {}) {
    return {
        [CALL_API]: {
            types: [
                UPDATE_AIRMEET_USER_DETAILS_REQUEST,
                UPDATE_AIRMEET_USER_DETAILS_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/user`,
            type: 'json',
            method: 'PUT',
            transform: userTransform,
            body: details,
        },
    };
}

export const updateUserMetadata = (airmeetId, metadata = {}) => ({
    [CALL_API]: {
        types: [
            UPDATE_AIRMEET_USER_METADATA_REQUEST,
            UPDATE_AIRMEET_USER_METADATA_RESPONSE,
        ],
        endpoint: `/airmeet/${airmeetId}/user/metadata`,
        type: 'json',
        method: 'PATCH',
        body: metadata,
    },
});

export const createMeeting = (airmeetId, meetingData = {}) => {
    return {
        [CALL_API]: {
            types: [CREATE_MEETING],
            endpoint: `/airmeet/${airmeetId}/user/meeting`,
            type: 'json',
            method: 'POST',
            body: meetingData,
        },
    };
};

export const deleteMeeting = (airmeetId, meetingId, meetingData = {}) => {
    return {
        [CALL_API]: {
            types: [DELETE_MEETING],
            endpoint: `/airmeet/${airmeetId}/user/meeting/${meetingId}`,
            type: 'json',
            method: 'DELETE',
            body: meetingData,
        },
    };
};

export const getMeetingList = (airmeetId, userId) => {
    return {
        [CALL_API]: {
            types: [FETCH_MEETING_LIST],
            endpoint: `/airmeet/${airmeetId}/user/${userId}/meetings`,
            type: 'json',
        },
    };
};

export const updateMeetingDetails = (
    airmeetId,
    meetingId,
    meetingData = {}
) => {
    return {
        [CALL_API]: {
            types: [UPDATE_MEETING_DETAILS],
            endpoint: `/airmeet/${airmeetId}/user/meeting/${meetingId}`,
            type: 'json',
            method: 'PATCH',
            body: meetingData,
        },
    };
};

//Validate Duplication
export const validateDuplication = (airmeetId) => {
    return {
        [CALL_API]: {
            types: [
                VALIDATE_OVERLAPPING_SESSION_REQUEST,
                VALIDATE_OVERLAPPING_SESSION_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/duplication/check`,
            method: 'GET',
            type: 'json',
        },
    };
};

export const getRichProfileInterests = (airmeetId) => {
    return {
        [CALL_API]: {
            types: [RICH_PROFILE_INTERESTS],
            endpoint: `/metadata/rich-profile-interests?airmeetId=${airmeetId}`,
            service: API_SERVICE.BFF,
            type: 'json',
            method: 'GET',
        },
    };
};

export const getCustomInterests = (airmeetId) => {
    return {
        [CALL_API]: {
            types: [RICH_PROFILE_INTERESTS],
            endpoint: `/airmeet/${airmeetId}/interests/configuration`,
            service: API_SERVICE.BFF,
            type: 'json',
            method: 'GET',
        },
    };
};

export const checkIntoEvent = (airmeetId, apiBody = {}) => {
    return {
        [CALL_API]: {
            types: [UPDATE_EVENT_USER_CHECK_IN_REQUEST],
            endpoint: `/airmeet/${airmeetId}/checkin`,
            type: 'json',
            method: 'PUT',
            body: apiBody,
        },
    };
};

export function getHubspotUserInfo(userId, communityId) {
    return {
        [CALL_API]: {
            types: [FETCH_USER_HUBSPOT_DETAILS],
            endpoint: `/airmeet/community/${communityId}/user/${userId}/deal`,
            service: API_SERVICE.BFF,
            type: 'json',
            method: 'GET',
        },
    };
}

export const getCustomFormFile = ({ airmeetId, userId, fieldId }) => {
    return {
        [CALL_API]: {
            types: [FETCH_CUSTOM_FORM_FILE],
            endpoint: `/airmeet/${airmeetId}/custom-form-file?userId=${userId}&fieldId=${fieldId}`,
            type: 'json',
            method: 'GET',
        },
    };
};

import { useContainerNotifications } from 'atoms/Container/container';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Events } from 'utils/constants/containers/airmeet';
import {
    AV_SETTINGS_CLOSE,
    AV_SETTINGS_OPEN,
    MUTE_STAGE_USER_AV,
    REVERT_STAGE_USER_AV,
} from 'utils/constants/sessions';
import { logger } from 'utils/logger';

export default function useStageAudioHandler({ stageService }) {
    const { onNotify, offNotify } = useContainerNotifications();
    const [isAudioMuted, setAudioMuted] = useState(true);
    const progressState = useRef(false);
    const isAudioMutedPrevious = useRef();
    const logPrefix = useRef();
    logPrefix.current = `Stage audio handler: ${
        stageService?.serviceName ?? 'default'
    }:`;
    const getLocalAudioStateRef = useRef(null);
    getLocalAudioStateRef.current = () =>
        !stageService?.localStream?.isAudioOn();

    const muteAudioHandler = useCallback(async () => {
        if (progressState.current) {
            logger.info(`${logPrefix.current} skip for mute audio`, {
                progressState: progressState.current,
            });
            return;
        }
        progressState.current = true;
        let result;
        try {
            result = await stageService.muteLocalAudio(false);
        } catch (e) {
            logger.info(`${logPrefix.current} mute audio failed`, {
                e,
            });
            result = false;
        }
        // if operation failed
        if (!result) {
            progressState.current = false;
            logger.info(`${logPrefix.current} mute audio failed`, {
                result,
            });
            return;
        }
        progressState.current = false;
        logger.info(`${logPrefix.current} mute audio success`, {
            progressState: progressState.current,
        });
        setAudioMuted(getLocalAudioStateRef.current());
    }, []);

    const unMuteAudioHandler = useCallback(async () => {
        if (progressState.current) {
            logger.info(`${logPrefix.current} skip for unmute audio`, {
                progressState: progressState.current,
            });
            return;
        }
        progressState.current = true;
        let result;
        try {
            result = await stageService.muteLocalAudio(true);
        } catch (e) {
            logger.info(`${logPrefix.current} unmute audio failed`, {
                e,
            });
            result = false;
        }
        // if operation failed
        if (!result) {
            progressState.current = false;
            logger.info(`${logPrefix.current} unmute audio failed`, {
                result,
            });
            return;
        }
        progressState.current = false;
        logger.info(`${logPrefix.current} unmute audio success`, {
            progressState: progressState.current,
        });
        setAudioMuted(getLocalAudioStateRef.current());
    }, []);

    useEffect(() => {
        const localStreamAudioMute = ({ localStream }) => {
            const isAudioOn = localStream ? localStream.isAudioOn() : false;
            logger.info(`${logPrefix.current} on localStreamAudioMute`, {
                localStream: !!localStream,
                isAudioOn,
            });
            setAudioMuted(!isAudioOn);
        };
        const localStreamAudioUnmute = ({ localStream }) => {
            const isAudioOn = localStream ? localStream.isAudioOn() : false;
            logger.info(`${logPrefix.current} on localStreamAudioUnmute`, {
                localStream: !!localStream,
                isAudioOn,
            });
            setAudioMuted(!isAudioOn);
        };
        const onStreamUnpublish = () => {
            logger.info(
                `${logPrefix.current} on onStreamUnpublish setting audio muted to true`
            );
            isAudioMutedPrevious.current = true;
            setAudioMuted(true);
        };
        const onUnmuteFailed = ({ localStream }) => {
            const isAudioOn = localStream ? localStream.isAudioOn() : false;
            logger.info(`${logPrefix.current} on onUnmuteFailed`, {
                localStream: !!localStream,
                isAudioOn,
            });
            setAudioMuted(!isAudioOn);
        };

        // mute the microphone when permissions revoked
        const onDevicePermissionRevoked = ({ deviceType }) => {
            if (deviceType === 'microphone') {
                logger.info(
                    `${logPrefix.current} initiate the microphone mute on permission revoked`
                );
                muteAudioHandler();
            }
        };
        stageService.on(
            Events.rtcBrodCastLocalStreamAudioMute,
            localStreamAudioMute
        );
        stageService.on(
            Events.rtcBrodCastLocalStreamAudioUnmute,
            localStreamAudioUnmute
        );
        stageService.on(Events.rtcLiveStreamUnpublished, onStreamUnpublish);
        stageService.on(Events.audioUnmuteFailed, onUnmuteFailed);
        stageService.on(Events.permissionsRevoked, onDevicePermissionRevoked);

        return () => {
            stageService.off(
                Events.rtcBrodCastLocalStreamAudioMute,
                localStreamAudioMute
            );
            stageService.off(
                Events.rtcBrodCastLocalStreamAudioUnmute,
                localStreamAudioUnmute
            );
            stageService.off(
                Events.rtcLiveStreamUnpublished,
                onStreamUnpublish
            );
            stageService.off(Events.audioUnmuteFailed, onUnmuteFailed);
            stageService.off(
                Events.permissionsRevoked,
                onDevicePermissionRevoked
            );
        };
    }, [stageService]);

    useEffect(() => {
        const muteMedia = async ({ data }) => {
            if (!stageService?.hasPublished()) {
                return;
            }
            isAudioMutedPrevious.current = isAudioMuted;
            const { source } = data;
            if (!isAudioMuted) {
                try {
                    logger.info(
                        `${logPrefix.current} muteMedia - muting the audio`,
                        {
                            source,
                        }
                    );
                    await muteAudioHandler();
                } catch (e) {
                    logger.error(
                        `${logPrefix.current} Failed to toggle audio`,
                        { source },
                        e
                    );
                }
            }
        };
        const revertMedia = async ({ data }) => {
            if (!stageService?.hasPublished()) {
                return;
            }
            const { source } = data;
            if (
                isAudioMutedPrevious.current !== undefined &&
                !isAudioMutedPrevious.current
            ) {
                logger.info(
                    `${logPrefix.current} revertMedia - unmuting the audio`,
                    {
                        source,
                    }
                );
                await unMuteAudioHandler();
            }
        };
        onNotify(AV_SETTINGS_OPEN, muteMedia);
        onNotify(AV_SETTINGS_CLOSE, revertMedia);
        onNotify(MUTE_STAGE_USER_AV, muteMedia);
        onNotify(REVERT_STAGE_USER_AV, revertMedia);
        return () => {
            offNotify(AV_SETTINGS_OPEN, muteMedia);
            offNotify(AV_SETTINGS_CLOSE, revertMedia);
            offNotify(MUTE_STAGE_USER_AV, muteMedia);
            offNotify(REVERT_STAGE_USER_AV, revertMedia);
        };
    }, [onNotify, offNotify, isAudioMuted, muteAudioHandler, stageService]);

    return {
        isAudioMuted,
        muteAudioHandler,
        unMuteAudioHandler,
    };
}

import Filter from '@airmeet/filter';
import {
    AirmeetRTCClient as AirmeetRTCSDKClient,
    instanceTypes,
} from '@airmeet/rtc-sdk';
import AirmeetRTCClient from 'containers/AirmeetRTCClient';
import EventEmitter from 'events';
import camelCase from 'lodash/camelCase';
import StageSdkService from 'utils/Stage/StageSdkService';
import AuthService from 'utils/authService';
import {
    CONNECTION_STATUS_ABORTED,
    CONNECTION_STATUS_CONNECTED,
    CONNECTION_STATUS_CONNECTING,
    CONNECTION_STATUS_DISCONNECTED,
    CONNECTION_STATUS_RECONNECTING,
    ErrorEvents,
    Events,
    HandRaiseMessageType,
    RtmClientMessages,
    WEBSITE_DEFAULT_RTM_CHANNEL,
} from 'utils/constants/containers/airmeet';
import {
    createScreenShareUserId,
    STAGE_DEFAULT_AV_STATES,
} from 'utils/constants/live-airmeet';
import { rtcSDKConfig } from 'utils/rtcSdkEnv.ts';
import {
    checkSystemRequirement as checkVideoRTCSystemRequirements,
    getDevicesForStream,
    reloadDevices,
    devices as rtcDevices,
} from '../containers/AirmeetRTCClient';
import AirmeetRTCClientBroadcast from '../containers/AirmeetRTCClientBroadcast';
import { DEFAULT_BLUR_RADIUS } from './FilterManager';
import { noop } from './constants/common';
import { logger } from './logger';

export {
    CONNECTION_STATUS_ABORTED,
    CONNECTION_STATUS_CONNECTED,
    CONNECTION_STATUS_CONNECTING,
    CONNECTION_STATUS_DISCONNECTED,
    CONNECTION_STATUS_RECONNECTING,
    ErrorEvents,
    Events,
    HandRaiseMessageType,
    RtmClientMessages,
    WEBSITE_DEFAULT_RTM_CHANNEL,
};

const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID;

const localLog = logger.init('Airmeet:', 'green');

const streamPlay = (streamObj, id, replay = false, props) => {
    if (!streamObj.stream) {
        return;
    }
    if (streamObj.isPlaying()) {
        if (!replay) {
            return;
        }
        streamObj.stop();
    }
    localLog(`Playing stream id ${id}, with type of id: ${typeof id}`);
    if (document.getElementById(id.toString())) {
        try {
            const oldPlayerEl = document.getElementById(
                `player_${streamObj.getId().toString()}`
            );
            oldPlayerEl && oldPlayerEl.remove();
        } catch (ex) {
            // igonre
        }
        streamObj.play(id.toString(), props);
        streamObj.on('player-status-change', (evt) => {
            if (
                evt.isErrorState &&
                evt.status === 'paused' &&
                streamObj.stream
            ) {
                //console.error(`Stream is paused unexpectedly. Trying to resume...`);
                streamObj
                    .resume()
                    .then(function () {
                        // console.log(`Stream is resumed successfully`);
                    })
                    .catch(function (e) {
                        // console.error(`Failed to resume stream. Error ${e.name} Reason ${e.message}`);
                    });
            }
        });
    } else {
        localLog(`Not found the player element: ${id} for stream.`);
    }
};

const FORWARDED_ERRORS = [ErrorEvents.RTCTokenError];

export const getEventTypeLabel = ({ eventType, eventSubType = null }) => {
    const EVENT_TYPE_LABELS = {
        DEFAULT: 'Webinar',
        MEETUP: 'Webinar',
        CONFERENCE: 'Virtual Event',
        HYBRID_CONFERENCE: 'Hybrid Event',
        VIRTUAL_SERIES: 'Event Series',
        IN_PERSON_CONFERENCE: 'In-Person Event',
        LITE: 'Webinar',
    };

    return EVENT_TYPE_LABELS[eventSubType || eventType] || '';
};

export const getAirmeetFormat = ({ eventType }) => {
    const AIRMEET_FORMATS = {
        DEFAULT: 'webinar',
        MEETUP: 'webinar',
        CONFERENCE: 'event',
        VIRTUAL_SERIES: 'series',
    };

    return AIRMEET_FORMATS[eventType] || '';
};

export const checkToAllowCameraPermission = async (callback) => {
    const response = await checkVideoRTCSystemRequirements(callback);
    return response;
};

export default class Airmeet extends EventEmitter {
    constructor(options = {}) {
        super();
        this.storage = localStorage;
        this.airmeetId = options.airmeetId;
        this.geofencing = options.geofencing;
        this.rtcClients = { lounge: null, booth: null, liveAnnouncement: null };
        this.eventChannelName = null;
        this.videoRTCClient = null;
        this.screenRTCClient = null;
        this.streamPlay = streamPlay;
        this.members = {};
        this.authUser = null;
        this.channelMembers = {};
        this.joinedTable = null;
        this.rtcDevices = rtcDevices;
        this.rtmClient = options.rtmClient;
        this.channelTypes = {
            default: WEBSITE_DEFAULT_RTM_CHANNEL,
        };
        this.localStreamMuted = {
            audio: false,
            video: true,
        };
        this.broadCastCustomMediaRTCClient = null;
        this.bindEvents();
        this.agoraConnectivityPass = true;
        this.dailyConnectivityPass = true;
        this.connectViaProxy = false;
        this.useAirmeetProxy = false;
        this.useAirmeetTURNServer = false;
        this.connectViaProxyDaily = false;
        this.useAirmeetProxyDaily = false;
        this.useAirmeetTURNServerDaily = false;
        this.airmeetTURNServerConfig = null;
        this.firebaseClient = options.firebaseClient;
        this.RTCServices = {};
        this.defaultAudioMute = STAGE_DEFAULT_AV_STATES.isAudioMute;
        this.defaultVideoMute = STAGE_DEFAULT_AV_STATES.isVideoMute;
    }

    setAirmeetTURNServerConfig = (config) => {
        this.airmeetTURNServerConfig = config;
        this.emit('turn-configuration-updated', config);
    };

    setWebRTCProxy = (type) => {
        this.connectViaProxy = type === 'agora';
        this.useAirmeetProxy = type === 'airmeet';
        this.agoraConnectivityPass = type !== 'failed';
        if (type !== 'failed') {
            this.emit('proxy-configuration-updated', type);
        }
    };
    setWebRTCProxyDaily = (type, useTurn = false) => {
        this.connectViaProxyDaily = type === 'daily';
        this.useAirmeetProxyDaily = type === 'airmeet';
        this.useAirmeetTURNServerDaily = useTurn;
        this.dailyConnectivityPass = type !== 'failed';
    };

    isEnabledConnectViaProxy() {
        return this.connectViaProxy || this.connectViaProxy;
    }

    toggleAirmeetTURNServer = (state) => {
        this.useAirmeetTURNServer = state;
    };

    isFailedConnection(connectionState) {
        return (
            [CONNECTION_STATUS_ABORTED, CONNECTION_STATUS_DISCONNECTED].indexOf(
                connectionState
            ) >= 0
        );
    }

    pauseLocalStreamCheck() {
        if (this.videoRTCClient) this.videoRTCClient.stopLocalVolumeCheck();
    }

    async resumeStageLocalStreamCheck(serviceName) {
        if (serviceName) {
            if (this.RTCServices[serviceName]) {
                return this.RTCServices[serviceName].resumeLocalStreamCheck();
            }
            return;
        }
        Object.keys(this.RTCServices).forEach((instance) => {
            if (this.RTCServices[instance]) {
                this.RTCServices[instance].resumeLocalStreamCheck();
            }
        });
    }

    resumeLocalStreamCheck() {
        if (this.videoRTCClient) this.videoRTCClient.initLocalVolumeCheck();
        this.resumeStageLocalStreamCheck();
    }

    getAuthUser() {
        return this.authUser;
    }

    getRTCDevices() {
        return this.rtcDevices();
    }

    reloadRTCDevices() {
        return reloadDevices();
    }

    getAvailablePreferredDevices(opts) {
        return getDevicesForStream(opts);
    }

    setAuthUser(user) {
        if (
            !user ||
            (this.authUser &&
                this.authUser.id.toString() === user.id.toString())
        ) {
            return this;
        }
        this.authUser = { tags: [], company: '', designation: '', ...user };

        return this;
    }

    bindEvents() {
        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.speakerJoined}-type-message`,
            ({ content }) => {
                this.emit(Events.speakerJoined, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.hostRequestedMuteSpeakerVideoInBroadcast}-type-message`,
            ({ content }) => {
                this.emit(
                    Events.hostRequestedMuteSpeakerVideoInBroadcast,
                    content
                );
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.hostRequestedMuteSpeakerAudioInBroadcast}-type-message`,
            ({ content }) => {
                this.emit(
                    Events.hostRequestedMuteSpeakerAudioInBroadcast,
                    content
                );
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.hostRequestedStopScreenshareInBroadcast}-type-message`,
            ({ content }) => {
                this.emit(
                    Events.hostRequestedStopScreenshareInBroadcast,
                    content
                );
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.hostRequestedStopPublishStreamInBroadcast}-type-message`,
            ({ content }) => {
                this.emit(
                    Events.hostRequestedStopPublishStreamInBroadcast,
                    content
                );
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStage}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStage, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStageAccepted}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStageAccepted, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStageRejected}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStageRejected, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStageHostCancelled}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStageHostCancelled, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStageJoined}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStageJoined, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStageHostRemoved}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStageHostRemoved, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStageNotAllowed}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStageNotAllowed, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStagePublishFail}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStagePublishFail, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStagePublishFailAttendee}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStagePublishFailAttendee, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStageJoinedSuccess}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStageJoinedSuccess, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStagePermissionMissing}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStagePermissionMissing, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStageAwaitPermission}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStageAwaitPermission, content);
            }
        );
        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.inviteToStageClosePrompt}-type-message`,
            ({ content }) => {
                this.emit(Events.inviteToStageClosePrompt, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.hostDelegationRequestAccepted}-type-message`,
            ({ content }) => {
                this.emit(Events.hostDelegationRequestAccepted, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.hostDelegationRequestRejected}-type-message`,
            ({ content }) => {
                this.emit(Events.hostDelegationRequestRejected, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.hostDelegationRequestCancelled}-type-message`,
            ({ content }) => {
                this.emit(Events.hostDelegationRequestCancelled, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.removeSessionHost}-type-message`,
            ({ content }) => {
                this.emit(Events.removeSessionHost, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.hostDelegationRefreshAirmeetForDelegatedHost}-type-message`,
            () => {
                this.emit(Events.hostDelegationRefreshAirmeetForDelegatedHost);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.hostDelegationNotifySpeakers}-type-message`,
            () => {
                this.emit(Events.hostDelegationNotifySpeakers);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.hostDelegationRequest}-type-message`,
            ({ content }) => {
                this.emit(Events.hostDelegationRequest, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.requestToJoinStage}-type-message`,
            ({ content }) => {
                this.emit(Events.requestToJoinStage, content);
            }
        );
        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.requestToJoinStageAccepted}-type-message`,
            ({ content }) => {
                this.emit(Events.requestToJoinStageAccepted, content);
            }
        );
        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.requestToJoinStageNotAllowed}-type-message`,
            ({ content }) => {
                this.emit(Events.requestToJoinStageNotAllowed, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.requestToJoinStagePermissionMissing}-type-message`,
            ({ content }) => {
                this.emit(Events.requestToJoinStagePermissionMissing, content);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.requestToJoinStageRequesteeJoined}-type-message`,
            ({ content }) => {
                this.emit(Events.requestToJoinStageRequesteeJoined, content);
            }
        );
        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.muteRemoteVideoRequestTable}-type-message`,
            (data) => {
                this.emit(Events.muteRemoteVideoRequestTable, data);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.requestTojoinStageAwaitingPermission}-type-message`,
            ({ content }) => {
                this.emit(Events.requestTojoinStageAwaitingPermission, content);
            }
        );
        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.requestToJoinStageRejected}-type-message`,
            ({ content }) => {
                this.emit(Events.requestToJoinStageRejected, content);
            }
        );
        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.requestToJoinStageCancelled}-type-message`,
            ({ content }) => {
                this.emit(Events.requestToJoinStageCancelled, content);
            }
        );
        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.requestToJoinStageRequesteeOnSameSession}-type-message`,
            ({ content }) => {
                this.emit(
                    Events.requestToJoinStageRequesteeOnSameSession,
                    content
                );
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.muteRemoteAudioRequestTable}-type-message`,
            (data) => {
                this.emit(Events.muteRemoteAudioRequestTable, data);
            }
        );

        this.rtmClient.on(
            `peer-2-peer-${RtmClientMessages.moveScreenShare}-type-message`,
            ({ content }) => {
                this.emit(Events.moveScreenShare, content);
            }
        );
        const emitter = this.rtmClient.getChannelEmmiter();
        if (emitter) {
            emitter.on(
                `${RtmClientMessages.hostAddedInvitedSpeakerInBroadcast}-type-message`,
                ({ content }) => {
                    this.emit(
                        Events.hostAddedInvitedSpeakerInBroadcast,
                        content
                    );
                }
            );
            emitter.on(
                `${RtmClientMessages.hostDelegationRefreshAirmeetInfo}-type-message`,
                ({ content }) => {
                    this.emit(Events.hostDelegationRefreshAirmeetInfo, content);
                }
            );
            emitter.on(
                `${RtmClientMessages.coHostUpdated}-type-message`,
                ({ content }) => {
                    this.emit(Events.coHostUpdated, content);
                }
            );
            emitter.on(
                `${RtmClientMessages.coHostRemoved}-type-message`,
                ({ content }) => {
                    this.emit(Events.coHostRemoved, content);
                }
            );
        }
    }

    initNewRTCClient(userId) {
        const authToken = AuthService.token || userId;

        const isProdOrPreprod =
            process.env.REACT_APP_RTC_SDK_ENV === 'production' ||
            process.env.REACT_APP_RTC_SDK_ENV === 'preprod';

        const client = new AirmeetRTCSDKClient({
            airmeetClientId: this.airmeetId,
            airmeetAuthToken: authToken,
            proxyConfig: {
                useVendorProxy: this.connectViaProxy,
                useAirmeetProxy: this.useAirmeetProxy,
                useAirmeetTURN: this.useAirmeetTURNServer,
            },
            dailyProxyConfig: {
                useVendorProxy: this.connectViaProxyDaily,
                useAirmeetProxy: this.useAirmeetProxyDaily,
                useAirmeetTURN: this.useAirmeetTURNServerDaily,
            },
            firebaseProxyHelper: this.firebaseClient.getFirebaseProxyHelperInstance(
                'SDK'
            ),
            environmentConfig: rtcSDKConfig,
            upstreamAuthUrl: !isProdOrPreprod
                ? process.env.REACT_APP_API_BASE_URL
                : null,
            isAVDForClosedCaptionEnabled: true,
        });

        const filterClient = this.initNewFilterClient(client.getLogger());
        client.registerModule('filter', filterClient);

        // TODO:: social lounge:: Enable constants

        if (process.env.REACT_APP_RTC_SDK_ENV === 'production') {
            client.setLogLevel(4);
        } else {
            client.setLogLevel(0);
        }
        return client;
    }

    initNewFilterClient(loggerInstance) {
        const filterClient = new Filter({
            logger: loggerInstance,
        });
        try {
            filterClient.init(rtcSDKConfig.rtcSdkAssetsBaseUrl, 'airmeet.js');
            filterClient.updateBlurRadius(DEFAULT_BLUR_RADIUS);
        } catch (err) {}

        return filterClient;
    }

    initRTCClient(params) {
        const uid = this.getAuthUser().id_seq;
        const client = new AirmeetRTCClient(uid, AGORA_APP_ID, {
            isEnableLocalAudioMonitor: true,
            ...params,
            connectViaProxy: this.connectViaProxy,
            useAirmeetProxy: this.useAirmeetProxy,
            airmeetId: this.airmeetId,
            useAirmeetTURNServer: this.useAirmeetTURNServer,
            turnServerConfig: this.airmeetTURNServerConfig,
            geofencing: this.geofencing,
        });

        this.interceptClientForwardedErrors(client);

        return client;
    }

    setStageService(serviceName, params = {}) {
        const setDefaultAudioMute = (defaultAudioMute) => {
            this.defaultAudioMute = defaultAudioMute;
        };

        const setDefaultVideoMute = (defaultVideoMute) => {
            this.defaultVideoMute = defaultVideoMute;
        };

        const getDefaultAudioMute = () => {
            return this.defaultAudioMute;
        };

        const getDefaultVideoMute = () => {
            return this.defaultVideoMute;
        };
        if (!this.RTCServices[serviceName]) {
            params = {
                mode: 'live',
                ...params,
                connectViaProxy: this.connectViaProxy,
                useAirmeetProxy: this.useAirmeetProxy,
                airmeetId: this.airmeetId,
                useAirmeetTURNServer: this.useAirmeetTURNServer,
                turnServerConfig: this.airmeetTURNServerConfig,
                geofencing: this.geofencing,
                authUser: this.authUser,
                serviceName,
                defaultAudioMute: this.defaultAudioMute,
                defaultVideoMute: this.defaultVideoMute,
                setDefaultAudioMute,
                setDefaultVideoMute,
                getDefaultAudioMute,
                getDefaultVideoMute,
            };
            const stageService = new StageSdkService(params);

            this.on('turn-configuration-updated', (event) => {
                stageService.setTurnConfig(event);
            });
            this.on('turn-configuration-updated', (event) => {
                stageService.setTurnConfig(event);
            });
            this.on('recording-device-change-success', (localStream) => {
                this.emit('recording-device-change-success', localStream);
            });

            this.RTCServices[serviceName] = stageService;
        }

        return this.RTCServices[serviceName];
    }

    initScreenRTCClient(params) {
        const uid = createScreenShareUserId(this.getAuthUser().id_seq);
        const client = new AirmeetRTCClient(uid, AGORA_APP_ID, {
            ...params,
            isShareScreenAccount: true,
            connectViaProxy: this.connectViaProxy,
            useAirmeetProxy: this.useAirmeetProxy,
            airmeetId: this.airmeetId,
            useAirmeetTURNServer: this.useAirmeetTURNServer,
            turnServerConfig: this.airmeetTURNServerConfig,
            geofencing: this.geofencing,
        });

        this.interceptClientForwardedErrors(client);

        return client;
    }

    initRTCClientBroadcast(params) {
        const uid = this.getAuthUser().id_seq;
        //params.streamId = this.getAuthUser().id_seq;
        const client = new AirmeetRTCClientBroadcast(uid, AGORA_APP_ID, {
            isEnableLocalAudioMonitor: true,
            ...params,
            connectViaProxy: this.connectViaProxy,
            useAirmeetProxy: this.useAirmeetProxy,
            airmeetId: this.airmeetId,
            useAirmeetTURNServer: this.useAirmeetTURNServer,
            turnServerConfig: this.airmeetTURNServerConfig,
            geofencing: this.geofencing,
        });

        this.interceptClientForwardedErrors(client);

        return client;
    }

    getScreenRTCClient() {
        if (!this.screenRTCClient) {
            this.screenRTCClient = this.initScreenRTCClient({
                mode: 'rtc',
            });

            this.on('turn-configuration-updated', () => {
                this.screenRTCClient &&
                    this.screenRTCClient.updateTURNServerConfiguration(
                        this.airmeetTURNServerConfig
                    );
            });

            this.on('proxy-configuration-updated', (event) => {
                this.screenRTCClient &&
                    this.screenRTCClient.enableProxyServer(
                        this.airmeetTURNServerConfig,
                        event
                    );
            });
        }

        return this.screenRTCClient;
    }

    getLobbyClient() {
        if (!this.lobbyClient) {
            this.lobbyClient = this.initRTCClient({
                mode: 'rtc',
            });

            this.on('turn-configuration-updated', () => {
                this.lobbyClient &&
                    this.lobbyClient.updateTURNServerConfiguration(
                        this.airmeetTURNServerConfig
                    );
            });

            this.on('proxy-configuration-updated', (event) => {
                this.lobbyClient &&
                    this.lobbyClient.enableProxyServer(
                        this.airmeetTURNServerConfig,
                        event
                    );
            });
        }

        return this.lobbyClient;
    }

    getVideoRTCClient() {
        if (!this.videoRTCClient) {
            this.videoRTCClient = this.initRTCClient({
                mode: 'video',
                airmeetId: this.airmeetId,
            });
            this.videoRTCClient.on('stream-added', ({ id, isLocal }) => {
                if (!isLocal) {
                    localLog(`subscribed to  ${id}`);
                }
                this.emit(Events.rtcVideoStreamAdded, { id });
            });
            this.videoRTCClient.on('stream-subscribed', ({ id }) => {});
            this.videoRTCClient.on('active-speaker', ({ id }) => {
                localLog(`rtc-video-active-speaker to  ${id}`);

                this.emit(Events.rtcVideoActiveSpeaker, { id });
            });

            this.videoRTCClient.on('stream-published', (stream) => {
                localLog(`${stream.getId()} has published a stream`);
                this.emit(Events.rtcVideoLocalStreamPublished, stream);
            });

            this.videoRTCClient.on('stream-removed', ({ id }) => {
                localLog(`${id} has left the room`);
                this.emit(Events.rtcVideoStreamRemoved, { id });
            });

            this.videoRTCClient.on(
                'network-quality-updated',
                ({ uid, quality }) => {
                    this.emit(Events.rtcLiveNetwokrQualityUpdated, {
                        uid,
                        quality,
                    });
                }
            );
            [
                'mute-video',
                'unmute-video',
                'mute-audio',
                'unmute-audio',
            ].forEach((event) => {
                const eventName = camelCase(`on-${event}`);
                this.videoRTCClient.on(eventName, (evt) => {
                    localLog(`${evt.uid} has ${eventName}`);
                    this.emit(`${eventName}RTC`, evt);
                });
            });

            this.on('turn-configuration-updated', () => {
                this.videoRTCClient &&
                    this.videoRTCClient.updateTURNServerConfiguration(
                        this.airmeetTURNServerConfig
                    );
            });

            this.on('proxy-configuration-updated', (event) => {
                this.videoRTCClient &&
                    this.videoRTCClient.enableProxyServer(
                        this.airmeetTURNServerConfig,
                        event
                    );
            });

            this.videoRTCClient.on(Events.localStreamReconnectEnd, (stream) => {
                this.emit(Events.localStreamReconnectEnd, stream);
            });
            this.videoRTCClient.on(
                Events.localStreamReconnectStart,
                (stream) => {
                    this.emit(Events.localStreamReconnectStart, stream);
                }
            );
        }
        return this.videoRTCClient;
    }

    getNewVideoRTCClient(userId, instance) {
        if (!this.rtcClients[instance]) {
            // ?REVIEW: maybe get token here?
            this.rtcClients[instance] = this.initNewRTCClient(userId);
        }
        return this.rtcClients[instance];
    }

    getStageService(serviceName) {
        if (!this.RTCServices[serviceName]) {
            this.RTCServices[serviceName] = this.setStageService(serviceName);
        }

        return this.RTCServices[serviceName];
    }

    checkMediaSource(elementId, video_uuid) {
        return new Promise((resolve, reject) => {
            let checkCount = 0;
            const mediaCheck = () => {
                const mediaElement = document.getElementById(elementId);
                if (!mediaElement) {
                    logger.info(
                        'checkMediaSource is running but mediaElement is not exists',
                        { elementId }
                    );
                    return reject(
                        'checkMediaSource is running but mediaElement is not exists'
                    );
                }

                if (video_uuid !== mediaElement.getAttribute('data-uuid')) {
                    logger.info(
                        'checkMediaSource is running but mediaElement src has been changed',
                        { elementId }
                    );
                    return reject(
                        'checkMediaSource is running but mediaElement src has been changed'
                    );
                }

                logger.debug('readyState', mediaElement.readyState);
                // added `muted = false` to avoid this error - [Uncaught (in promise) DOMException: play()
                // failed because the user didn't interact with the document first. https://goo.gl/xX8pDD]
                // [ref] https://stackoverflow.com/questions/49930680/how-to-handle-uncaught-in-promise-domexception-play-failed-because-the-use
                const playPromise = mediaElement.play();
                mediaElement.muted = false;
                if (playPromise !== undefined && mediaElement.readyState > 1) {
                    playPromise
                        .then(() => {
                            mediaElement.pause();
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                            logger.info('checkMediaSource error:', {
                                error,
                                checkCount,
                            });
                        });
                } else {
                    checkCount++;
                    if (checkCount < 20) {
                        setTimeout(() => {
                            mediaCheck();
                        }, 500);
                    } else {
                        reject('media check count exceeded');
                    }
                }
            };
            setTimeout(mediaCheck, 1000);
        });
    }

    switchInputDevices() {
        let shouldEmitEvent = false;
        Object.keys(this.rtcClients).forEach((instance) => {
            if (
                this.rtcClients[instance] &&
                this.rtcClients[instance].getConferenceInfo()
            ) {
                shouldEmitEvent = true;
            }
        });

        if (this.videoRTCClient && this.videoRTCClient.localStream) {
            // Table stream
            // this.stopTableStream();
            // this.startTableStream();
            shouldEmitEvent = true;
        }
        if (shouldEmitEvent) {
            this.emit('switch-input-devices');
            shouldEmitEvent = false;
        }
    }

    startTableStream() {
        logger.debug('Publishing stream');
        if (this.videoRTCClient)
            this.videoRTCClient.publishStream({
                attendeeMode: 'video',
                videoProfile: '360p_8',
            });
    }

    stopTableStream() {
        logger.debug('Stopping stream');
        if (this.videoRTCClient) this.videoRTCClient.unpublishStream();
    }

    interceptClientForwardedErrors(client) {
        if (client) {
            FORWARDED_ERRORS.forEach((err) => {
                client.on(err, ({ error, ...props }) =>
                    this.emit(err, { type: err, error, ...props })
                );
            });
        }
    }

    /**
     * Register all propogated errors here
     */
    onErrors(callback = noop) {
        this.forwardedErrorCallback = callback;
        this.forwardErrors();
    }

    forwardErrors(attach = true) {
        FORWARDED_ERRORS.forEach((err) => {
            if (attach) {
                this.on(err, this.forwardedErrorCallback);
            } else {
                this.off(err, this.forwardedErrorCallback);
            }
        });
    }

    hasAllowToJoinTable = (callback) => {
        return checkToAllowCameraPermission(callback);
    };

    notifyHostOnJoinBackstage(
        host,
        userStageRole,
        sessionId = '',
        sessionName = ''
    ) {
        this.rtmClient.sendMessage(RtmClientMessages.speakerJoined, host, {
            name: this.authUser.name,
            id: this.authUser.id,
            userStageRole,
            sessionId,
            sessionName,
        });
    }

    notifyFSZoneAlloc(sessionId = '') {
        this.rtmClient.sendChannelMessage(RtmClientMessages.fsZoneRealloc, {
            sessionId,
        });
    }

    subscribeFsZoneReallocReq(cb) {
        const emitter = this.rtmClient.getChannelEmmiter();
        emitter.on(`${RtmClientMessages.fsZoneRealloc}-type-message`, cb);
        return () => {
            emitter.off(`${RtmClientMessages.fsZoneRealloc}-type-message`, cb);
        };
    }

    sendInviteStageMessage({ id, type = 'inviteToStage', params = {} }) {
        if (params.session && params.session.sessionid) {
            const session = params.session;
            delete params.session;
            params.sessionId = session.sessionid;
        }
        if (params.user && params.user.id) {
            const user = params.user;
            params.user = {
                id: user.id,
                name: user.name,
            };
        }
        this.rtmClient.sendMessage(RtmClientMessages[type], id, params);
    }

    sendInviteStageBroadcastMessage({ id, type, params = {} }) {
        if (['hostAddedInvitedSpeakerInBroadcast'].includes(type)) {
            this.rtmClient.sendChannelMessage(RtmClientMessages[type], {});
        }
    }

    hostDelegationRefreshAirmeetInfo() {
        this.rtmClient.sendChannelMessage(
            RtmClientMessages.hostDelegationRefreshAirmeetInfo,
            { refreshAirmeetInfo: true }
        );
    }

    muteSpeakerAudioAsHost(receiverId) {
        this.rtmClient.sendMessage(
            RtmClientMessages.hostRequestedMuteSpeakerAudioInBroadcast,
            receiverId,
            {}
        );
    }

    muteSpeakerVideoAsHost(receiverId) {
        this.rtmClient.sendMessage(
            RtmClientMessages.hostRequestedMuteSpeakerVideoInBroadcast,
            receiverId,
            {}
        );
    }

    stopSpeakerScreenshareAsHost(receiverId) {
        this.rtmClient.sendMessage(
            RtmClientMessages.hostRequestedStopScreenshareInBroadcast,
            receiverId,
            {}
        );
    }

    requestStopPublishStreamByHost(receiverId) {
        this.rtmClient.sendMessage(
            RtmClientMessages.hostRequestedStopPublishStreamInBroadcast,
            receiverId,
            {}
        );
    }

    notifyMoveScreenShare(receiverId, data = {}) {
        this.rtmClient.sendMessage(
            RtmClientMessages.moveScreenShare,
            receiverId,
            data
        );
    }

    getJoinedTableStreams() {
        return this.getVideoRTCClient().getStreams();
    }

    getLocalStreamSubscriber() {
        return this.getVideoRTCClient().localStreamSubscribed;
    }

    addLocalStreamSubscriber(id) {
        this.getVideoRTCClient().addLocalStreamSubscriber(id);
        this.emit(Events.rtcVideoStreamAdded, { id });
    }

    updateJoinedTableInfo(joinedTableInfo, name = 'JoinedTableInfo') {
        this.storage.setItem(name, JSON.stringify(joinedTableInfo));
    }

    getJoinedTableInfo(name = 'JoinedTableInfo') {
        const info = this.storage.getItem(name);
        if (!info) {
            return {};
        }
        return JSON.parse(info);
    }

    playStream(stream, id, replay = false, props = {}) {
        this.streamPlay(stream, id, replay, props);
    }

    beforeUnload() {
        this.emit(Events.beforeUnload);

        Object.keys(this.RTCServices).forEach((instance) => {
            if (this.RTCServices[instance]) {
                this.RTCServices[instance].leaveBroadCastRTC();

                this.RTCServices[instance].logDataToSDK({
                    msg: 'leaveChannelSource - cleanup',
                });
            }
        });

        if (this.videoRTCClient) {
            this.videoRTCClient.leaveChannel();
        }

        if (this.forwardedErrorCallback) {
            this.forwardErrors(false);
            this.forwardedErrorCallback = null;
        }
        Object.keys(this.rtcClients).forEach((instance) => {
            if (this.rtcClients[instance]) {
                this.rtcClients[instance].leave();
            }
        });
    }

    leaveRTC() {
        this.leaveBroadCastRTC();

        if (this.videoRTCClient) {
            this.videoRTCClient.leaveChannel();
        }
    }

    sendCoHostUpdatedBroadcastMessage({ type, payload }) {
        this.rtmClient.sendChannelMessage(RtmClientMessages[type], payload);
    }

    cleanupEvent = (type, data, forceClear = false) => {
        this.emit(`cleanup-${type}`, data, forceClear);
    };

    syncPollAnswer() {
        this.emit(Events.syncPollAnswer);
    }

    // request to join stage/backstage
    sendRequestToJoinStageMessage({ id, type, params = {} }) {
        if (params.session && params.session.sessionid) {
            const session = params.session;
            delete params.session;
            params.sessionId = session.sessionid;
        }
        if (params.user && params.user.id) {
            const user = params.user;
            params.user = {
                id: user.id,
                name: user.name,
            };
        }

        this.rtmClient.sendMessage(RtmClientMessages[type], id, params);
    }

    async prefetchChannelToken({ userId, userIdSeq, channelIds }) {
        try {
            logger.info(
                'prefetch token - sending the channel ids to SDK init',
                channelIds
            );
            await AirmeetRTCSDKClient.prefetchChannelToken(
                { id: userId, type: instanceTypes.SDK },
                userIdSeq,
                channelIds
            );
            logger.info('prefetch token - sent token success', channelIds);
        } catch (e) {
            logger.info(
                'prefetch token - failed to create the pre-fetch token',
                e
            );
        }
    }
}

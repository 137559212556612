import BarChart from 'components/community/editPublicAirmeet/Analytics/components/BarChart';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import useTheme from 'hooks/useTheme';
import React from 'react';
require('components/community/editPublicAirmeet/Analytics/components/RoundedBar');

function PostEventBarChart({
    chartLabels,
    chartData,
    backgroundColor = null,
    hoverBackgroundColor = null,
    height = '18rem',
    stacked = true,
    cornerRadius = 4,
    ...rest
}) {
    const { theme } = useTheme();

    return (
        <FlexDiv height={height} alignItems='stretch' justifyContent='stretch'>
            <BarChart
                chartLabels={chartLabels}
                chartData={chartData}
                backgroundColor={
                    backgroundColor || theme.colors.module.viz.sea['800']
                }
                hoverBackgroundColor={
                    hoverBackgroundColor || theme.colors.module.viz.sea['600']
                }
                maintainAspectRatio={false}
                displayLegend={false}
                maxBarThickness={20}
                xAxes={[
                    {
                        stacked,
                        ticks: {},
                        maxBarThickness: 20,
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                    },
                ]}
                yAxes={[
                    {
                        stacked,
                        ticks: {
                            precision: 0,
                            beginAtZero: true,
                            padding: 24,
                        },
                        gridLines: {
                            color: theme.colors.border.default1,
                            borderDash: [2, 2],
                            drawBorder: false,
                            borderDashOffset: 20,
                            zeroLineColor: theme.colors.border.default1,
                            zeroLineBorderDash: [2, 2],
                        },
                    },
                ]}
                options={{
                    cornerRadius,
                    fullCornerRadius: true,
                    bottomRounded: false,
                    tooltips: {
                        backgroundColor: theme.colors.fill.default1,
                        borderColor: theme.colors.border.default2,
                        borderWidth: 1,
                        displayColors: false,
                        xPadding: 21,
                        yPadding: 6,
                        titleSpacing: 0,
                        titleAlign: 'center',
                        titleFontColor: theme.colors.text.default.primary,
                        titleFontStyle: 'normal',
                        titleMarginBottom: 0,
                        bodyFontColor: theme.colors.text.default.primary,
                    },
                }}
                {...rest}
            />
        </FlexDiv>
    );
}

export default PostEventBarChart;

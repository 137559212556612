import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import useClosedCaptionsSettings from 'hooks/closed-captions/useClosedCaptionsSettings';
import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import {
    noop,
    noComponent,
    emptyObject,
    emptyArray,
} from 'utils/constants/common';
import {
    CC_LANGUAGE_OPTIONS_AWS,
    CC_LANGUAGE_OPTIONS_GCP,
    CC_PRIMARY_LANGUAGE_OPTIONS_AWS,
    CC_PRIMARY_LANGUAGE_OPTIONS_GCP,
} from './constants';

export default function CcLangsSelect({
    liveConfig,
    selectStyles = emptyObject,
    isLoading = false,
    onChange = noop,
    onSubmit = noop,
    style = emptyObject,
}) {
    const {
        serviceProvider,
        defaultPrimaryLanguage,
    } = useClosedCaptionsSettings();
    const CC_LANGUAGE_OPTIONS = useMemo(() => {
        return {
            supportedLangs:
                serviceProvider === 1
                    ? CC_LANGUAGE_OPTIONS_AWS
                    : CC_LANGUAGE_OPTIONS_GCP,
            primaryLangs:
                serviceProvider === 1
                    ? CC_PRIMARY_LANGUAGE_OPTIONS_AWS
                    : CC_PRIMARY_LANGUAGE_OPTIONS_GCP,
        };
    }, [serviceProvider]);

    const ccPrimaryLangOption = useMemo(
        () =>
            defaultPrimaryLanguage
                ? CC_LANGUAGE_OPTIONS.primaryLangs?.find(
                      (option) =>
                          option?.value === defaultPrimaryLanguage?.value
                  )
                : CC_LANGUAGE_OPTIONS.primaryLangs[0],
        [defaultPrimaryLanguage, CC_LANGUAGE_OPTIONS]
    );

    const uniqueSupportedLangs = useMemo(() => {
        // filter out the primary language from the supported languages
        // let's say en-US is the primary language, then we should not show en in the supported languages dropdown
        return CC_LANGUAGE_OPTIONS?.supportedLangs?.filter(({ value }) => {
            return !ccPrimaryLangOption?.value
                ?.split?.('-')?.[0]
                .includes?.(value);
        });
    }, [ccPrimaryLangOption, CC_LANGUAGE_OPTIONS]);

    const selectedOptions = useMemo(() => {
        const ccLangs = liveConfig?.ccLangs || [];
        if (Array.isArray(ccLangs) && ccLangs.length > 0) {
            return uniqueSupportedLangs?.filter((option) =>
                ccLangs.find((lang) => lang?.value === option?.value)
            );
        }
        return emptyArray;
    }, [liveConfig, uniqueSupportedLangs]);

    const [ccLangOptions, setCcLangOptions] = useState(selectedOptions);

    useEffect(() => {
        // update the ccLangOptions when the uniqueSupportedLangs changes
        // in case of primary language change, we should remove the primary language from the selected languages list
        handleCcLangsChange(
            uniqueSupportedLangs?.filter((option) =>
                selectedOptions.find((lang) => lang?.value === option?.value)
            )
        );
    }, [uniqueSupportedLangs]);

    const handleCcLangsChange = (val) => {
        setCcLangOptions(val || []);
        onChange && onChange(val || []);
    };

    const handleSubmit = () => {
        onSubmit && onSubmit(ccLangOptions);
    };

    return (
        <FlexDiv
            flexDirection='column'
            alignItems='stretch'
            flex='1'
            pt='16px'
            style={style}
        >
            <Typography variant='subtitle2-bold' color='ambience.0' pb='5px'>
                Caption Languages
            </Typography>
            <Typography variant='subtext2' color='ambience.6' pb='8px'>
                Select the languages the attendees can choose to show the closed
                caption in
            </Typography>
            <Select
                defaultValue={uniqueSupportedLangs[0]}
                name='language'
                options={uniqueSupportedLangs}
                components={{
                    DropdownIndicator: noComponent,
                    IndicatorSeparator: noComponent,
                    ClearIndicator: noComponent,
                }}
                styles={selectStyles}
                isDisabled={isLoading}
                onChange={handleCcLangsChange}
                onBlur={handleSubmit}
                value={ccLangOptions}
                isMulti={true}
                isSearchable={true}
            />
        </FlexDiv>
    );
}
